import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class BannerApi {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.BANNERS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createBanner(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.BANNERS}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editBanner(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.BANNERS}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }


    async getBannerByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.BANNERS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async deleteBanner(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.BANNERS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async uploadBannerFile(file: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.FILE_BANNER}banner`, file, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Define o tipo de conteúdo correto
                },
            });
            return response.data; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async deleteFileBanner(uuidBanner: string, uuidFile: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.BANNERS}/${uuidBanner}/image/${uuidFile}`);
            return response.data; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }
}

export default new BannerApi();