import { Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import productStore from '../../../../stores/productStore';
import { getUniqueFilters } from '../../../../utils/functions';
import ContentBox from '../../../molecules/ContentBox';
import LoadingComponent from '../../../molecules/LoadingComponent';


interface DataType {
    unity: any;
    total: any;
    center: any;
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

const TableStock: React.FC = observer(() => {
    const { loading, stockList } = productStore;

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Centro',
            dataIndex: 'center',
            key: 'center',
            filters: getUniqueFilters(stockList?.center, 'center'),
            onFilter: (value, record) => record.center.indexOf(value as string) === 0,
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            filters: getUniqueFilters(stockList?.total, 'total'),
            onFilter: (value, record) => record.total.indexOf(value as string) === 0,
        },
        {
            title: 'Unidade',
            dataIndex: 'unity',
            key: 'unity',
            filters: getUniqueFilters(stockList?.unity, 'unity'),
            onFilter: (value, record) => record.unity.indexOf(value as string) === 0,
        },
    ];

    return (
        <div>
            {loading &&
                <LoadingComponent />
            }

            {!loading &&
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={stockList && stockList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            }
        </div>
    );
});

export default TableStock;