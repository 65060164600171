import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    ordination: Yup.string().required('Ordenação é obrigatória'),
    topic: Yup.object()
        .shape({
            value: Yup.string().required('Selecione um Tópico válido'),
        })
        .nullable()
        .required('Tópico é obrigatório'),
    content: Yup.string().required('Conteudo é obrigatória'),
});