import URLS from '../config/urls';
import { Color } from '../types/Cores';
import BaseAPI from './BaseApi';


class ColorService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COLORS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async createColor(color: Color): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.COLORS}`, color);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };  // Retorna um objeto de erro
        }
    }

    async editColor(color: Color, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.COLORS}/${uuid}`, color);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error }; // Retorna um objeto de erro
        }
    }

    async getColorByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COLORS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async deleteColor(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.COLORS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new ColorService();