import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionInstitucional } from '../../../permissions/PermissionInstitucional';
import institucionalStore from '../../../stores/institucionalStore';
import { Institucional } from '../../../types/Institucional';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import EditorTextComponent from '../../atoms/EditorTextComponent';
import InputComponet from '../../atoms/InputComponet';
import InputNumberComponet from '../../atoms/InputNumberComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/institucional">Listagem de Institucional</a>,
    },
    {
        title: <p>Cadastro de Institucional</p>,
    },
]

const topicOption = [
    {
        label: 'Institucional',
        value: 'INSTITUCIONAL',
    },
    {
        label: 'Marcas',
        value: 'MARCAS',
    },
    {
        label: 'Central de atendimento',
        value: 'CENTRAL_DE_ATENDIMENTO',
    },
    {
        label: 'Horário de Atendimento',
        value: 'HORARIO_DE_ATENDIMENTO',
    },
    {
        label: 'Políticas',
        value: 'POLITICAS',
    },
];

const CreateInstitucional: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { institucional, loading } = institucionalStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    useEffect(() => {
        if (uuid && hasPermission(PermissionInstitucional.GET_UUID)) {
            institucionalStore.getInstitucionalByUuid(uuid);
        }

        return () => {
            institucionalStore.reset();
        };
    }, []);

    const initialValues: Institucional = {
        name: institucional?.name || '',
        topic: typeof institucional?.topic === 'string'
            ? { value: institucional?.topic, label: '' }
            : institucional?.topic || { value: '', label: '' },
        ordination: institucional?.ordination || '',
        externalLink: institucional?.externalLink || '',
        pageType: 'FREE_TEXT',
        content: institucional?.content
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Institucional) => {
        let response;

        const createProps = {
            ...institucional,
            ...values,
            type: "FOOTER_2",
            topic: typeof values.topic === 'object' && values.topic !== null
                ? values.topic.value
                : (values.topic || ''),
        };

        if (uuid) {
            response = await institucionalStore.editInstitucional(createProps, uuid);
        } else {
            response = await institucionalStore.createInstitucional(createProps);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/institucional`);
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }], // Títulos: h1, h2, sem cabeçalho
            ['bold', 'italic', 'underline', 'strike'], // Formatação de texto
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Listas ordenadas e não ordenadas
            [{ 'script': 'sub' }, { 'script': 'super' }], // Subscrito e sobrescrito
            [{ 'indent': '-1' }, { 'indent': '+1' }], // Reduzir ou aumentar a indentação
            [{ 'size': ['small', false, 'large', 'huge'] }], // Tamanhos de fonte
            [{ 'color': [] }, { 'background': [] }], // Cor do texto e cor de fundo
            [{ 'font': [] }], // Fontes disponíveis
            ['link', 'image', 'video'], // Inserir links, imagens e vídeos
            ['clean'], // Limpar formatação
            ['code-block'], // Bloco de código
        ],
    };


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionInstitucional.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Institucional" description='Tela para cadastro de Institucional'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionInstitucional.POST, PermissionInstitucional.PUT, PermissionInstitucional.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        //@ts-ignore
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Cadastro de Institucional" description='Tela para cadastro de Institucional'>
                                <div className="flex w-full gap-4 pb-4 grid grid-cols-12">
                                    <div className="col-span-4">
                                        <InputComponet label="Nome*" name="name" id="name" />
                                    </div>
                                    <div className="col-span-4">
                                        <SelectComponent label="Tópico" name="topic" options={topicOption} />
                                    </div>
                                    <div className="col-span-4">
                                        <InputNumberComponet label="Ordenação*" name="ordination" id="ordination" />
                                    </div>
                                </div>

                                <div className="flex w-full gap-4 pb-4 grid grid-cols-12">
                                    <div className="col-span-12">
                                        <InputComponet label="Link*" name="externalLink" id="externalLink" />
                                    </div>
                                </div>
                            </ContentBox>

                            <ContentBox className="mb-4 p-6" title='Ediçaõ de Dados'>
                                <EditorTextComponent
                                    name="content"
                                    modules={modules} />
                            </ContentBox>

                            <Permission permissionKeys={[PermissionInstitucional.PUT, PermissionInstitucional.POST]} buttonType>
                                <div className="flex justify-end">
                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                </div>
                            </Permission>
                        </Form>
                    </Formik>
                </Permission>
            }

        </>
    );
});

export default CreateInstitucional;