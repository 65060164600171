import { message } from 'antd';
import React, { useRef, useState } from 'react';
import * as XLSX from 'xlsx';
import './styles.scss';
import { decodeText } from '../../../utils/functions';

interface FileUploadProps {
    onFileProcessed: (data: any) => void; // Função que receberá os dados processados
    requiredColumns: string[]; // Colunas que devem estar presentes no arquivo
    title?: string;
    accept?: string;
    validateFilledFields?: boolean; // faz a validação apenas pelos campos selecionados no lugar da tabela inteira
    returnOriginalFile?: boolean; // Retorna o valor original no lugar das colunas
}

const FileUpload: React.FC<FileUploadProps> = ({ onFileProcessed, requiredColumns, title, accept, validateFilledFields = true, returnOriginalFile = false }) => {
    const fileInputRef = useRef<HTMLInputElement | null>(null); // Ref para o input
    const [fileName, setFileName] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const processFile = (file: File) => {
        setIsUploading(true);
        const reader = new FileReader();
        reader.onload = (event) => {
            // Lê os dados do arquivo
            //@ts-ignore
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            let jsonDataValue = undefined as any;

            if (validateFilledFields) {
                jsonDataValue = XLSX.utils.sheet_to_json(worksheet);
            } else {
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }) as any; // Retorna um array de arrays
                const headers = jsonData[0]; // Primeira linha contém o cabeçalho
                const rows = jsonData.slice(1);

                if (!headers || headers?.length === 0) {
                    message.error('O arquivo está vazio ou não possui cabeçalhos.');
                    return;
                }

                jsonDataValue = rows.map((row: any) =>
                    headers.reduce((acc: any, header: any, index: number) => {
                        acc[header] = row[index] || null; // Inclui as colunas mesmo se estiverem vazias
                        return acc;
                    }, {} as Record<string, any>)
                );
            }

            // Valida as colunas
            const validationError = validateColumns(jsonDataValue, requiredColumns);
            if (validationError) {
                message.error(validationError);
                return; // Retorna se houver erro de validação
            }


            if (returnOriginalFile) {
                // Chama a função recebida com os dados processados
                onFileProcessed(file);
            } else {
                // Chama a função recebida com os dados processados
                onFileProcessed(jsonDataValue);
            }

            // Reseta o input após o processamento
            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Limpa o valor do input
            }
        };
        reader.readAsArrayBuffer(file);

        //@ts-ignore
        document.getElementById('file_upload').value = '';
        setIsUploading(false);
    };

    const validateColumns = (data: any[], requiredColumns: string[]): string | null => {
        if (data.length === 0) return 'O arquivo está vazio.';
        const fileColumns = Object.keys(data[0]);

        // Valida colunas faltantes
        for (const column of requiredColumns) {
            if (!fileColumns.includes(column)) {
                return `Coluna obrigatória "${decodeText(column)}" não encontrada.`;
            }
        }

        if (validateFilledFields) {
            // Verifica colunas adicionais inesperadas
            const extraColumns = fileColumns.filter(col => !requiredColumns.includes(col));
            if (extraColumns.length > 0) {
                return `O arquivo contém colunas extras que não são permitidas: ${extraColumns.join(', ')}. Por favor, remova essas colunas antes de fazer o upload.`;
            }
        }

        return null;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        if (selectedFile) {
            const isXlsx = selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const isCsv = selectedFile.type === 'text/csv';

            if (isXlsx) {
                processFile(selectedFile); // Processa o arquivo XLSX
                setFileName(selectedFile.name);
            } else if (isCsv) {
                processFile(selectedFile); // Processa o arquivo CSV
                setFileName(selectedFile.name);
            } else {
                // Exibe mensagens personalizadas para arquivos inválidos
                if (selectedFile.name.endsWith('.xlsx') || selectedFile.name.endsWith('.csv')) {
                    message.error(`O tipo do arquivo "${selectedFile.name}" não é suportado. Tente novamente.`);
                } else {
                    message.error(
                        `O arquivo selecionado não é válido. Por favor, envie um arquivo no formato XLSX ou CSV.`
                    );
                }
            }
        } else {
            message.error(`Nenhum arquivo foi selecionado.`);
        }
    };

    return (
        <div>
            <input
                type="file"
                accept={accept ? accept : ".xlsx"}
                id="file_upload"
                onChange={handleFileChange}
                ref={fileInputRef} // Adiciona a referência ao input
                style={{ display: 'none' }} // Oculta o input
            />
            <p
                onClick={() => !isUploading && fileInputRef.current?.click()}
                className={`upload-button bg-primary-color ${isUploading ? 'disabled' : ''}`}
            >
                {isUploading ? 'Processando...' : fileName ? `Arquivo: ${fileName}` : title || 'Fazer Upload'}
            </p>
        </div>
    );
};

export default FileUpload;