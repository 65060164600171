import { Switch, Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';

//@ts-ignore
interface SwitchProps {
    label?: string;
    name: string; // Tornando o name obrigatório
    defaultChecked?: boolean;
    value?: boolean;
    disabled?: boolean;
    checkedChildren?: React.ReactNode; // Texto para quando o switch está ativo
    unCheckedChildren?: React.ReactNode; // Texto para quando o switch está inativo
    condition?: (checked: any) => boolean; // Função que verifica a condição
}

const SwitchComponent: React.FC<SwitchProps> = ({
    label,
    name,
    value,
    defaultChecked = false,
    disabled,
    checkedChildren = "Ativo",
    unCheckedChildren = "Inativo",
    condition,
}) => {
    return (
        <div className="flex flex-col w-full switch-container">
            {label && <Typography.Title level={5}>{label}</Typography.Title>}
            <Field name={name}>
                {({ field, form }: any) => (
                    <Switch
                        {...field}
                        checked={value ? value : field.value} // Utiliza o valor do Formik
                        defaultChecked={defaultChecked}
                        disabled={disabled}
                        size="middle"
                        onChange={(checked) => {
                            // Verifica a condição antes de mudar o estado
                            if (!condition || condition(checked)) {
                                form.setFieldValue(name, checked); // Atualiza o Formik com o novo valor
                            } else {
                                form.setFieldValue(name, false);
                            }
                        }}
                        checkedChildren={checkedChildren}
                        unCheckedChildren={unCheckedChildren}
                    />
                )}
            </Field>
        </div>
    );
};

export default SwitchComponent;
