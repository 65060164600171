import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import VoltageService from '../services/VoltageService';
import { PaginationParams } from '../types';
import { Voltage } from '../types/Voltage';
import { transformToValueLabel } from '../utils/functions';
import { mapErrorMessage } from '../utils/mapErrorMensage';


class VoltagemStore {
    loading: boolean = false;
    voltageList: Voltage[] = [];
    voltagem: Voltage | undefined;
    voltageSelectList: any;
    sort?: string = 'created,desc';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.voltagem = undefined;
        this.voltageList = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await VoltageService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.voltageList = response.content;
                this.totalPages = response.totalPages;
                this.page = response.number;
                this.totalElements = response.totalElements;
                this.size = response.size;
                this.sort = params.sort;
                this.voltageSelectList = transformToValueLabel(response.content, 'uuid', 'code');
            }
            this.loading = false;
        });

        return response;
    }


    async createVoltagem(voltage: Voltage) {
        this.loading = true;
        const response = await VoltageService.createVoltagem(voltage);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.voltagem = voltage;
            } else {
                message.success('Voltagem cadastrado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }


    async editVoltagem(voltage: Voltage, uuid: string | undefined) {
        this.loading = true;
        const response = await VoltageService.editVoltagem(voltage, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.voltagem = voltage;
            } else {
                message.success('Voltagem editado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }


    async getVoltagemByUuid(uuid: string) {
        this.loading = true;
        const response = await VoltageService.getVoltagemByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Voltagem`);
                return;
            }

            this.voltagem = response.data;
            this.loading = false;
        });

        return response;
    }


    async deleteVoltagem(uuid: string) {
        this.loading = true;
        const response = await VoltageService.deleteVoltagem(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Voltagem`);
                return;
            } else {
                message.success('Voltagem deletado com sucesso!');
            }

            this.voltagem = response.data;
            this.loading = false;
        });

        return response;
    }

    async deleteVoltagemAll(uuid: string) {
        this.loading = true;
        const response = await VoltageService.deleteVoltagem(uuid);

        runInAction(() => {
            if (!response.error) {
                this.voltagem = undefined;
            }
            this.loading = false;
        });

        return response;
    }
}

const voltagemStore = new VoltagemStore();
export default voltagemStore;