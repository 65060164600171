import { ColorPicker, Typography } from 'antd';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import './styles.scss';

interface ColorPickerComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    name: string; // Tornando o name obrigatório, pois o Field depende disso
}

const ColorPickerComponent: React.FC<ColorPickerComponentProps> = ({ label, name, ...props }) => {
    const { setFieldValue } = useFormikContext(); // Pega o método setFieldValue do Formik para atualizar o valor do campo

    return (
        <div className="flex flex-col w-full color-picker-container">
            {label && <Typography.Title level={5}>{label}</Typography.Title>}
            <Field name={name}>
                {({ field, meta }: any) => (
                    <>
                        <ColorPicker
                            {...field}
                            onChange={(color) => setFieldValue(name, color.toHexString())} // Atualiza o valor no Formik
                            showText={(color) => <span>Cor Selecionada: ({color.toHexString()})</span>}
                            size="large"
                        />
                        {/* Exibe mensagem de erro abaixo do campo */}
                        {meta.touched && meta.error && (
                            <Typography.Text type="danger">{meta.error}</Typography.Text>
                        )}
                    </>
                )}
            </Field>
        </div>
    );
};

export default ColorPickerComponent;
