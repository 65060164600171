import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionCustomer } from '../../../permissions/PermissionCustomer';
import { PermissionOrder } from '../../../permissions/PermissionOrder';
import customerStore from '../../../stores/customerStore';
import orderStore from '../../../stores/orderStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ClientDetailsInformation from '../../organisms/ClientComponents/ClientDetailsInformation';
import TableOrderCustomer from '../../organisms/ClientComponents/TableOrderCustomer';
import { useAuth } from '../../protected/ProviderAuth';

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/cliente">Clientes</a>,
    },
    {
        title: <p>Detalhes do Cliente</p>,
    },
]

const ClientDetails: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { hasPermission } = useAuth();
    const navigate = useNavigate();
    const { customer, loading, defaultparams } = customerStore;

    useEffect(() => {
        const fetchData = async () => {
            if (hasPermission(PermissionOrder.GET_UUID)) {
                orderStore.getListByCustomer(uuid, defaultparams);
            }

            if (uuid && hasPermission(PermissionCustomer.GET_UUID)) {
                await customerStore.getCustomerByUuid(uuid);
            }
        };

        fetchData();
    }, [uuid]);


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação do Cliente',
            destroyInactiveTabPane: true,
            children: <ClientDetailsInformation loading={loading} customer={customer} />,
        },
        {
            key: '3',
            label: 'Pedidos Cliente',
            children: <TableOrderCustomer uuid={uuid} />,
        },
    ];

    const listRouter = () => {
        navigate(`/pedidos`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionOrder.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            <div className="flex gap-4 ">
                <div className="w-full">
                    <Tabs className="mb-0" type="card" defaultActiveKey="1" items={items} />
                </div>
            </div>
        </>
    );
});

export default ClientDetails;