import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatória'),
    baseQty: Yup.string().required('Valor Base é obrigatória'),
    displayUnits: Yup.object()
        .shape({
            value: Yup.string().required('Selecione um multiplo válido'),
        })
        .nullable()
        .required('Multiplo é obrigatório'),
});