import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class PropertiesService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.PROPERTIES}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async getPropertieByUuid(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.PROPERTIES}/classes/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async createPropertie(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.PROPERTIES}/classes`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editPropertie(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.PROPERTIES}/classes/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }


    async createPropertieValues(data: any, uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.PROPERTIES}/classes/${uuid}/values`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async deletePropertieValues(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.PROPERTIES}/property/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }


    async deletePropertie(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.PROPERTIES}/property/classes/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new PropertiesService();