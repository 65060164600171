import { Carousel } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { formatDateString } from '../../../../utils/dateFunctions';

interface SlideShowPrincipalProps {
    banner?: any;
    bannerPage?: boolean;
    isMobile?: boolean;
    viewDate?: boolean;
}

const SlideShowPrincipal: React.FC<SlideShowPrincipalProps> = observer(({ banner, bannerPage, isMobile, viewDate }) => {
    const [hoverElement, setHoverElement] = useState<boolean>(false);

    const replaceImageUrl = (url: string) => {

        if (url) {
            return url.replace(
                "https://s3.amazonaws.com/tres-coracoes-dev-sa/",
                "https://tres-coracoes-dev-sa.s3.amazonaws.com/"
            );
        }
    };

    return (
        <div onMouseLeave={() => setHoverElement(false)}>
            {banner && banner?.files?.length > 0 &&
                <Carousel arrows>
                    {banner && banner?.files?.map((item: any) => (
                        <div onMouseEnter={() => setHoverElement(true)} key={item?.file?.path}>

                            {viewDate && hoverElement &&
                                <div className="image-date-view">
                                    <p>{formatDateString(item.initialDate)}</p>
                                </div>
                            }

                            <img src={replaceImageUrl(item?.file?.path)} style={{
                                width: '100%',
                                height: isMobile ? '294px' : 'auto',
                            }} alt="carousel" />
                        </div>
                    ))}
                </Carousel>
            }
        </div>
    );
});

export default SlideShowPrincipal;