import { Tabs, TabsProps } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { PermissionConfig } from '../../../permissions/PermissionConfig';
import configStore from '../../../stores/configStore';
import productStore from '../../../stores/productStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import ButtonColor from './ButtonColor';
import MultipleControl from './ControlMultiple';
import FooterConfiguration from './FooterConfiguration';
import GeneralConfiguration from './GeneralConfiguration';
import HeaderConfiguration from './HeaderConfiguration';
import TopBarConfiguration from './TopBarConfiguration';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Cadastro de Configuração</p>,
    }
]

const CreateConfiguration: React.FC = observer(() => {
    const { configProps, multiple } = configStore;
    const [loading, setLoading] = useState(true);
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            setLoading(true);

            if (hasPermission(PermissionConfig.GET)) {
                await configStore.getConfig();
                await configStore.getMultipleRule();
            }
            setLoading(false);
        };

        getInformation();

        return () => {
            productStore.reset();
        };
    }, []);



    const initialValues = {
        //Multiple
        multiple: { value: multiple?.value === "HE" ? "Ativo" : "Inativo", label: '' },

        //Informação Inicial
        colorHeaderBackground: configProps.colorHeaderBackground || '',
        colorHeaderFont: configProps.colorHeaderFont || '',
        colorFooterBackground: configProps.colorFooterBackground || '',
        colorFooterFont: configProps.colorFooterFont || '',
        facebook: configProps.facebook || '',
        instagram: configProps.instagram || '',
        youtube: configProps.youtube || '',
        linkedin: configProps.linkedin || '',
        twitter: configProps.twitter || '',
        colorSubHeaderBackground: configProps.colorSubHeaderBackground || '',
        colorSubHeaderFont: configProps.colorSubHeaderFont || '',
        activeWeb: { value: configProps?.activeWeb, label: '' },
        activeMobile: { value: configProps?.activeMobile, label: '' },
        companyName: configProps.companyName || '',
        document: configProps.document || '',
        primaryColor: configProps.primaryColor || '',
        secondaryColor: configProps.secondaryColor || '',
        homeMetaDescription: configProps.homeMetaDescription || '',
        homeMetaTitle: configProps.homeMetaTitle || '',
        zendeskKey: configProps.zendeskKey || '',
        openingHours: configProps.openingHours || '',
        phone: configProps.phone || '',
        logo: [
            {
                name: 'logo-imagem',
                preview: configProps?.logo?.path, // URL da imagem default
                thumbUrl: configProps?.logo?.path,
            },
        ],
        favicon: [
            {
                name: 'favicon-imagem',
                preview: configProps?.favicon?.path, // URL da imagem default
                thumbUrl: configProps?.favicon?.path,
            },
        ],
    };


    // Faz o filtro da tabela
    const handleSubmit = async (values: any) => {
        setLoading(true);
        const createProps = {
            ...configProps,
            ...values,
            multipleRule: values.multiple.value,
            activeWeb: values.activeWeb.value,
            activeMobile: values.activeMobile.value,
        };

        await configStore.editConfig(createProps);
        setLoading(false);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Configuração Geral',
            children: <GeneralConfiguration />,
        },
        {
            key: '2',
            label: 'Header',
            children: <HeaderConfiguration />,
        },
        {
            key: '3',
            label: 'Footer',
            children: <FooterConfiguration />,
        },
        {
            key: '4',
            label: 'Barra Superior',
            children: <TopBarConfiguration />,
        },
        {
            key: '5',
            label: 'Botão Cores',
            children: <ButtonColor />,
        },
        {
            key: '6',
            label: 'Controle de Multiplo',
            children: <MultipleControl />,
        },
    ];

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Configuração" description='Tela para cadastro de Configurações'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionConfig.PUT]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <Tabs className="mb-0" type="card" defaultActiveKey="1" items={items} />

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionConfig.PUT]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">Cadastrar</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateConfiguration;