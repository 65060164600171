import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import ColorService from '../services/ColorService';
import { PaginationParams } from '../types';
import { Color } from '../types/Cores';
import { transformToValueLabel } from '../utils/functions';

class ColorStore {
    loading: boolean = false;
    colorList: Color[] = [];
    colorSelectList: any;
    sort?: string = 'created,desc';
    totalPages: number = 0;
    color: Color | undefined;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.color = undefined;
        this.colorList = [];
        this.colorSelectList = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await ColorService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.colorList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
                this.colorSelectList = transformToValueLabel(response.data.content, 'uuid', 'name');
            }
            this.loading = false;
        });

        return response;
    }

    async createColor(color: Color) {
        this.loading = true;
        const response = await ColorService.createColor(color);

        runInAction(() => {
            if (response.error) {
                message.error(response?.details?.response?.data?.message);
                this.color = color;
            } else {
                message.success('Cor cadastrado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async editColor(color: Color, uuid: string | undefined) {
        this.loading = true;
        const response = await ColorService.editColor(color, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(response?.details?.response?.data?.message);
                this.color = color;
            } else {
                message.success('Cor editado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async getColorByUuid(uuid: string) {
        this.loading = true;
        const response = await ColorService.getColorByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Cor');
                return;
            }
            this.color = response.data;
            this.loading = false;
        });

        return response;
    }

    async deleteColor(uuid: string) {
        this.loading = true;
        const response = await ColorService.deleteColor(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao deletar Cor');
            } else {
                message.success('Cor deletado com sucesso!');
                this.color = undefined;
            }
            this.loading = false;
        });

        return response;
    }

    async deleteColorAll(uuid: string) {
        this.loading = true;
        const response = await ColorService.deleteColor(uuid);

        runInAction(() => {
            if (!response.error) {
                this.color = undefined;
            }
            this.loading = false;
        });

        return response;
    }
}

const colorStore = new ColorStore();
export default colorStore;
