import { Carousel } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import productStore from '../../../../stores/productStore';
import ProductCard from './ProductCard';
import './styles.scss';
import SkeletonProduct from './SkeletonProduct';


interface ProductCarouselProps {
    category?: any;
    isMobile?: boolean;
}

const ProductCarousel: React.FC<ProductCarouselProps> = observer(({ category, isMobile }) => {
    const [products, setProducts] = useState<any>();
    const [loading, setLoading] = useState<any>(false);
    const { defaultparams } = productStore;
    const skeletonProductWeb = [1, 2, 3, 4];
    const skeletonProductMobile = [1, 2];

    const fetchCategoryProducts = async () => {
        setLoading(true);
        setProducts([]);

        if (category?.slug) {
            const result = await productStore.getProductByCategory(category?.slug, defaultparams);
            if (!result.error) {
                //@ts-ignore
                setProducts(result.data.content);
            } else {
                setProducts([]);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchCategoryProducts();
    }, [category])


    const settings = {
        infinite: true,
        slidesToShow: 4, // Número de slides a serem mostrados por vez
        slidesToScroll: 1,
    }

    const settingsMobile = {
        infinite: true,
        slidesToShow: 1.5, // Número de slides a serem mostrados por vez
        slidesToScroll: 1,
    }

    return (
        <div>
            {loading &&
                <div className="py-8">
                    <div className="flex pb-8 items-center justify-between">
                        <p className="text-2xl font-semibold">{category?.categoryName}</p>
                        <div className="button-carrousel">
                            <p>Ver Todos</p>
                        </div>
                    </div>

                    {isMobile &&
                        <div className="container-skeleton-web py-8">
                            {skeletonProductMobile.map((index) => (
                                <SkeletonProduct key={index} />
                            ))}
                        </div>
                    }

                    {!isMobile &&
                        <div className="container-skeleton py-8">
                            {skeletonProductWeb.map((index) => (
                                <SkeletonProduct key={index} />
                            ))}
                        </div>
                    }
                </div>
            }

            {products && products.length > 0 &&
                <div className="carrosel-products py-8 max-width-view">
                    <div>
                        {!isMobile &&
                            <div className="flex pb-8 items-center justify-between">
                                <p className="text-2xl font-semibold">{category?.categoryName}</p>
                                <div className="button-carrousel">
                                    <p>Ver Todos</p>
                                </div>
                            </div>
                        }

                        {isMobile &&
                            <div className="flex pb-8 items-center justify-between">
                                <p className="text-xl font-semibold">{category?.categoryName}</p>
                            </div>
                        }

                        <Carousel {...(isMobile ? settingsMobile : settings)} arrows>
                            {products.map((item: any, index: number) => (
                                <div key={index}>
                                    <ProductCard product={item} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            }
        </div>
    );
});

export default ProductCarousel;