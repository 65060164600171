import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import reportEvaluationsService from '../services/ReportEvaluationsService';


class ReportEvaluationsStore {
    loading: boolean = false;
    reportList: any = [];
    reportTotal: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'name',
    }


    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: any) {
        this.loading = true;
        const response = await reportEvaluationsService.getList(params);

        if (!response.error) {
            this.reportList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        }
        this.loading = false;

        return response;
    }
}

const reportEvaluationsStore = new ReportEvaluationsStore();
export default reportEvaluationsStore;