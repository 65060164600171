import type { TableColumnsType } from "antd";
import { Button, Table } from "antd";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import useQueryParams from "../../../hooks/useQueryParams";
import { PermissionReports } from "../../../permissions/PermissionReports";
import categoryStore from "../../../stores/categoryStore";
import channelStore from "../../../stores/channelStore";
import cupomStore from "../../../stores/cupomStore";
import customerStore from "../../../stores/customerStore";
import productStore from "../../../stores/productStore";
import subChannel from "../../../stores/subChannel";
import { exportToExcel } from "../../../utils/excelFunction";
import {
  convertToDayjs,
  formatCurrency,
  formattedDates,
  getFirstFilterDates,
} from "../../../utils/functions";
import AutocompleteComponent from "../../atoms/AutocompleteComponent";
import BreadCrumbComponent from "../../atoms/BreadCrumbComponent";
import ActionButton from "../../atoms/ButtonComponent";
import DatePickerComponet from "../../atoms/DatePickerComponet";
import InputComponent from "../../atoms/InputComponet";
import NotPermissionComponent from "../../atoms/NotPermissionComponent";
import SelectComponent from "../../atoms/SelectComponent";
import ContentBox from "../../molecules/ContentBox";
import Permission from "../../molecules/Permission";
import { useAuth } from "../../protected/ProviderAuth";
import { PermissionChannel } from "../../../permissions/PermissionChannel";
import { PermissionSubChannel } from "../../../permissions/PermissionSubChannel";
import { PermissionCategories } from "../../../permissions/PermissionCategories";
import { PermissionProducts } from "../../../permissions/PermissionProducts";
import { PermissionCustomer } from "../../../permissions/PermissionCustomer";

interface DataType {
  uuid: string;
  key: React.Key;
}

// BreadCrumb
const breadCrumb = [
  {
    title: <a href="/dashboard">Dashboard</a>,
  },
  {
    title: <p>Relatório de Cupom</p>,
  },
];

const orderStatus = [
  { value: "ANALYSIS", label: "Pedido Realizado" },
  { value: "PAID", label: "Pagamento Autorizado" },
  { value: "BILLED", label: "Pedido Faturado" },
  { value: "SENT", label: "Pedido Enviado" },
  { value: "DELIVERED", label: "Pedido Entregue" },
  { value: "FINISHED", label: "Pedido Concluído" },
  { value: "CANCELED", label: "Pedido Cancelado" },
];

const ReportCupom: React.FC = observer(() => {
  const { reportsCupom, loading } = cupomStore;
  const { categoryListSelect } = categoryStore;
  const { selectChannelList } = channelStore;
  const { selectSubChannelList } = subChannel;
  const [queryParams, setQueryParams] = useQueryParams();
  const [initialDate, finalDate] = getFirstFilterDates();
  const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
  const [autocompleteOptionsCliente, setAutocompleteOptionsCliente] =
    useState<any>([]);
  const { hasPermission } = useAuth();

  const initialValues = {
    date:
      queryParams.from && queryParams.to
        ? [convertToDayjs(queryParams.from), convertToDayjs(queryParams.to)]
        : [convertToDayjs(initialDate), convertToDayjs(finalDate)],
    searchTerm:
      typeof queryParams.searchTerm === "string"
        ? { value: queryParams.searchTerm, label: queryParams.labelTerm }
        : { value: "", label: "" },
    categoryUuid:
      typeof queryParams.categoryUuid === "string"
        ? { value: queryParams.categoryUuid, label: queryParams.categoryUuid }
        : { value: "", label: "" },
    channel: queryParams.channel
      ? { value: queryParams.channel, label: queryParams.channel }
      : "",
    subChannel: queryParams.subChannel
      ? { value: queryParams.subChannel, label: queryParams.subChannel }
      : "",
    searchTermCliente:
      typeof queryParams.searchTermCliente === "string"
        ? {
          value: queryParams.searchTermCliente,
          label: queryParams.labelTermCliente,
        }
        : { value: "", label: "" },
    status: queryParams.status
      ? { value: queryParams.status, label: queryParams.status }
      : "",
    finalZipCode: queryParams.finalZipCode || "",
    initialZipCode: queryParams.initialZipCode || "",
  };

  useEffect(() => {
    const initialParams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: queryParams.from
        ? queryParams.from + "T00:00:00.556"
        : initialDate + "T00:00:00.556",
      to: queryParams.to
        ? queryParams.to + "T00:00:00.556"
        : finalDate + "T00:00:00.556",
      categoryUuid: queryParams.categoryUuid || "",
      productUuid: queryParams.labelTerm || undefined,
      channel: queryParams.channel || "",
      subChannel: queryParams.subChannel || "",
      customerDocument: queryParams.labelTermCliente || undefined,
      status: queryParams.status || "",
      finalZipCode: queryParams.finalZipCode || "",
      initialZipCode: queryParams.initialZipCode || "",
    };

    const fetchData = async () => {
      const defaultparamsChannel = {
        size: 99999,
        page: 0,
        sort: "created,desc",
        search: "",
      };

      if (hasPermission(PermissionReports.VOUCHERS)) {
        cupomStore.getReports(initialParams);
      }

      if (hasPermission(PermissionChannel.GET)) {
        await channelStore.getList(defaultparamsChannel);
      }

      if (hasPermission(PermissionSubChannel.GET)) {
        await subChannel.getList(defaultparamsChannel);
      }
    };

    const fetchDataCategory = async () => {
      await categoryStore.getListCategorySelect();
    };

    if (hasPermission(PermissionCategories.GET)) {
      fetchDataCategory();
    }
    fetchData();
  }, []);

  // Colunas da Tabela
  const columns: TableColumnsType<DataType> = [
    {
      title: "Indicador",
      dataIndex: "indicador",
      key: "indicador",
      width: 500,
      align: "center",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 500,
      align: "center",
    },
  ];

  // Faz o filtro da tabela
  const handleSubmit = async (values: {
    date: any;
    channel: any;
    subChannel: any;
    searchTerm: any;
    categoryUuid: { value: string; label: string };
    searchTermCliente: any;
    status: any;
    finalZipCode: any;
    initialZipCode: any;
  }) => {
    const formattedDate = formattedDates(values.date, "YYYY-MM-DD");
    const dateInitial = formattedDate[0];
    const dateFinal = formattedDate[1];

    const params = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: dateInitial + "T00:00:00.556",
      to: dateFinal + "T00:00:00.556",
      channel: values.channel.value || "",
      subChannel: values.subChannel.value || "",
      productUuid: values.searchTerm.label || queryParams.searchTerm || "",
      categoryUuid: values.categoryUuid.value || queryParams.categoryUuid || "",
      customerDocument:
        values.searchTermCliente.label || queryParams.searchTermCliente || "",
      status: values.status.value || queryParams.status || "",
      finalZipCode: values.finalZipCode || queryParams.finalZipCode || "",
      initialZipCode: values.initialZipCode || queryParams.initialZipCode || "",
    };

    const newParams: any = {
      to: dateFinal || undefined,
      from: dateInitial || undefined,
      channel: params.channel || undefined,
      subChannel: params.subChannel || undefined,
      searchTerm: values.searchTerm.value || undefined,
      labelTerm: values.searchTerm.label || undefined,
      categoryUuid: values.categoryUuid.value || undefined,
      searchTermCliente: values.searchTermCliente.value || undefined,
      labelTermCliente: values.searchTermCliente.label || undefined,
      staus: params.status,
      finalZipCode: params.finalZipCode,
      initialZipCode: params.initialZipCode,
    };

    setQueryParams(newParams);

    //@ts-ignore
    await cupomStore.getReports(params);
  };

  // Limpa os dados do formulario
  const handleClearAndSearch = async (resetForm: () => void) => {
    resetForm(); // Limpa os valores do formulário
    setQueryParams({}); // Limpa a URL
    setAutocompleteOptions([]);

    const clearparams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: initialDate + "T00:00:00.556",
      to: finalDate + "T00:00:00.556",
      channel: "",
      subChannel: "",
      categoryUuid: "",
      productUuid: "",
      customerDocument: "",
      status: "",
      finalZipCode: "",
      initialZipCode: "",
    };

    await cupomStore.getReports(clearparams);
  };

  const handleSearch = async (searchText: string) => {
    if (searchText.length > 3) {
      try {
        const response = await productStore.searchProduct(searchText);
        setAutocompleteOptions(response);

        // Aqui você pode mapear a resposta para opções, se necessário
      } catch (error) {
        console.error("Erro ao buscar cliente:", error);
      }
    }
  };

  const handleSearchCliente = async (searchText: string) => {
    if (searchText.length > 3) {
      try {
        const response = await customerStore.searchCustomer(searchText, 'firstName');
        setAutocompleteOptionsCliente(response);
        // Aqui você pode mapear a resposta para opções, se necessário
      } catch (error) {
        console.error("Erro ao buscar cliente:", error);
      }
    }
  };

  const returnFormatedList = (reportResponse: any) => {
    const newList = [
      {
        indicador: "Quantidade de Itens",
        total: reportResponse?.amountItems || 0,
      },
      {
        indicador: "Quantidade de Usos",
        total: reportResponse?.amountUses || 0,
      },
      {
        indicador: "Ticket Médio",
        total: formatCurrency(reportResponse?.avgTicket),
      },
      {
        indicador: "Total de Descontos",
        total: formatCurrency(reportResponse?.totalDiscounts),
      },
      {
        indicador: "Vendas Totais",
        total: formatCurrency(reportResponse?.totalSales),
      },
    ];

    return newList;
  };

  const handleExport = async () => {
    const defaultParams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: queryParams.from
        ? queryParams.from + "T00:00:00.556"
        : initialDate + "T00:00:00.556",
      to: queryParams.to
        ? queryParams.to + "T00:00:00.556"
        : finalDate + "T00:00:00.556",
      categoryUuid: queryParams.categoryUuid || "",
      productUuid: queryParams.labelTerm || undefined,
      channel: queryParams.channel || "",
      subChannel: queryParams.subChannel || "",
      customerDocument: queryParams.labelTermCliente || undefined,
      status: queryParams.status || "",
      finalZipCode: queryParams.finalZipCode || "",
      initialZipCode: queryParams.initialZipCode || "",
    };

    const dataExport = await cupomStore.getReports(defaultParams);

    if (dataExport) {
      const listExport = [{
        amountItems: dataExport.amountItems,
        amountUses: dataExport.amountUses,
        avgTicket: formatCurrency(dataExport.avgTicket),
        totalDiscounts: formatCurrency(dataExport.totalDiscounts),
        totalSales: formatCurrency(dataExport.totalSales),
      }];

      exportToExcel(listExport, ["Quantidade de Itens", "Quantidade de Usos", "Ticket Médio", "Total de Descontos", "Vendas Totais"], "Tabela de Relatório de Cupom.xlsx");
    }
  };

  return (
    <>
      <BreadCrumbComponent breadCrumbList={breadCrumb} />
      <Permission permissionKeys={[PermissionReports.VOUCHERS]} fallback={NotPermissionComponent()}>
        <ContentBox
          className="mb-4 p-6"
          title="Relatório de Cupom"
          description="Informações sobre as Cupom"
        >
          {/* @ts-ignore */}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(
              { resetForm } // Passa o resetForm do render prop de Formik
            ) => (
              <Form>
                <div className="flex items-end w-full gap-4 pb-3">
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionCategories.GET]} inputType name="Categoria">
                      <SelectComponent
                        label="Categoria"
                        name="categoryUuid"
                        options={categoryListSelect}
                      />
                    </Permission>
                  </div>
                  <div className="w-full">
                    <SelectComponent
                      label="Status"
                      name="status"
                      options={orderStatus}
                    />
                  </div>
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionChannel.GET]} inputType name="Canal">
                      <SelectComponent
                        label="Canal"
                        name="channel"
                        options={selectChannelList}
                      />
                    </Permission>
                  </div>
                </div>
                <div className="flex items-end w-full gap-4 pb-3">
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionSubChannel.GET]} inputType name="SubCanal">
                      <SelectComponent
                        label="Sub Canal"
                        name="subChannel"
                        options={selectSubChannelList}
                      />
                    </Permission>
                  </div>
                  <div className="w-full">
                    <InputComponent
                      label="CEP Inicial"
                      name="initialZipCode"
                      id="initialZipCode"
                    />
                  </div>
                  <div className="w-full">
                    <InputComponent
                      label="CEP Final"
                      name="finalZipCode"
                      id="finalZipCode"
                    />
                  </div>
                </div>
                <div className="flex items-end w-full gap-4 pb-3">
                  <div className="w-full">
                    <DatePickerComponet label="Data inicial" name="date" />
                  </div>
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionProducts.GET]} inputType name="Buscar-Produtos">
                      <AutocompleteComponent
                        label="Buscar Produtos"
                        name="searchTerm"
                        placeholder="Digite o nome do produto"
                        //@ts-ignore
                        fetchOptions={handleSearch}
                        options={autocompleteOptions}
                      />
                    </Permission>
                  </div>
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionCustomer.GET]} inputType name="Buscar-Clientes">
                      <AutocompleteComponent
                        label="Buscar Clientes"
                        name="searchTermCliente"
                        placeholder="Digite o nome do cliente"
                        //@ts-ignore
                        fetchOptions={handleSearchCliente} // Passa a função de busca
                        options={autocompleteOptionsCliente}
                      />
                    </Permission>
                  </div>
                </div>
                <div className="flex items-end w-full gap-4 justify-end">
                  <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                    <Button
                      className="w-full"
                      onClick={() => handleClearAndSearch(resetForm)}
                    >
                      Limpar
                    </Button>
                    <Button className="w-full" htmlType="submit" type="primary">
                      Filtrar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ContentBox>

        <ContentBox className="mb-4 p-6">
          <div className="flex items-end w-full gap-4 pb-4 items-center justify-between">
            <p>Relatório de Cupom</p>

            <div className="flex gap-4">
              <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
            </div>
          </div>

          <Table
            className="table-custom"
            columns={columns}
            dataSource={returnFormatedList(reportsCupom).map(
              (item: any, index: number) => ({
                ...item,
                key: item.uuid || index,
              })
            )}
            loading={loading}
            size="middle"
            bordered={false}
            pagination={false}
          />
        </ContentBox>
      </Permission>
    </>
  );
});

export default ReportCupom;
