import { PermissionEvaluations } from "./PermissionEvaluations";

export const PermissionReports = {
    SALES_GENERAL: "mgr-reports-sales-general-PUT",
    ABANDONED_CARTS_GENERAL: "mgr-reports-abandoned-carts-general-PUT",
    BEST_SELLING_PRODUCTS: "mgr-reports-best-selling-products-PUT",
    BEST_SELLING_CATEGORIES: "mgr-reports-best-selling-categories-PUT",
    CAMPAINGS: "mgr-reports-campaigns-PUT",
    LOGINS: "mgr-reports-logins-PUT",
    VOUCHERS: "mgr-reports-vouchers-PUT",
    RATINGS_BY_PRODUCTS: PermissionEvaluations.GET,
    DASHBOARD: "mgr-reports-dashboard-PUT",
};