import URLS from '../config/urls';
import { Stamp } from '../types/Selos';
import BaseAPI from './BaseApi';

class SelosService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.STAMP}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createSelo(stamp: Stamp): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.STAMP}`, stamp);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async deleteSelos(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.STAMP}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

// Atribua a instância da classe a uma variável
const selosService = new SelosService();

// Exporte a variável ao invés de uma instância anônima
export default selosService;