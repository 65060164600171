// src/components/organisms/LoginForm/index.tsx
import { Button } from 'antd';
import { Form, Formik } from 'formik';
import Cookies from 'js-cookie';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { COOKIE_NAMES, COOKIE_OPTIONS } from '../../../../config/cookieConstants';
import authStore, { saveRemenber } from '../../../../stores/authStore';
import CheckboxComponent from '../../../atoms/CheckboxComponent';
import InputComponent from '../../../atoms/InputComponet';
import InputPassword from '../../../atoms/InputPassword';
import validationSchema from './validationSchema';
import CryptoJS from "crypto-js";


interface LoginContainerProps {
    setStep: Dispatch<SetStateAction<number>>;
    setRecoverStep: Dispatch<SetStateAction<number>>;
}

const LoginContainer: React.FC<LoginContainerProps> = ({ setStep, setRecoverStep }) => {
    const [remeberCheckbox, setRemeberCheckbox] = useState(() => {
        const rememberValue = Cookies.get(COOKIE_NAMES.REMEMBER);
        return rememberValue === 'true'; // Retorna true se o cookie for 'true', caso contrário, retorna false
    });

    const initialValues = {
        email: '',
        password: ''
    };

    const encryptPassword = (password: any) => {
        return CryptoJS.SHA256(password).toString(CryptoJS.enc.Hex);
    }

    const handleSubmit = async (values: { email: string; password: string }) => {
        const response = await authStore.verifyTwoFactorAuth(values.email, values.password);

        if (!response.error) {
            setStep(2);
        }
    };

    const remeberLogin = () => {
        const newValue = !remeberCheckbox;
        if (newValue) {
            saveRemenber('true', COOKIE_OPTIONS.TOKEN);
        } else {
            Cookies.set(COOKIE_NAMES.REMEMBER, 'false', COOKIE_OPTIONS.NOT_REMEMBER)
        }
        setRemeberCheckbox(newValue);
    };


    return (
        <div className="flex flex-col items-center justify-center rounded-xl p-8 bg-white min-w-96" >
            <div className="flex flex-col items-center justify-center pb-6">
                <p className="font-semibold text-lg">Bem vindo de volta!</p>
                <p className="text-slate-400 text-sm">Faça login para acessar a plataforma</p>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <div className="w-full">
                        <Form className="flex flex-col justify-start gap-6 w-full">
                            <InputComponent label="E-mail" name="email" id="email" />
                            <InputPassword label="Senha" name="password" id="password" />

                            <div className="flex justify-between">
                                <CheckboxComponent checked={remeberCheckbox} onChange={() => remeberLogin()} label="Lembrar-se" />
                                <p className="text-slate-500 text-sm cursor-pointer underline" onClick={() => setRecoverStep(1)}>Esqueceu a senha ?</p>
                            </div>

                            <div className="pt-4 w-full">
                                <Button className="w-full" htmlType="submit" type="primary">Entrar</Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default LoginContainer;