import URLS from '../config/urls';
import BaseAPI from './BaseApi';

class OrderApi {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ORDERS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async getListByCustomer(uuidCustomer: string, params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ORDERS}/customers/${uuidCustomer}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async getOrder(uuidOrder: string, uuidCustomer: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ORDERS}/${uuidOrder}/customers/${uuidCustomer}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }
}

export default new OrderApi();