import React, { useState } from "react";
import AuthenticationForm from "../../organisms/AuthenticationForm";
import LoginForm from "../../organisms/LoginForm";

const LoginPage = () => {
    const [step, setStep] = useState<number>(1);

    return (
        <>
            <div className="flex flex-col items-center justify-center h-screen bg-background-main">
                <div className="flex flex-col items-center pb-6">
                    <img src="/assets/logo.png" alt="Logo" style={{ width: '150px' }} />
                </div>

                {step === 1 &&
                    <LoginForm setStep={setStep} />
                }

                {step === 2 &&
                    <AuthenticationForm setStep={setStep} />
                }
            </div>
        </>
    );
};

export default LoginPage;
