import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchemaTopBar = Yup.object().shape({
    ordination: Yup.string().required('Ordenação é obrigatório'),
    name: Yup.string().required('Nome é obrigatório'),
    position: Yup.object()
        .shape({
            value: Yup.string().required('Selecione uma posição válida'),
        })
        .nullable()
        .required('Posição é obrigatório'),
});