export const genarateSelectOptions = (items: any, parentPath: any, isSlugValue?: boolean) => {
    let options = [] as any;

    if (!items.error) {
        items.forEach((item: any) => {
            const categoryName =
                (parentPath ? parentPath + '/' : '') + item.categoryName;
            options = [...options, { value: isSlugValue ? item.slug : item.uuid, label: categoryName }];
            if (item.childrenCategory?.length > 0)
                options = [
                    ...options,
                    ...genarateSelectOptions(item.childrenCategory, categoryName, isSlugValue),
                ];
        });
    }

    return options;
}


export const generateTree = (catTree: any) => {
    return catTree.map((ct: any) => {
        return {
            key: ct.uuid,
            title: ct.categoryName,
            children: ct.childrenCategory
                ? generateTree(ct.childrenCategory)
                : undefined,
            uuid: ct.uuid
        };
    });
};


export const flattenCategories = (categoriesArray: any) => {
    let result = [] as any;

    categoriesArray.forEach((category: any) => {
        result.push({
            label: category.categoryName,
            option: category,
            value: category.uuid,
        });

        if (category.childrenCategory && category.childrenCategory.length > 0) {
            result = result.concat(flattenCategories(category.childrenCategory));
        }
    });

    return result.sort((a: any, b: any) => a.label.localeCompare(b.label));
}