import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchemaBanner = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    order: Yup.string().required('Ordenação é obrigatório'),
    type: Yup.object()
        .shape({
            value: Yup.string().required('Tipo é obrigatório'),
        })
        .nullable()
        .required('Tipo é obrigatório'),
    date: Yup.array()
        .of(Yup.string().required('Data inválida'))
        .length(2, 'É necessário selecionar um intervalo de datas')
        .required('Data é obrigatório'),
    banners: Yup.object()
        .shape({
            value: Yup.string().required('Banner é obrigatório'),
        })
        .nullable()
        .required('Banner é obrigatório'),
});


export const validationSchemaCategory = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    type: Yup.object()
        .shape({
            value: Yup.string().required('Tipo é obrigatório'),
        })
        .nullable()
        .required('Tipo é obrigatório'),
    date: Yup.array()
        .of(Yup.string().required('Data inválida'))
        .length(2, 'É necessário selecionar um intervalo de datas')
        .required('Data é obrigatório'),
    category: Yup.object()
        .shape({
            value: Yup.string().required('Categoria é obrigatório'),
        })
        .nullable()
        .required('Cateoria é obrigatório'),
});