import { Button, Drawer } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import propertiesStore from '../../../../../stores/propertiesStore';
import InputComponet from '../../../../atoms/InputComponet';
import InputNumberComponet from '../../../../atoms/InputNumberComponet';
import { validationSchema } from './schema';

interface DrawerPropertiesProps {
    isDrawerOpen: boolean;
    setIsDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}

const DrawerProperties: React.FC<DrawerPropertiesProps> = observer(({ isDrawerOpen, setIsDrawerOpen }: any) => {
    const initialValuesDisplay = {
        ordering: '',
        displayString: '',
    };

    const handleSubmitValues = async (values: { ordering: string; displayString: string }) => {
        propertiesStore.addNewValueToPropertie(values.ordering, values.displayString);
        setIsDrawerOpen(false);
    };

    return (
        <Formik
            initialValues={initialValuesDisplay}
            onSubmit={(values, { resetForm }) => {
                handleSubmitValues(values);
                resetForm();
            }}
            validationSchema={validationSchema}
        >
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastrar Propriedade"
                    width={500}
                    onClose={() => {
                        resetForm();
                        setIsDrawerOpen(false);
                    }}
                    open={isDrawerOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsDrawerOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}>
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex flex-col w-full gap-4 pb-4 items-end">
                            <div className="w-full">
                                <InputNumberComponet size="middle" label="Ordenação*" name="ordering" id="ordering" />
                            </div>
                            <div className="w-full">
                                <InputComponet size="middle" label="Valor da propriedade*" name="displayString" id="displayString" />
                            </div>
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
});

export default DrawerProperties;
