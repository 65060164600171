import BaseAPI from './BaseApi';


class ReportLogAcessService {
    async getList(url: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${url}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }
}

const reportLogAcessService = new ReportLogAcessService();
export default reportLogAcessService;

