import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    startZipCode: Yup.string()
        .required('CEP Início é obrigatório')
        .length(9, 'O CEP deve ter exatamente 9 caracteres'), // Validação de comprimento exato

    endZipCode: Yup.string()
        .required('CEP Fim é obrigatório')
        .length(9, 'O CEP deve ter exatamente 9 caracteres'),
});