// src/components/organisms/LoginForm/index.tsx
import { Alert, Button } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import authStore from '../../../../stores/authStore';
import InputComponent from '../../../atoms/InputComponet';
import validationSchema from './validationSchema';
import { MailOutlined } from '@ant-design/icons';


interface ForgoutPasswordProps {
    setStep: Dispatch<SetStateAction<number>>;
    setEmail: Dispatch<SetStateAction<string>>;
}

const ForgoutPassword: React.FC<ForgoutPasswordProps> = ({ setStep, setEmail }) => {
    const initialValues = {
        email: '',
    };

    const handleSubmit = async (values: { email: string }) => {
        const response = await authStore.redefinePass(values.email);

        if (!response.error) {
            setEmail(values.email);
            setStep(2);
        }
    };

    return (

        <div className="flex flex-col items-center justify-center rounded-xl p-8 bg-white min-w-96" >
            <div className="flex flex-col items-center justify-center pb-6">
                <p className="font-semibold text-lg">Recuperação de Senha</p>
                <p className="text-slate-400 text-sm">Redefinir senha com stoom.</p>
            </div>

            <div className="w-full text-center mb-6 max-w-80">
                <Alert message="Digite seu e-mail e enviaremos as instruções para redefinir sua senha!." type="success" />
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <div className="w-full">
                        <Form className="flex flex-col justify-start gap-6 w-full">
                            <InputComponent prefixIcon={<MailOutlined />} label="E-mail" name="email" id="email" />

                            <div className="pt-4 w-full">
                                <Button className="w-full" htmlType="submit" type="primary">Enviar</Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>


            <div className="mt-6">
                <div className="flex gap-2 text-sm cursor-pointer" onClick={() => setStep(0)}>Lembrou a senha? <p className="text-primary-color">Faça login</p></div>
            </div>
        </div>
    );
};

export default ForgoutPassword;