import { Button, Drawer } from 'antd';
import { Form, Formik } from 'formik'; // Removi o useFormikContext daqui
import React, { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import configStore from '../../../../../stores/configStore';
import InputComponent from '../../../../atoms/InputComponet';
import InputNumberComponet from '../../../../atoms/InputNumberComponet';
import SelectComponent from '../../../../atoms/SelectComponent';
import { validationSchemaTopBar } from './schema';


export const position = [
    { id: 'LEFT', value: 'LEFT', label: 'Esquerda' },
    { id: 'RIGTH', value: 'RIGTH', label: 'Direita' },
    { id: 'CENTER', value: 'CENTER', label: 'Centro' },
];

export const Responsive = [
    { value: true, label: 'Responsivo' },
    { value: false, label: 'Web' }
];

interface DrawerPriceProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerRegisterTopBarLink: React.FC<DrawerPriceProps> = ({ isModalOpen, setIsModalOpen }) => {
    const handleSubmit = async (values: any) => {
        const createProps = {
            ...values,
            position: values.position.value,
            mobile: values.mobile.value === 'true' ? true : false,
            uuid: uuidv4(),
        };

        configStore.addTopBarContents(createProps);
        setIsModalOpen(false);
    };

    const initialValuesForm = {
        ordination: '',
        name: '',
        link: '',
        position: undefined,
        mobile: undefined,
    };

    return (
        <Formik validationSchema={validationSchemaTopBar} initialValues={initialValuesForm} onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm()
        }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastrar de Preço"
                    width={540}
                    onClose={() => {
                        resetForm(); // Reseta o formulário ao fechar
                        setIsModalOpen(false);
                    }}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}> {/* Agora funciona */}
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex gap-4 flex-col">
                            <InputNumberComponet size="middle" label="Ordenação" name="ordination" id="ordination" />
                            <InputComponent size="middle" label="Nome" name="name" id="name" />
                            <InputComponent size="middle" label="Link" name="link" id="link" />
                            <SelectComponent size="middle" label="Posição" name="position" options={position} />
                            <SelectComponent size="middle" label="Mobile" name="mobile" options={Responsive} />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
};

export default DrawerRegisterTopBarLink;
