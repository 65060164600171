import { Carousel } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { replaceImageUrl } from '../../../../utils/functions';


interface CategoryCarroselProps {
    banner?: any;
    isMobile?: boolean;
}

const CategoryCarrosel: React.FC<CategoryCarroselProps> = observer(({ banner, isMobile }) => {
    const settings = {
        infinite: true,
        slidesToShow: 8, // Número de slides a serem mostrados por vez
        slidesToScroll: 1,
    }

    const settingsMobile = {
        infinite: true,
        slidesToShow: 2, // Número de slides a serem mostrados por vez
        slidesToScroll: 1,
    }

    return (
        <div>
            <Carousel {...(isMobile ? settingsMobile : settings)} arrows>
                {banner && banner?.files?.map((item: any, index: number) => (
                    <div className="flex flex-col gap-4 justify-center items-center" key={index}>
                        <div className="flex flex-col gap-4 justify-center items-center">
                            <img style={{ width: '60px', height: '60px' }} src={replaceImageUrl(item?.file?.path)}></img>
                            <p style={{ fontSize: '13px' }} className="text-center">{item.name}</p>
                        </div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
});

export default CategoryCarrosel;