import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    order: Yup.string().required('Ordenação é obrigatória'),
    mobile: Yup.object()
        .shape({
            value: Yup.string().required('Selecione um tipo de exibicação válido'),
        })
        .nullable()
        .required('Responsivo é obrigatório'),
    type: Yup.object()
        .shape({
            value: Yup.string().required('Selecione um Tipo válido'),
        })
        .nullable()
        .required('Tipo é obrigatório'),
});