import { EyeOutlined, LinkOutlined } from '@ant-design/icons';
import { Image, Space, Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import productStore from '../../../../stores/productStore';
import { getUniqueFilters } from '../../../../utils/functions';
import { getStatusColor } from '../../../../utils/statusColorMapper';
import ContentBox from '../../../molecules/ContentBox';
import ModalComponent from '../../../organisms/ModalComponent';


interface DataType {
    baseProduct: any;
    skuCode: any;
    valEnd: any;
    valStart: any;
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

const ProductVariationList: React.FC = observer(() => {
    const { product, loading } = productStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Imagem',
            dataIndex: 'files',
            key: 'files',
            width: 55,
            render: (files) => {
                return (
                    <Image
                        width={50}
                        src={files.length > 0 && files[0].path}
                    />
                );
            },
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            filters: getUniqueFilters(product?.unitOfMeasurementList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
        },
        {
            title: 'Sku',
            dataIndex: 'skuCode',
            key: 'skuCode',
            filters: getUniqueFilters(product?.unitOfMeasurementList, 'skuCode'),
            onFilter: (value, record) => record.skuCode.indexOf(value as string) === 0,
        },
        {
            title: 'Status',
            dataIndex: 'active',
            key: 'active',
            filters: getUniqueFilters(product?.unitOfMeasurementList, 'displayUnits'),
            onFilter: (value, record) => record.displayUnits.indexOf(value as string) === 0,
            render: (active) => {
                let text = active === 'true' ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Visualizar',
            dataIndex: 'view',
            key: 'view',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/produtos-variacao/editar/${record.uuid}`} target="_blank" rel="noopener noreferrer">
                        <div><EyeOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Desvincular',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><LinkOutlined /></div>
                </Space>
            ),
        },
    ];



    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        productStore.unlinkProduct(itemSelected);
        setIsModalOpen(false);
    };


    return (
        <div>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Lista de Produtos Variação" description='Listagem de Variações do produto'>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={product?.productVariations?.filter((item: any) => item.deleted === false).map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            </ContentBox>

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Unidade <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />
        </div>
    );
});

export default ProductVariationList;