import { Drawer, Tabs, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import productStore from '../../../stores/productStore';
import InformationProduct from '../ProductComponents/InformationProduct';
import TablePrice from '../ProductComponents/TablePrice';
import TableStock from '../ProductComponents/TableStock';
import TableUnits from '../ProductComponents/TableUnits';

export const statusOptions = [
    {
        value: 'PENDING',
        label: 'Pendente'
    },
    {
        value: 'ACCEPTED',
        label: 'Aprovado'
    },
    {
        value: 'REJECTED',
        label: 'Reprovado'
    }
];

interface ModalProductsProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    product: any;
    isModel?: boolean;
}

const ModalProducts: React.FC<ModalProductsProps> = observer(({ isModalOpen, isModel, setIsModalOpen, product }) => {
    useEffect(() => {
        const getInformation = async () => {

            await productStore.getStockList(product?.skuCode);
        };

        getInformation();
        return () => {
            productStore.reset();
        };
    }, [product]);

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação do Produto',
            children: <InformationProduct isModel={isModel} product={product} />,
        },
        {
            key: '2',
            label: 'Unidade',
            children: <div className="pt-4"><TableUnits modalProduct={true} unitList={product?.unitOfMeasurementList} /></div>,
        },
        {
            key: '3',
            label: 'Preço',
            children: <div className="pt-4"><TablePrice modalProduct={true} unitList={product?.unitOfMeasurementList} priceList={product?.prices.filter((item: any) => item.show === true)} /></div>,
        },
        {
            key: '4',
            label: 'Estoque',
            children: <div className="pt-4"><TableStock /></div>,
        },
    ];


    return (
        <Drawer
            title="Informações do Produto"
            width={850}
            onClose={() => setIsModalOpen(false)}
            open={isModalOpen}
        >

            <Tabs defaultActiveKey="1" items={items} />
        </Drawer>
    );
});

export default ModalProducts;
