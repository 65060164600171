import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    propertie: Yup.object()
        .shape({
            value: Yup.string().required('Selecione uma opção válida'),
        })
        .nullable()
        .required('Propriedade é obrigatório'),
    selectedOption: Yup.object()
        .shape({
            value: Yup.string().required('Selecione uma opção válida'),
        })
        .nullable()
        .required('Campo é obrigatório'),
});