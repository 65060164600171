import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionChannel } from '../../../permissions/PermissionChannel';
import channelStore from '../../../stores/channelStore';
import { Channel } from '../../../types/Channel';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';
import SwitchComponent from '../../atoms/SwitchComponent';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/canal">Listagem de Canais</a>,
    },
    {
        title: <p>Cadastro de Canal</p>,
    },
]

const CreateChannel: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { channel, loading } = channelStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionChannel.GET_UUID)) {
                channelStore.getChannelByUuid(uuid);
            }
        };

        getInformation();

        return () => {
            channelStore.reset();
        };
    }, [uuid]);


    const initialValues: Channel = {
        name: channel?.name || '',
        code: channel?.code || '',
        active: channel?.active || false,
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Channel) => {
        let response;

        const createProps = {
            ...channel,
            ...values,
            active: values.active || false,
        };

        if (uuid) {
            response = await channelStore.editChannel(createProps, uuid);
        } else {
            response = await channelStore.createChannel(createProps);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/canal`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionChannel.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Canal" description='Tela para cadastro de Canal'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionChannel.POST, PermissionChannel.PUT, PermissionChannel.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Cadastro de Canal" description='Tela para cadastro de Canal' actions={
                                <>
                                    <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                                </>
                            }>
                                <div className="flex w-full gap-4 pb-4">
                                    <InputComponet label="Código" name="code" id="code" />
                                    <InputComponet label="Nome" name="name" id="name" />
                                </div>
                            </ContentBox>

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionChannel.PUT, PermissionChannel.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateChannel;