import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class ReportBestSelingService {
    async getList(params: any, page: string, size: string): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.BEST_SELLING_PRODUCTS}?size=${size}&page=${page}`, params);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }
}

// Atribua a instância da classe a uma variável
const reportBestSelingService = new ReportBestSelingService();

// Exporte a variável ao invés de uma instância anônima
export default reportBestSelingService;