import JSEncrypt from "jsencrypt";

const publicKeyDefault = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4CXiRYzBiH97nHWooNUa
VDokAR+r4+5AL8b3tBkyF5zaxxS22HHMG8A7x4VZKmPmWOx0yXmLBjYgT6CXKrU7
2XbB1u6uZNTCX61CKCS1+3jtczSfsjFWqfO/lEhQOgfmlq+KjYCfe97ucsn9ZYcM
Ci0Z7Rz+qRrsmyzSc0R7ldPUFxqqYwP4tUXT49W/c4Gnr14Opyezf0rFs0t7FQh+
Rqe97FuhUnmdprTyP+ChLJXeiz4vZrqWd0DLfa4VtET7xMETfJxn4DVF63r1+/yS
s7gDyJnHOjzPBUCwK2YbcmscwYNIza2pn/6gmeWKCFbu4uexmCk/hOw2JcMW/Wlj
8wIDAQAB
-----END PUBLIC KEY-----`;

const publicKey = process.env.PUBLIC_KEY || publicKeyDefault;

export const encryptData = (password: string): string | null => {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey); // Certifique-se de que publicKey está definida corretamente
    const encrypted = encrypt.encrypt(password); // Criptografa a senha

    if (!encrypted) {
        console.error("Erro ao criptografar a senha");
        return null; // Retorna null em caso de falha
    }

    return encrypted; // Retorna a senha criptografada
};