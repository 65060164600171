import { message, Tabs, TabsProps } from 'antd';
import { Form, Formik, FormikHelpers } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionProducts } from '../../../permissions/PermissionProducts';
import authStore from '../../../stores/authStore';
import categoryStore from '../../../stores/categoryStore';
import productStore from '../../../stores/productStore';
import propertiesStore from '../../../stores/propertiesStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import TablePrice from '../../organisms/ProductComponents/TablePrice';
import TableSpecification from '../../organisms/ProductComponents/TableSpecification';
import TableStock from '../../organisms/ProductComponents/TableStock';
import TableUnits from '../../organisms/ProductComponents/TableUnits';
import UplodFileProduct from '../../organisms/ProductComponents/UplodFileProduct';
import { useAuth } from '../../protected/ProviderAuth';
import ProductVariationList from './ProductVariationList';
import ProdutoInformation from './ProdutoInformation';
import { validationSchema } from './schema';
import { PermissionCategories } from '../../../permissions/PermissionCategories';
import { PermissionProperties } from '../../../permissions/PermissionProperties';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/produtos">Listagem de Produtos</a>,
    },
    {
        title: <p>Cadastro de Produto</p>,
    },
]

const CreateProduct: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { product, listPrice } = productStore;
    const navigate = useNavigate();
    const { merchant } = authStore;
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [loading, setLoading] = useState(true);
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            setLoading(true);

            if (uuid && hasPermission(PermissionProducts.GET_UUID)) {
                await productStore.getProductByUuid(uuid);
            }

            if (uuid && hasPermission(PermissionProperties.GET)) {
                await propertiesStore.getList({ size: 999999, page: 0, sort: 'created,desc' });
            }

            if (uuid && hasPermission(PermissionCategories.GET)) {
                await categoryStore.getList();
            }
            setLoading(false);
        };

        getInformation();

        return () => {
            // Remover ou comentar isso se você não quiser resetar ao desmontar
            productStore.reset();
        };
    }, [uuid]);


    const initialValues = {
        //Informação Inicial
        active: product?.active || false,
        name: product?.name,
        shortName: product?.shortName,
        barcode: product?.barcode,
        videoPath: product?.videoPath,
        slug: product?.slug,
        skuCode: product?.skuCode,
        metaCanonical: product?.metaCanonical,
        metaKeywords: product?.metaKeywords,
        metaTitle: product?.metaTitle,
        metaDescription: product?.metaDescription,
        shortDescription: product?.shortDescription,
        description: product?.description,

        //Infomação de Ficha Tecnica
        netWeight: product?.netWeight,
        weight: product?.weight,
        height: product?.height,
        length: product?.length,
        shippingWeight: product?.shippingWeight,
        shippingHeight: product?.shippingHeight,
        shippingWidth: product?.shippingWidth,
        width: product?.width,
        cubage: product?.cubage,
        ordering: '',
        path: '',
    };


    // Faz o filtro da tabela
    const handleSubmit = async (values: typeof initialValues, formikHelpers: FormikHelpers<typeof initialValues>) => {
        setLoading(true);
        let response;

        const createProps = {
            '@class': "br.com.stoom.tresCoracoes.model.TresCoracoesProduct",
            ...product,
            ...values,
            currency: "BRL",
            unitOfMeasurementList: product.unitOfMeasurementList,
            merchant,
        };

        if (uuid) {
            response = await productStore.editProduct(createProps, uuid);
        } else {
            response = await productStore.createProduct(createProps);
        }

        setLoading(false);

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/produtos`);
    }

    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <ProdutoInformation />,
        },
        {
            key: '2',
            label: 'Imagens',
            children: <UplodFileProduct />,
        },
        {
            key: '3',
            label: 'Especificação',
            children: <TableSpecification />,
        },
        {
            key: '4',
            label: 'Tabela de Unidades',
            children: <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg'><TableUnits unitList={product.unitOfMeasurementList} /></ContentBox>,
        },
        {
            key: '5',
            label: 'Tabela de Preço',
            children: <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg'><TablePrice unitList={product?.unitOfMeasurementList} priceList={listPrice} /></ContentBox>,
        },
        {
            key: '6',
            label: 'Estoque',
            children: <ContentBox title="Tabela de Estoque" description='Listagem de Estoques' className="mb-4 p-6" borderCustom='rounded-b-lg'><TableStock /></ContentBox>,
        },
        {
            key: '7',
            label: 'Variação',
            children: <ProductVariationList />,
        },
    ];

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionProducts.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading ? (
                <ContentBox className="mb-4 p-6" title="Cadastro de Produto" description="Tela para cadastro de Produto">
                    <LoadingComponent />
                </ContentBox>
            ) : (
                <Permission
                    permissionKeys={[PermissionProducts.POST, PermissionProducts.PUT, PermissionProducts.GET_UUID]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ errors, validateForm }) => (
                            <Form>
                                <Tabs
                                    className="mb-0"
                                    type="card"
                                    activeKey={activeTabKey}
                                    onChange={onChange}
                                    items={items}
                                />
                                <div className="flex justify-end">
                                    <Permission permissionKeys={[PermissionProducts.PUT, PermissionProducts.POST]} buttonType>
                                        <ActionButton onClick={async () => {
                                            // Valida o formulário e atualiza o objeto errors
                                            const formErrors = await validateForm();

                                            if (Object.keys(formErrors).length > 0) {
                                                setActiveTabKey('1'); // Redireciona para a primeira aba
                                                message.error(`Há campos que não foram preenchidos`);
                                            }
                                        }} htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                    </Permission>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Permission>
            )}
        </>
    );
});

export default CreateProduct;