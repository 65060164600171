import { EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Space, Table, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../config/config';
import useQueryParams, { buildParamsFromQueryReceivedParams } from '../../../hooks/useQueryParams';
import { PermissionTemplateEmail } from '../../../permissions/PermissionTemplateEmail';
import templateStore from '../../../stores/templateStore';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import { getStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    active: boolean | React.Key;
    subject: string;
    type: string;
    uuid: string;
    key: React.Key;
    nameTag: string;
    responsive: boolean;
    tag: string;
}

interface QueryParams {
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Templates</p>,
    },
]

const ListTemplate: React.FC = observer(() => {
    const { templateList, loading, page, totalElements, defaultparams, sort } = templateStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const { hasPermission, isColumnVisible } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
    };

    useEffect(() => {
        const params = buildParamsFromQueryReceivedParams(queryParams, mappings, defaultparams);

        const fetchData = async () => {
            if (hasPermission(PermissionTemplateEmail.GET)) {
                await templateStore.getList(params);
            }
        };

        fetchData();
    }, []);


    // Interação da Tabela como sorte
    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await templateStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;


        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort, // Use o sort da tabela se disponível
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
        });

        return params;
    };


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
            sorter: true,
            filters: getUniqueFilters(templateList, 'type'),
            onFilter: (value, record) => record.type.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('type', queryParams.sort),
        },
        {
            title: 'Assunto',
            dataIndex: 'subject',
            key: 'subject',
            sorter: true,
            filters: getUniqueFilters(templateList, 'subject'),
            onFilter: (value, record) => record.subject.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('subject', queryParams.sort),
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Ativos',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Ativo', value: true },
                { text: 'Inativo', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            defaultSortOrder: getSortOrder('active', queryParams.sort),
            render: (active) => {
                let text = active ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionTemplateEmail.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`template/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];


    const handleExport = async () => {
        const params = buildParamsFromQueryReceivedParams(queryParams, mappings, defaultparams);
        const exportParams = {
            ...params,
            size: 999999,
        }

        const dataExport = await templateStore.getList(exportParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: any) => ({
                subject: item.subject,
                type: item.type,
                created: formatDateString(item.created ?? ""),
                active: item?.active ? "Sim" : "Não",
            }));

            exportToExcel(listExport, ["Nome", "Tipo", "Data de Criação", "Ativo"], "Tabela de Templates.xlsx");
        }
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            <Permission permissionKeys={[PermissionTemplateEmail.GET]} fallback={<PermissionComponent />}>
                <ContentBox title="Templates" description='Tela de listagem de Templates' className="mb-4 p-6" actions={
                    <>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={templateList}
                        onChange={onChange}
                        loading={loading}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: DEFAULT_PAGE_SIZE,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>
        </>
    );
});

export default ListTemplate;