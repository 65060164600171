import { observer } from 'mobx-react-lite';
import React from 'react';


interface ImagemHomeComponentProps {
    banner?: any;
    bannerPage?: boolean;
    isMobile?: boolean;
}

const ImagemHomeComponent: React.FC<ImagemHomeComponentProps> = observer(({ banner, bannerPage, isMobile }) => {

    const replaceImageUrl = (url: string) => {

        if (url) {
            return url.replace(
                "https://s3.amazonaws.com/tres-coracoes-dev-sa/",
                "https://tres-coracoes-dev-sa.s3.amazonaws.com/"
            );
        }
    };

    return (
        <div>
            {banner && banner?.files?.length > 0 &&
                <div className={`flex gap-2 ${isMobile ? 'flex-col gap-2' : ''}`}>
                    {banner && banner?.files?.map((item: any) => (
                        <div key={item?.file?.path}>
                            <img src={replaceImageUrl(item?.file?.path)} style={{ width: '100%', height: 'auto' }} alt="carousel" />
                        </div>
                    ))}
                </div>
            }
        </div>
    );
});

export default ImagemHomeComponent;