import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import CategoryService from '../services/CategoryService';
import { flattenCategories, genarateSelectOptions, generateTree } from '../utils/categoryFunctions';


class CategoryStore {
    loading: boolean = false;
    categoryListSelect: any = [];
    categoryProps: any = [];
    selectCategory: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.categoryProps = undefined;
    }

    constructor() {
        makeAutoObservable(this);
    }


    async getList() {
        this.loading = true;
        const response = await CategoryService.getList();

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Categorias`);
                return;
            }

            this.categoryListSelect = generateTree(response.data);
            this.selectCategory = flattenCategories(response.data);
            this.loading = false;
        });

        return response.data;
    }


    async getCategoryByUuid(uuid: string) {
        this.loading = true;
        const response = await CategoryService.getCategoryByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Campanha`);
                return;
            }

            this.categoryProps = response.data;
            this.loading = false;
        });

        return response;
    }


    async createCategory(data: any) {
        this.loading = true;

        data.parentCategory = this.categoryProps?.parentCategory;
        const response = await CategoryService.createCategory(data);

        runInAction(async () => {
            if (response.error) {
                this.categoryProps = data;
                message.error(response.details.status === 409 ? `Já existe uma categoria com esse nome` : `Erro ao criar Categoria`);
            } else {
                message.success('Categoria cadastrada com sucesso!');
                await this.saveImage(data);
            }

            this.loading = false;
        });


        return response;
    }

    async editCategory(data: any, uuid: string | undefined) {
        this.loading = true;
        data.parentCategory = this.categoryProps?.parentCategory
        const response = await CategoryService.editCategory(data, uuid);

        runInAction(async () => {
            if (response.error) {
                message.error(`Erro ao editar Categoria`);
                this.categoryProps = data;
            } else {
                message.success('Categoria editada com sucesso!');
                await this.saveImage(data);
            }
            this.loading = false;
        });

        return response;
    }

    async deleteImage(uuid: string) {
        const response = await CategoryService.deleteCategoryImage(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Image`);
            } else {
                message.success('Imagem deletada com sucesso!');
                const filterImage = this.categoryProps.files.filter((item: any) => item.uuid !== uuid);
                this.categoryProps.files = filterImage;
            }
        });

        return response;
    }

    async saveCategoryTree(uuidCategory?: any) {
        if (uuidCategory !== undefined) {
            this.categoryProps.parentCategory = {
                uuid: uuidCategory
            };
        } else {
            this.categoryProps.parentCategory = undefined;
        }
    }

    async saveImage(category: any) {
        if (category.imageCategory[0]?.path) {
            const file = category.imageCategory[0];

            const metadata = {
                metaTags: ['category'],
                path: file.path
            };

            const formData = new FormData();
            formData.append('file', file); // O arquivo deve ser um objeto File
            formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            CategoryService.saveCategoryImage(formData, category.uuid);
        }

        if (category?.imageCategoryResponsive[0]?.path) {
            const file = category.imageCategoryResponsive[0];

            const metadata = {
                metaTags: ['category-mobile'],
            };

            const formData = new FormData();
            formData.append('file', file); // O arquivo deve ser um objeto File
            formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            CategoryService.saveCategoryImage(formData, category.uuid);
        }


        if (category?.imageIconCategory[0]?.path) {
            const file = category.imageIconCategory[0];

            const metadata = {
                metaTags: ['icon-category'],
            };

            const formData = new FormData();
            formData.append('file', file); // O arquivo deve ser um objeto File
            formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
            CategoryService.saveCategoryImage(formData, category.uuid);
        }
    }


    async deleteCategory(uuid: string) {
        this.loading = true;
        const response = await CategoryService.deleteCategory(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao deletar Categoria');
            } else {
                message.success('Categoria deletada com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async getListCategorySelect(isSlugValue?: boolean) {
        this.loading = true;
        const response = await CategoryService.getList();

        runInAction(() => {
            this.categoryListSelect = genarateSelectOptions(response.data, '', isSlugValue);
            this.loading = false;
        });
    }
}

const categoryStore = new CategoryStore();
export default categoryStore;