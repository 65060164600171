import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionSize } from '../../../permissions/PermissionSize';
import sizeStore from '../../../stores/sizeStore';
import { Size } from '../../../types/Size';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/tamanhos">Listagem de Tamanhos</a>,
    },
    {
        title: <p>Cadastro de Tamanho</p>,
    },
]


const CreateSize: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { sizeProps, loading } = sizeStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionSize.GET_UUID)) {
                sizeStore.getSizeByUuid(uuid);
            }
        };
        getInformation();
        return () => {
            sizeStore.reset();
        };
    }, []);


    const initialValues: Size = {
        code: sizeProps?.code || '',
        description: sizeProps?.description || '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Size) => {
        let response;

        const updatedProps = {
            ...sizeProps,
            ...values,
        };

        if (uuid) {
            response = await sizeStore.editSize(updatedProps, uuid);
        } else {
            response = await sizeStore.createSize(updatedProps);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/tamanhos`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionSize.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Novo Tamanho" description='Tela para cadastro de Tamanho'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionSize.POST, PermissionSize.PUT, PermissionSize.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Cadastro de Tamanho" description='Tela para cadastro de Tamanho'>
                                <div className="flex w-full gap-4 pb-4">
                                    <InputComponet maxLength={5} label="Código" name="code" id="code" disabled={uuid ? true : false} />
                                    <InputComponet label="Descrição" name="description" id="description" />
                                </div>
                            </ContentBox>


                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionSize.PUT, PermissionSize.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik>
                </Permission>
            }

        </>
    );
});

export default CreateSize;