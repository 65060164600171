import type { TableColumnsType } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import logStore from '../../../../../stores/logStore';
import { formatDateString } from '../../../../../utils/dateFunctions';
import { exportToExcel } from '../../../../../utils/excelFunction';
import { getActionColor, getActionLog } from '../../../../../utils/statusLogMapper';
import ActionButton from '../../../../atoms/ButtonComponent';
import ContentBox from '../../../../molecules/ContentBox';
import { convertToDayjs, formattedDates, getFirstFilterDates } from '../../../../../utils/functions';
import { ParsedQuery } from 'query-string';
import { DEFAULT_PAGE_SIZE } from '../../../../../config/config';
import { Form, Formik } from 'formik';
import InputComponent from '../../../../atoms/InputComponet';
import DatePickerRangeComponet from '../../../../atoms/DatePickerRangeComponet';
import ModalLogComparativo from '../ModalLogComparativo';

interface DataType {
    idUser: any;
    email: any;
    username: any;
    description: string;
    code: string;
    uuid: string;
    key: React.Key;
}

interface LogProductProps {
    setQueryParams: (params: Record<string, any>) => void;
    queryParams: ParsedQuery<string>;
}

const LogProduct: React.FC<LogProductProps> = observer(({ setQueryParams, queryParams }) => {
    const { logProduct, loading, page, totalElements, defaultparams, sort, size } = logStore;
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [initialDate, finalDate] = getFirstFilterDates();

    const params = {
        entityName: 'Product',
        startDate: queryParams.startDate
            ? queryParams.startDate + "T00:00:00.556"
            : initialDate + "T00:00:00.556",
        endDate: queryParams.endDate
            ? queryParams.endDate + "T23:59:59.999"
            : finalDate + "T23:59:59.999",
        page: queryParams.page || 0,
        size: queryParams.page || DEFAULT_PAGE_SIZE,
        modifiedBy: queryParams.modifiedBy || 'philipe.costa@stoom.com.br'
    }


    useEffect(() => {
        const fetchData = async () => {
            await logStore.getListProduct(params);
        };

        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Código',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Usuario',
            dataIndex: 'modifiedBy',
            key: 'modifiedBy',
        },
        {
            title: 'Modificação',
            dataIndex: 'modifiedDate',
            key: 'modifiedDate',
            render: (item) => <p>{formatDateString(item)}</p>,
        },
        {
            title: 'Ação',
            dataIndex: 'operation',
            key: 'operation',
            render: (item) => {
                return (
                    <>{item && <Tag color={getActionColor(item)}> <p>{getActionLog(item)}</p></Tag>}  </>
                );
            },
        },
        {
            title: 'Visualizar',
            dataIndex: 'view',
            key: 'view',
            align: 'center',
            width: 30,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <div onClick={() => showModal(record)}>
                        <div><EyeOutlined /></div>
                    </div>
                </Space>
            ),
        },
    ];


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };


    const handleExport = async () => {
        const dataExport = await logStore.getListProduct('br.com.stoom.tresCoracoes.model.TresCoracoesProduct');

        if (dataExport) {
            const listExport = dataExport.data.map((item: any) => ({
                actionType: getActionLog(item.actionType.type),
                userDetails: item.userDetails.idUser,
                user: item.actionType.type,
                email: item.userDetails.email,
                created: formatDateString(item.userDetails.created ?? ""),
                modified: formatDateString(item.userDetails.modified ?? ""),
            }));

            exportToExcel(listExport, ["Ação", "ID Usuário", "Usuário", "E-mail", "Criação", "Modificação"], "Tabela de Tamanhos.xlsx");
        }
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: { modifiedBy: string, date: any }) => {
        const formattedDate = formattedDates(values?.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];

        const newParams: any = {
            modifiedBy: values?.modifiedBy || undefined,
        };

        // Cria os parâmetros base
        const params: Record<string, any> = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            entityName: 'TresCoracoesChannel',
            modifiedBy: values?.modifiedBy || undefined,
            startDate: dateInitial ? `${dateInitial}T00:00:00` : undefined,
            endDate: dateFinal ? `${dateFinal}T23:59:59` : undefined,
        };

        Object.keys(params).forEach((key) => {
            if (params[key] === undefined) {
                delete params[key];
            }
        });

        setQueryParams(newParams);

        // //@ts-ignore
        await logStore.getListProduct(params);
    };

    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await logStore.getListProduct(params);
    };


    const initialValues = {
        modifiedBy: Array.isArray(queryParams.modifiedBy) ? queryParams.modifiedBy[0] || '' : queryParams.modifiedBy || '',
        date:
            queryParams.startDate && queryParams.endDate
                ? [convertToDayjs(queryParams.startDate), convertToDayjs(queryParams.endDate)]
                : [convertToDayjs(initialDate), convertToDayjs(finalDate)],
    };

    return (
        <>

            <ContentBox borderCustom='rounded-b-lg' className="mb-4 p-6" title="Log de Produtos" description='Tela de logs de produtos'>
                <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    {({ resetForm }) => (
                        <Form>
                            <div className="flex items-end w-full gap-4 pb-4">
                                <div className="w-full">
                                    <InputComponent label="Usuário" name="modifiedBy" id="modifiedBy" />
                                </div>
                                <div className="w-full">
                                    <DatePickerRangeComponet label="Data" name="date" />
                                </div>
                                <div className="flex items-end gap-4 justify-end ">
                                    <Button onClick={() => handleClearAndSearch(resetForm)}>
                                        Limpar
                                    </Button>
                                    <Button htmlType="submit" type="primary">Filtrar</Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ContentBox>

            <ContentBox className="mb-4 p-6" description='Listagem de logs de Produtos' actions={
                <>
                    <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                </>
            }>

                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={logProduct.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={{
                        current: page + 1,
                        pageSize: size,
                        total: totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60'],
                    }}
                    showSorterTooltip={{ target: 'sorter-icon' }}
                />
            </ContentBox>

            {isModalOpen && <ModalLogComparativo setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} itemSelected={itemSelected} />}
        </>
    );
});

export default LogProduct;