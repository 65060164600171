import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import InstitucionalService from '../services/InstitucionalService';
import { PaginationParams } from '../types';
import { Institucional, InstitucionalList } from '../types/Institucional';


class InstitucionalStore {
    loading: boolean = false;
    institucionalList: InstitucionalList[] = [];
    institucional: Institucional | undefined;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.institucional = undefined;
        this.institucionalList = [];
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await InstitucionalService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.institucionalList = response.data.result.content;
                this.totalPages = response.data.result.totalPages;
                this.page = response.data.result.number;
                this.totalElements = response.data.result.totalElements;
                this.size = response.data.result.size;
            } else {
                this.institucionalList = [];
            }
            this.loading = false;
        });
        return response;
    }


    async getInstitucionalByUuid(uuid: string) {
        this.loading = true;
        const response = await InstitucionalService.getInstitucionalByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Institucional`);
                return;
            }

            this.institucional = response.data.result;
            this.loading = false;
        });
        return response;
    }

    async createInstitucional(data: any) {
        this.loading = true;
        const response = await InstitucionalService.createInstitucional(data);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao criar Institucional');
            } else {
                message.success('Institucional cadastrado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }


    async editInstitucional(data: any, uuid: string | undefined) {
        this.loading = true;
        const response = await InstitucionalService.editInstitucional(data, uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao editar Institucional');
            } else {
                message.success('Institucional editado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }


    async deleteInstitucional(uuid: string) {
        this.loading = true;
        const response = await InstitucionalService.deleteInstitucional(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Institucional`);
            } else {
                message.success('Institucional deletado com sucesso!');
            }

            this.institucional = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteInstitucionalAll(uuid: string) {
        this.loading = true;
        const response = await InstitucionalService.deleteInstitucional(uuid);
        runInAction(() => {
            this.institucional = response.data;
            this.loading = false;
        });
        return response;
    }
}

const institucionalStore = new InstitucionalStore();
export default institucionalStore;