import { Tabs, TabsProps } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionCampaigns } from '../../../permissions/PermissionCampaigns';
import campaingStore from '../../../stores/campaingStore';
import categoryStore from '../../../stores/categoryStore';
import channelStore from '../../../stores/channelStore';
import subChannel from '../../../stores/subChannel';
import { convertToDayjs, formattedDates, updateOrAddRestriction } from '../../../utils/functions';
import { findChannel, findRestrictionChannel, findRestrictionProduct, findSubChannel } from '../../../utils/functionsRestrictions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import ApplicationType from './components/ApplicatinType';
import ChannelOptions from './components/ChannelOptions';
import CustomerAddPromotion from './components/CustomerAddPromotion';
import InformationComponent from './components/InformationComponent';
import TableZipCodeRestriction from './components/TableZipCodeRestriction';
import { validationSchema } from './schema';


interface FormValues {
    "@class": string;
    active: { value: string; label: string }
    type: { value: string; label: string };
    productsPercentageDiscount: string;
    excludeZipCode: { value: string; label: string };
    excludeProductCategory: { value: string; label: string };
    name: string;
    productsAbsoluteDiscount: string;
    date: any;
    aplicationType: { value: string; label: string };
    excludeClient: { value: string; label: string };
    excludeChannelOrSubChannel: { value: string; label: string };
    channel: { value: string; label: string };
    subChannel: { value: string; label: string };
    channelOptions: { value: string; label: string };
}

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/campanhas">Listagem de Campanhas</a>,
    },
    {
        title: <p>Cadastro de Campanhas</p>,
    },
]

const CreateCampaing: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const navigate = useNavigate();
    const [aplicationType, setAplicationType] = useState<any>();
    const [channelOrSubChannelOptions, setChannelOrSubChannelOptions] = useState<any>();
    const { campaignProps, loading, zipCodeRestrictionList, channelOrSubChannel, customerRestrictionItem, categoriesOrProdutcts, dateRestrictionList, customerList, categoryList, zipCodeRestriction, productList } = campaingStore;
    const { hasPermission } = useAuth();

    useEffect(() => {
        getInformation();
        return () => {
            campaingStore.reset();
        };
    }, []);


    const defaultparams = {
        size: 999999,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    const getInformation = async () => {
        if (uuid && hasPermission(PermissionCampaigns.GET_UUID)) {
            const response = await campaingStore.getCampaingByUuid(uuid);
            channelStore.getList(defaultparams);
            subChannel.getList(defaultparams);
            setAplicationType({ value: findRestrictionProduct(response?.restrictions) || '', label: '' })
            setChannelOrSubChannelOptions({ value: findRestrictionChannel(response?.restrictions) || '', label: '' })
        }

        await categoryStore.getList();
    }


    //@ts-ignore
    const initialValues: FormValues = {
        name: campaignProps?.name || '',
        active: campaignProps?.active || '',
        type: { value: campaignProps?.type, label: '' },
        // productsAbsoluteDiscount: (campaignProps && campaignProps?.discounts && campaignProps?.discounts[0]?.productsAbsoluteDiscount) || '',
        productsAbsoluteDiscount: (campaignProps && campaignProps?.discounts && campaignProps?.discounts[0]?.productsAbsoluteDiscount) || '',
        productsPercentageDiscount: (
            campaignProps &&
            campaignProps.discounts &&
            campaignProps.discounts[0]?.productsPercentageDiscount * 100
        ) || '',
        date: campaignProps ? [convertToDayjs(dateRestrictionList?.minDate), convertToDayjs(dateRestrictionList?.maxDate)] : '',
        excludeZipCode: { value: zipCodeRestriction?.excludes === true ? 'true' : 'false', label: '' },
        excludeProductCategory: { value: categoriesOrProdutcts?.excludes === true ? 'true' : 'false', label: '' },
        aplicationType: { value: findRestrictionProduct(campaignProps?.restrictions) || '', label: '' },
        channelOptions: { value: findRestrictionChannel(campaignProps?.restrictions) || '', label: '' },
        excludeClient: { value: customerRestrictionItem?.excludes === true ? 'true' : 'false', label: '' },
        excludeChannelOrSubChannel: { value: channelOrSubChannel?.excludes === true ? 'true' : 'false', label: '' },
        channel: { value: findChannel(campaignProps?.restrictions)?.value || '', label: findChannel(campaignProps?.restrictions)?.label || '' },
        subChannel: { value: findSubChannel(campaignProps?.restrictions)?.value || '', label: findSubChannel(campaignProps?.restrictions)?.label || '' },
    };


    const handleSubmit = async (values: FormValues) => {
        let response;
        const formattedDate = formattedDates(values.date, 'YYYY-MM-DD');

        // Inicializa as restrições
        const restrictions = [...(campaignProps?.restrictions || [])];

        // Atualiza ou adiciona a restrição de Data
        updateOrAddRestriction(restrictions, {
            "@class": "br.com.stoom.kernel.model.persistence.restriction.DateRestriction",
            minDate: `${formattedDate[0]}T00:00:00.000`,
            maxDate: `${formattedDate[1]}T23:59:59.000`
        });

        // Atualiza ou adiciona a restrição de CEP
        if (zipCodeRestrictionList?.length > 0) {
            updateOrAddRestriction(restrictions, {
                "@class": "br.com.stoom.kernel.model.persistence.restriction.ZipCodeRestriction",
                excludes: values.excludeZipCode?.value === 'true',
                zipCodeRangeRestrictions: zipCodeRestrictionList
            });
        } else {
            updateOrAddRestriction(restrictions, {
                "@class": "br.com.stoom.kernel.model.persistence.restriction.ZipCodeRestriction",
                excludes: '',
                zipCodeRangeRestrictions: []
            });
        }

        // Atualiza ou adiciona a restrição de Categoria de Produto
        if (categoryList?.length > 0 || productList?.length > 0) {
            updateOrAddRestriction(restrictions, {
                "@class": "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction",
                excludes: values.excludeProductCategory?.value === 'true',
                categories: aplicationType.value === 'CATEGORIA' ? categoryList.map((uuid: string) => ({ uuid })) : [],
                products: aplicationType.value === 'PRODUTO' ? productList : []
            });
        } else {
            updateOrAddRestriction(restrictions, {
                "@class": "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction",
                excludes: values.excludeProductCategory?.value === 'true',
                categories: [],
                products: [],
            });
        }


        // Atualiza ou adiciona a restrição de Cliente
        if (customerList && customerList?.length > 0) {
            updateOrAddRestriction(restrictions, {
                "@class": "br.com.stoom.kernel.model.persistence.restriction.CustomerRestriction",
                excludes: values.excludeClient?.value === 'true',
                documents: customerList
            });
        }

        // Regra de Canal e SubCanal
        if (channelOrSubChannelOptions) {
            updateOrAddRestriction(restrictions, {
                "@class": "br.com.stoom.tresCoracoes.model.restrictions.TresCoracoesChannelRestriction",
                excludes: values.excludeChannelOrSubChannel?.value === 'true',
                channels: channelOrSubChannelOptions.value === 'channel' ? [
                    {
                        uuid: values?.channel?.value
                    }
                ] : [],
                subChannels: channelOrSubChannelOptions.value === 'subChannel' ? [
                    {
                        uuid: values?.subChannel?.value
                    }
                ] : []
            });
        }

        const updateValues = {
            ...campaignProps,
            active: values.active || false,
            name: values.name,
            type: values?.type?.value,
            bean: "Discount_Campaign",
            discounts: [
                {
                    "@class": "br.com.stoom.kernel.model.persistence.Discount",
                    productsAbsoluteDiscount: values?.type?.value === 'Desconto Absoluto X' ? (values?.productsAbsoluteDiscount) : 0,
                    //@ts-ignore
                    productsPercentageDiscount: values?.type?.value === 'Desconto Percentual X' ? (values?.productsPercentageDiscount / 100) : 0,
                    shippingAbsoluteDiscount: 0,
                    shippingPercentageDiscount: 0,
                    buyXPayY: {
                        x: 0,
                        y: 0,
                        cumulative: true,
                        uuid: campaignProps && campaignProps.discounts && campaignProps?.discounts[0]?.buyXPayY?.uuid
                    },
                    uuid: campaignProps && campaignProps.discounts && campaignProps?.discounts[0]?.uuid
                }
            ],
            restrictions: restrictions || []
        };

        if (uuid) {
            response = await campaingStore.editCampaing(updateValues, uuid);
        } else {
            response = await campaingStore.createCampaing(updateValues);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/campanhas`);
    }

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <InformationComponent />,
        },
        {
            key: '2',
            label: 'Canal/SubCanal',
            children: <ChannelOptions channelOrSubChannelOptions={channelOrSubChannelOptions} setChannelOrSubChannelOptions={setChannelOrSubChannelOptions} />,
        },
        {
            key: '3',
            label: 'Faixa do CEP ',
            children: <TableZipCodeRestriction />,
        },
        {
            key: '4',
            label: 'Aplicação ',
            //@ts-ignore
            children: <ApplicationType setAplicationType={setAplicationType} aplicationType={aplicationType} />,
        },
        {
            key: '5',
            label: 'Cliente ',
            children: <CustomerAddPromotion />,
        },
    ];


    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionCampaigns.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Campanha" description='Tela para cadastro de Campanha'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionCampaigns.POST, PermissionCampaigns.PUT, PermissionCampaigns.GET_UUID]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <>
                            <Form>
                                <Tabs className="mb-0" type="card" defaultActiveKey="1" items={items} />
                                <div className="flex justify-end">
                                    <Permission permissionKeys={[PermissionCampaigns.PUT, PermissionCampaigns.POST]} buttonType>
                                        <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                    </Permission>
                                </div>
                            </Form>
                        </>
                    </Formik >
                </Permission>
            }
        </>
    );
});

export default CreateCampaing;

