import { format } from 'date-fns';
import dayjs from "dayjs";

export function formatDateString(value: string, separator = '/', reverse = false) {
    if (!value) return '';
    const date = new Date(value);

    const month = `${'0'}${date.getMonth() + 1}`.slice(-2);
    const day = `${'0'}${date.getDate()}`.slice(-2);

    if (reverse) {
        return `${day}${separator}${month}${separator}${date.getFullYear()}`;
    }

    return `${day}/${month}/${date.getFullYear()}`;
}


export const convertIsoToTime = (isoString: string): string | undefined => {
    const isValidIso = dayjs(isoString).isValid();
    if (isValidIso) {
        return dayjs(isoString).format('HH:mm:ss'); // Extrai apenas a hora no formato HH:mm:ss
    }
    return undefined;
};


export const extractTime = (timeString: string): string | undefined => {
    // Verifica se o formato da string é apenas hora (HH:mm:ss)
    const isTimeFormat = /^\d{2}:\d{2}:\d{2}$/.test(timeString);
    if (isTimeFormat) {
        // Combina a hora com a data atual para formar uma string completa no formato ISO
        const today = dayjs().format('YYYY-MM-DD'); // Pega a data atual no formato 'YYYY-MM-DD'
        const isoString = dayjs(`${today}T${timeString}`).toISOString(); // Combina com o tempo e converte para ISO
        return isoString;
    }
    return undefined;
};


//converte formate date "2024-02-29T10:26:33.926-03:00"
//to 29/02/20024
export const formatData = (data: any) => {
    if (data !== undefined) {
        // Primeiro, parse a data para um objeto Date
        const dataObj = new Date(data);

        // Use a função format do date-fns para formatar a data e o horário
        const dataFormatada = format(dataObj, 'dd/MM/yyyy HH:mm:ss');

        return dataFormatada;
    }
};


export const formatDateAndTime = (dateString: string) => {
    if (dateString) {
        const date = new Date(dateString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    } else {
        return ""
    }
};