import { LineChartOutlined, PieChartOutlined, RiseOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import reportGeneralSalesStore from '../../../../../stores/reportGeneralSalesStore';
import { formatCurrency, roundPercentage } from '../../../../../utils/functions';
import ContentBox from '../../../../molecules/ContentBox';
import './styles.scss';


const DashboardInformation: React.FC = observer(() => {
    const [informationBlock, setInformationBlock] = useState<any>();

    useEffect(() => {
        // Função para calcular as datas
        const getDates = () => {
            const currentDate = new Date();
            const fromDate = new Date(currentDate);
            const toDate = new Date(currentDate);

            // Subtrai um mês da data atual
            fromDate.setMonth(currentDate.getMonth() - 1);

            // Formata as datas para ISO string
            const from = fromDate.toISOString();
            const to = toDate.toISOString();
            return { from, to };
        };

        const { from, to } = getDates();

        const initialParams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            "paymentsTypes": "INVOICE",
            "status": "ANALYSIS",
            "from": from,
            "to": to,
        };

        const fetchData = async () => {
            const result = await reportGeneralSalesStore.getGeneralSales(initialParams);
            setInformationBlock(result);
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="grid grid-cols-2 gap-6 h-2/4 pb-3">
                <div className="col-span-1">
                    <ContentBox className="mb-4 p-6 flex flex-col justify-between h-full">
                        <div className="flex items-center gap-4">
                            <div className="icon flex justify-center items-center rounded-md min-w-12"><PieChartOutlined /></div>
                            <p className="text-base font-semibold dark:text-white">Ticket Medio</p>
                        </div>

                        <p className="text-xl font-semibold dark:text-white">{formatCurrency(informationBlock?.totalSalesValues / informationBlock?.totalSalesOrders)}</p>

                        <p className="text-gray-400 text-sm dark:text-white">Últimos 30 Dias</p>
                    </ContentBox>
                </div>
                <div className="col-span-1">
                    <ContentBox className="mb-4 p-6 flex flex-col justify-between h-full">
                        <div className="flex items-center gap-4">
                            <div className="icon flex justify-center items-center rounded-md min-w-12"><LineChartOutlined /></div>
                            <p className="text-base font-semibold dark:text-white">Número de pedidos</p>
                        </div>

                        <p className="text-xl font-semibold dark:text-white">{informationBlock?.totalSalesOrders}</p>

                        <p className="text-gray-400 text-sm dark:text-white">Últimos 30 Dias</p>
                    </ContentBox>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-6 h-2/4 pt-3">
                <div className="col-span-1">
                    <ContentBox className="p-6 flex flex-col justify-between h-full">
                        <div className="flex items-center gap-4">
                            <div className="icon flex justify-center items-center rounded-md min-w-12"><RiseOutlined /></div>
                            <p className="text-base font-semibold dark:text-white">Taxa de conversão</p>
                        </div>

                        <p className="text-xl font-semibold dark:text-white">{roundPercentage(informationBlock?.percentageOrdersConverted)}%</p>

                        <p className="text-gray-400 text-sm dark:text-white">Últimos 30 Dias</p>
                    </ContentBox>
                </div>
                <div className="col-span-1">
                    <ContentBox className="p-6 flex flex-col justify-between h-full">
                        <div className="flex items-center gap-4">
                            <div className="icon flex justify-center items-center rounded-md min-w-12"><PieChartOutlined /></div>
                            <p className="text-base font-semibold dark:text-white">Valor total</p>
                        </div>

                        <p className="text-xl font-semibold dark:text-white">{formatCurrency(informationBlock?.totalSalesValues)} </p>

                        <p className="text-gray-400 text-sm dark:text-white">Últimos 30 Dias</p>
                    </ContentBox>
                </div>
            </div>
        </>
    );
});


export default DashboardInformation;