import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import ChannelService from '../services/ChannelService';
import { PaginationParams } from '../types';
import { transformToValueLabel } from '../utils/functions';
import { Channel } from '../types/Channel';
import { mapErrorMessage } from '../utils/mapErrorMensage';


class ChannelStore {
    loading: boolean = false;
    channelList: Channel[] = [];
    selectChannelList: any[] = [];
    channel: Channel | undefined;
    sort?: string = 'created,desc';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.channel = undefined;
        this.selectChannelList = [];
        this.selectChannelList = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await ChannelService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.selectChannelList = transformToValueLabel(response.data.content, 'uuid', 'name');
                this.channelList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            }
            this.loading = false;
        });

        return response;
    }


    async createChannel(channel: Channel) {
        this.loading = true;
        const response = await ChannelService.createChannel(channel);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.channel = channel;
            } else {
                message.success('Canal cadastrado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }


    async editChannel(channel: Channel, uuid: string | undefined) {
        this.loading = true;
        const response = await ChannelService.editChannel(channel, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(mapErrorMessage(response?.details?.response?.data?.message));
                this.channel = channel;
            } else {
                message.success('Canal editado com sucesso!');
            }
            this.loading = false;
        });
        return response;
    }


    async getChannelByUuid(uuid: string) {
        this.loading = true;
        const response = await ChannelService.getChannelByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Canal`);
                return;
            }

            this.channel = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteChannelAll(uuid: string) {
        this.loading = true;
        const response = await ChannelService.deleteChannel(uuid);
        runInAction(() => {
            this.channel = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteChannel(uuid: string) {
        this.loading = true;
        const response = await ChannelService.deleteChannel(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Canal`);
            } else {
                message.success('Canal deletado com sucesso!');
            }
            this.channel = response.data;
            this.loading = false;
        });
        return response;
    }
}

const channelStore = new ChannelStore();
export default channelStore;