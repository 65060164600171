import React from 'react';
import { Upload, Button, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Field, ErrorMessage } from 'formik';
import './input.scss';

interface ImageUploadComponentProps {
    label?: string;
    name: string; // Tornando o name obrigatório
    maxCount?: number; // Número máximo de arquivos permitidos
    disabled?: boolean;
    onRemoveAction?: (file: any) => void; // Callback externo para o evento de remoção
    accept?: string;
    onChange?: (option: any) => void;
}

const createPreviewImage = (values: any[], uidToDelete?: string) =>
    values
        .filter((file: any) => file.uid !== uidToDelete) // Filtra o arquivo pelo uid
        .map((file: any) =>
            file.preview
                ? file
                : Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    path: file.name,
                    thumbUrl: URL.createObjectURL(file),
                })
        );

const ImageUploadComponent: React.FC<ImageUploadComponentProps> = ({ label, name, maxCount = 5, disabled, onRemoveAction, accept, onChange }) => {
    return (
        <div className="flex flex-col w-full input-upload">
            {label && <Typography.Title level={5}>{label}</Typography.Title>}
            <Field name={name}>
                {({ field, meta, form }: any) => {
                    return (
                        <>
                            <Upload
                                accept={accept ? accept : "image/*"}
                                listType="picture"
                                beforeUpload={(file) => {
                                    let fileList = field.value || [];
                                    createPreviewImage([file]);

                                    if (fileList.length >= maxCount) {
                                        fileList = [file, ...fileList.slice(1)];
                                    } else {
                                        fileList = [...fileList, file];
                                    }

                                    onChange && onChange(file);
                                    form.setFieldValue(name, fileList);
                                    return false;
                                }}
                                onRemove={(file) => {
                                    if (onRemoveAction) {
                                        onRemoveAction(file);
                                        const fileList = field.value || [];
                                        //@ts-ignore
                                        const newFileList = fileList.filter((item: File) => item?.uid !== file.uid);
                                        form.setFieldValue(name, newFileList);
                                    } else {
                                        const fileList = field.value || [];
                                        //@ts-ignore
                                        const newFileList = fileList.filter((item: File) => item?.uid !== file.uid);
                                        form.setFieldValue(name, newFileList);
                                    }
                                }}
                                fileList={field.value || []}
                                disabled={disabled}
                            >
                                <Button icon={<UploadOutlined />}>Selecione a Imagem</Button>
                            </Upload>
                            <ErrorMessage name={name}>
                                {(msg) => <Typography.Text type="danger">{msg}</Typography.Text>}
                            </ErrorMessage>
                        </>
                    );
                }}
            </Field>
        </div>
    );
};

export default ImageUploadComponent;
