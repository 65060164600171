import URLS from '../config/urls';
import { SubChannelModel } from '../types/SubChannel';
import BaseAPI from './BaseApi';


class SubChannelService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SUBCHANNELS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async createSubChannel(subChannel: SubChannelModel): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.SUBCHANNELS}`, subChannel);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editSubChannel(subChannel: SubChannelModel, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.SUBCHANNELS}/${uuid}`, subChannel);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async getSubChannelByUuid(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SUBCHANNELS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async deleteSubChannel(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.SUBCHANNELS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new SubChannelService();