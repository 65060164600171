import URLS from '../config/urls';
import { Shipping } from '../types/Shipping';
import BaseAPI from './BaseApi';


class ShippingService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SHIPPING}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createShipping(shipping: Shipping): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.SHIPPING}`, shipping);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }


    async editShipping(shipping: Shipping, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.SHIPPING}/${uuid}`, shipping);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }


    async getShippingByUuid(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SHIPPING}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async deleteShipping(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.SHIPPING}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new ShippingService();