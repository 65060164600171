import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import ComponentsService from '../services/ComponentsService';
import { PaginationParams } from '../types';
import { transformToValueLabel } from '../utils/functions';

class ComponentsStore {
    loading: boolean = false;
    componentsList: any[] = [];
    componentsListSelect: any;
    sort?: string = 'created,desc';
    totalPages: number = 0;
    componentProps: any | undefined;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.componentProps = undefined;
        this.componentsList = [];
        this.componentsListSelect = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await ComponentsService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.componentsList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
                this.componentsListSelect = transformToValueLabel(response.data.content, 'uuid', 'name');
            }
            this.loading = false;
        });

        return response;
    }


    async createComponet(component: any) {
        this.loading = true;
        const response = await ComponentsService.createComponent(component);

        runInAction(() => {
            if (response.error) {
                message.error(response?.details?.response?.data?.message);
                this.componentProps = component;
            } else {
                message.success('Component cadastrado com sucesso!');
            }
            this.loading = false;
        });

        return response.data;
    }

    async editComponent(component: any, uuid: string | undefined) {
        this.loading = true;
        const response = await ComponentsService.editComponent(component, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(response?.details?.response?.data?.message);
                this.componentProps = component;
            } else {
                message.success('Componente editado com sucesso!');
            }
            this.loading = false;
        });

        return response;
    }

    async getComponentByUuid(uuid: string) {
        this.loading = true;
        const response = await ComponentsService.getComponentByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Componente');
                return;
            }
            this.componentProps = response.data;
            this.loading = false;
        });

        return response.data;
    }

    async deleteComponente(uuid: string) {
        this.loading = true;
        const response = await ComponentsService.deleteComponente(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao deletar Componente');
            } else {
                message.success('Componente deletado com sucesso!');
                this.componentProps = undefined;
            }
            this.loading = false;
        });

        return response;
    }

    async deleteCompoentesAll(uuid: string) {
        this.loading = true;
        const response = await ComponentsService.deleteComponente(uuid);

        runInAction(() => {
            if (!response.error) {
                this.componentProps = undefined;
            }
            this.loading = false;
        });

        return response;
    }
}

const componentsStore = new ComponentsStore();
export default componentsStore;
