import { Button } from 'antd';
import { Form, Formik } from 'formik';
import 'jsoneditor/dist/jsoneditor.css';
import { observer } from 'mobx-react-lite';
import React from 'react';
import templateStore from "../../../../stores/templateStore";
import InputComponent from "../../../atoms/InputComponet";
import ContentBox from "../../../molecules/ContentBox";
import { validationSchema } from './schema';


interface TemplateSendProps {
    htmlFromServer?: string;
    jsonData: any;
}

const TemplateSend: React.FC<TemplateSendProps> = observer(({ htmlFromServer, jsonData }) => {
    const { template } = templateStore;

    const initialValues = {
        to: '',
        subject: '',
    };

    const handleSubmitTest = async (values: { subject: string; to: string }) => {
        const createProps = {
            data: {
                ...jsonData,
                html: htmlFromServer,
            },
            subject: values.subject,
            to: [values.to]
        };

        templateStore.senEmailTesting(createProps)
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitTest}
            validationSchema={validationSchema}
        >
            {({ submitForm }) => (
                <Form>
                    <ContentBox className="mb-4 p-6" title="Visualização do Template">
                        <div className="flex gap-4 mb-4 items-end">
                            <InputComponent label="Assunto*" name="subject" id="subject" />
                            <InputComponent label="Destinataio (To)*" name="to" id="to" />

                            <div className="flex">
                                <Button onClick={submitForm} type="primary">Enviar</Button>
                            </div>
                        </div>

                        <div
                            className="element-view"
                            //@ts-ignore
                            dangerouslySetInnerHTML={{ __html: htmlFromServer }}
                        />
                    </ContentBox>
                </Form>
            )}
        </Formik>
    );
});

export default TemplateSend;