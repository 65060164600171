import { observer } from 'mobx-react-lite';
import React from 'react';
import { replaceImageUrl } from '../../../../utils/functions';


interface BlogComponentProps {
    banner?: any;
    isMobile?: boolean;
}

const BlogComponent: React.FC<BlogComponentProps> = observer(({ banner, isMobile }) => {

    return (
        <div className="grid grid-cols-12 flex gap-4 justify-center items-center">
            {banner && banner?.files?.map((item: any, index: number) => (
                <div className={`flex flex-col gap-4 justify-center items-start ${isMobile ? 'col-span-12' : 'col-span-4'}`} key={index}>
                    <img src={replaceImageUrl(item?.file?.path)} style={{
                        width: '100%',
                        height: isMobile ? '240px' : 'auto',
                    }}></img>

                    <div className="flex flex-col gap-2 items-start">
                        <p className="text-center font-medium">{item.name}</p>
                        <p className="text-center">{item.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
});

export default BlogComponent;