export const findRestrictionChannel = (restriction: any) => {
    const findRestriction = restriction?.find((item: any) => item['@class'] === 'br.com.stoom.tresCoracoes.model.restrictions.TresCoracoesChannelRestriction');

    if (findRestriction && findRestriction?.channels?.length > 0) {
        return 'channel'
    }

    if (findRestriction && findRestriction?.subChannels?.length > 0) {
        return 'subChannel'
    }
}

export const findChannel = (restriction: any) => {
    const findRestriction = restriction?.find((item: any) => item['@class'] === 'br.com.stoom.tresCoracoes.model.restrictions.TresCoracoesChannelRestriction');

    if (findRestriction && findRestriction?.channels?.length > 0) {
        return {
            value: findRestriction?.channels[0]?.uuid,
            label: findRestriction?.channels[0]?.name
        }
    }
}

export const findSubChannel = (restriction: any) => {
    const findRestriction = restriction?.find((item: any) => item['@class'] === 'br.com.stoom.tresCoracoes.model.restrictions.TresCoracoesChannelRestriction');

    if (findRestriction && findRestriction?.subChannels?.length > 0) {
        return {
            value: findRestriction?.subChannels[0]?.uuid,
            label: findRestriction?.subChannels[0]?.name
        }
    }
}


export const findRestrictionProduct = (restriction: any) => {
    const findRestriction = restriction?.find((item: any) => item['@class'] === 'br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction');

    if (findRestriction && findRestriction.categories.length > 0) {
        return 'CATEGORIA'
    }

    if (findRestriction && findRestriction.products.length > 0) {
        return 'PRODUTO'
    }
}


export const findPriceRestriction = (restriction: any, type: string) => {
    const findRestriction = restriction?.find((item: any) => item['@class'] === 'br.com.stoom.kernel.model.persistence.restriction.PriceRestriction');

    if (type === 'maxPrice') {
        return findRestriction?.maxPrice;
    }

    if (type === 'minPrice') {
        return findRestriction?.minPrice;
    }

    if (type === 'exclude') {
        return findRestriction?.excludes === true ? 'true' : 'false'
    }
}