import { observer } from 'mobx-react-lite';
import React, { Dispatch } from 'react';
import channelStore from '../../../../../stores/channelStore';
import subChannel from '../../../../../stores/subChannel';
import { Status } from '../../../../../utils/selects';
import SelectComponent from '../../../../atoms/SelectComponent';
import ContentBox from '../../../../molecules/ContentBox';
import Permission from '../../../../molecules/Permission';
import { PermissionChannel } from '../../../../../permissions/PermissionChannel';
import { PermissionSubChannel } from '../../../../../permissions/PermissionSubChannel';


export const modelOptions = [
    { value: 'channel', label: 'Canal' },
    { value: 'subChannel', label: 'SubCanal' },
];


interface ChannelOptionsProps {
    channelOrSubChannelOptions?: any;
    setChannelOrSubChannelOptions: Dispatch<any>;
}

const ChannelOptions: React.FC<ChannelOptionsProps> = observer(({ channelOrSubChannelOptions, setChannelOrSubChannelOptions }) => {
    const { selectSubChannelList } = subChannel;
    const { selectChannelList } = channelStore;

    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Selecione o Canal ou SubCanal" description='Tela para cadastro de Campanha'>
                <div className="mb-4">
                    <SelectComponent label="Remove Faixa Canal ou SubCanal" name="excludeChannelOrSubChannel" options={Status} />
                </div>

                <div className="flex gap-4">
                    <SelectComponent onChange={(value, option) => setChannelOrSubChannelOptions(option)} label="Canal/SubCanal" name="channelOptions" options={modelOptions} />

                    {channelOrSubChannelOptions?.value === 'channel' &&
                        <Permission permissionKeys={[PermissionChannel.GET]} inputType name="Canal">
                            <SelectComponent label="Canal" name="channel" options={selectChannelList} />
                        </Permission>
                    }

                    {channelOrSubChannelOptions?.value === 'subChannel' &&
                        <Permission permissionKeys={[PermissionSubChannel.GET]} inputType name="SubCanal">
                            <SelectComponent label="SubCanal" name="subChannel" options={selectSubChannelList} />
                        </Permission>}
                </div>
            </ContentBox>
        </>
    );
});

export default ChannelOptions;