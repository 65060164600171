import { AlertFilled, CheckCircleFilled, ClockCircleFilled, CloseCircleFilled, CreditCardFilled, StopFilled, TruckFilled } from '@ant-design/icons';
import React from 'react';
import {
  FaFileInvoiceDollar,
  FaFlagCheckered
} from 'react-icons/fa';
import { formatData } from '../../../../../utils/dateFunctions';
import './styles.scss';



interface StatusRuleComponentProps {
  statusChanges?: any;
  orderStatus: any;
  paymentOptionName: string;
  order: any;
}

const StatusRuleComponent = ({
  statusChanges,
  paymentOptionName,
  order,
  orderStatus,
}: StatusRuleComponentProps) => {
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'ANALYSIS':
        return <CheckCircleFilled />;
      case 'PENDING_PAYMENT':
        return <ClockCircleFilled />;
      case 'PAID':
        return <CreditCardFilled />;
      case 'BILLED':
        return <FaFileInvoiceDollar />;
      case 'SENT':
        return <TruckFilled />;
      case 'DELIVERED':
        return <FaFlagCheckered />;
      // case 'FINISHED':
      //   return <FaCheckCircle />;
      case 'RETURNED':
        return <AlertFilled />;
      case 'CANCELED':
        return <StopFilled />;
      default:
        return null;
    }
  };

  const filteredStatusRules = orderStatus?.filter((item: any) => {
    // Se o status for "RETURNED" ou "CANCELED", verifique se está em filledStatus
    if (
      item.value === 'RETURNED' ||
      item.value === 'CANCELED' ||
      item.value === 'PENDING_PAYMENT'
    ) {
      return statusChanges?.some(
        (status: any) => status?.newStatus?.statusName === item?.value
      );
    }

    if (paymentOptionName === 'Boleto_Faturado') {
      // Se o status for "PAID" ou "PENDING_PAYMENT" e houver algum pagamento faturado...
      if (item.value === 'PAID' || item.value === 'PENDING_PAYMENT') {
        // ...remova "Pagamento Autorizado" e "Pendente de Pagamento" da lista
        return false;
      }
    }

    // Se não for "RETURNED" ou "CANCELED", mantenha na lista
    return true;
  });

  const isCanceledInStatusChanges = statusChanges && statusChanges.some(
    (status: any) => status.newStatus.statusName === 'CANCELED'
  );

  const isReturnedInStatusChanges = statusChanges && statusChanges.some(
    (status: any) => status.newStatus.statusName === 'RETURNED'
  );


  return (
    <>
      {orderStatus &&
        <div className="container-order">
          <div className="progress-container">
            <div className="progress" id="progress"></div>

            {filteredStatusRules.map((item: any, index: any) => {
              let isCanceled = false;

              console.log('order', order);

              const isFilled = statusChanges?.some(
                (status: any) => status?.newStatus?.statusName === item.value || status.previousStatus.statusName === item.value
              );

              const filterData = statusChanges?.find(
                (status: any) => status?.newStatus?.statusName === item.value
              );

              if (isCanceledInStatusChanges || isReturnedInStatusChanges) {

                // Verifica se item.value está presente no array statusChanges
                const isItemValuePresent = statusChanges?.some(
                  (status: any) => status.newStatus.statusName === item.value || status.previousStatus.statusName === item.value
                );

                isCanceled = !isItemValuePresent;
              }

              if (item.value === 'CANCELED' || item.value === 'RETURNED') {
                isCanceled = true;
              }

              if (order?.status?.statusName === item.status) {

              }

              const combinedClass = `text-wrap ${isFilled ? 'active' : ''} ${isCanceled ? 'canceled-text' : ''
                }`;

              return (
                <div className={combinedClass} key={item.id}>
                  <div
                    className={`circle ${isFilled ? 'filled' : ''} ${isCanceled ? 'canceled' : ''
                      }`}
                  >
                    <>
                      {isCanceled ? (
                        <CloseCircleFilled />
                      ) : (
                        <>
                          {isFilled ? (
                            <CheckCircleFilled />
                          ) : (
                            <div className="icon-color">
                              {getStatusIcon(item.value)}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </div>
                  <p className="text">{item.label}</p>
                  <p className="text text-date">
                    {item.value === "ANALYSIS" ?
                      <>{formatData(order?.created)}</> : <>{formatData(filterData?.created)}</>
                    }
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      }
    </>
  );
};

export default StatusRuleComponent;
