import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    date: Yup.array()
        .of(Yup.string().required('Data inválida'))
        .length(2, 'É necessário selecionar um intervalo de datas')
        .required('Data é obrigatório'),
    type: Yup.object()
        .shape({
            value: Yup.string().required('Selecione um tipo válido'),
        })
        .nullable()
        .required('Tipo é obrigatório'),
    // productsPercentageDiscount: Yup.string().required('Valor do desconto é obrigatório'),
    // productsAbsoluteDiscount: Yup.string().required('Valor do desconto Absoluto é obrigatório'),
});