import dayjs from "dayjs";
import { UploadFile } from 'antd/es/upload/interface';
import { formatDateString } from "./dateFunctions";

export interface SortConfig {
    order: 'ascend' | 'descend';
    field: string;
}


export const getSortField = (config: any): string => {
    const { order, columnKey } = config;

    if (order === 'ascend') {
        return columnKey; // Retorna apenas o nome do campo
    } else if (order === 'descend') {
        return `${columnKey},desc`; // Adiciona ",desc" ao nome do campo
    }

    return columnKey; // Retorna o nome do campo por padrão
};



export const valueStatusConvert = (prop: string) => {
    switch (prop) {
        case 'ANALYSIS':
            return 'Pedido Realizado';
        case 'BILLED':
            return 'Pedido Faturado';
        case 'SENT':
            return 'Pedido Enviado';
        case 'PENDING_PAYMENT':
            return 'Pendente de Pagamento';
        case 'DELIVERED':
            return 'Pedido Entregue';
        case 'FINISHED':
            return 'Pedido Concluído';
        case 'PAID':
            return 'Pagamento Autorizado';
        case 'PENDING':
            return 'Pedido pendente';
        case 'CANCELED':
            return 'Pedido Cancelado';
        case 'RETURNED':
            return 'Pedido Devolvido';
        case 'CAPTURED':
            return 'Pedido Capturado';
        case 'ACCEPTED':
            return 'Pedido Aceito';
        case 'AUTHORIZED':
            return 'Pedido Autorizado';
        case 'CART':
            return 'Carrinho';
        default:
            return prop;
    }
};


export const formatNumbers = (value: any, prefix = '', suffix = '') => {
    if (!value) {
        return prefix.concat('0,00').concat(suffix);
    }

    if (!Number.isNaN(value)) {
        if (value.parseFloat) {
            value = value.parseFloat(value).toFixed(2);
        } else if (value.toFixed) {
            value = value.toFixed(2);
        }
    }

    let v = value.toString().replace(/\D/g, '');
    v = `${(v / 100).toFixed(2)}`;
    v = v.replace('.', ',');
    v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
    v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
    return prefix.concat(v).concat(suffix);
};


export const mapPaymentOptionName = (name: string) => {
    switch (name) {
        case 'Boleto_Faturado':
            return 'Pagamento Faturado';
        case 'Invoice':
            return 'Boleto';
        case 'CC_Generic':
            return 'Cartão de Crédito';
        case 'Pix':
            return 'PIX';
        default:
            return 'Outros';
    }
};


export const formatCurrency = (value = 0, currency = 'BRL') =>
    value.toLocaleString('pt-BR', {
        style: 'currency',
        currency,
    });


interface Item {
    [key: string]: any; // Definindo que a chave do objeto pode ser qualquer string
}

export const transformToValueLabel = (
    data: Item[],
    valueKey: string,
    labelKey: string
) => {
    return data.map(item => ({
        value: item[valueKey],
        label: item[labelKey],
        option: item,
    }));
};


// Faz um filtro pelos dados da tabela
export const getUniqueFilters = <T>(data: T[], key: any) => {
    //@ts-ignore
    const uniqueValues = Array.from(new Set(data?.map(item => item[key] as unknown as string))); // Extrai valores únicos e faz o cast
    return uniqueValues.map(value => ({
        text: value, // Não é necessário converter novamente para string aqui, já estamos tratando como string
        value: value,
    }));
};


// Faz um filtro pelos dados da tabela com mais de uma key
export const getUniquePropertyFilters = <T, K extends keyof T, P extends keyof T[K]>(data: T[], key: K, propertyName: P) => {
    const uniqueValues = Array.from(new Set(data.map(item => item[key] ? item[key][propertyName] : ''))); // Acesso dinâmico à propriedade
    return uniqueValues.map(value => ({
        text: value,
        value: value,
    }));
};

type Filters = {
    [key: string]: string | undefined;
};

export const buildSearchString = (filters: Filters, keys: string[]): string => {
    return keys
        .map(key => (filters[key] ? `${key};"${filters[key]}"` : null))
        .filter(Boolean)
        .join(' ');
};



export const getSortOrder = (sortField: string, querySort: string | null | (string | null)[]): 'ascend' | 'descend' | undefined => {
    if (!querySort) return undefined;

    // Se for um array, pega o primeiro elemento
    const sortString = Array.isArray(querySort) ? querySort[0] : querySort;

    //@ts-ignore
    const [field, order] = sortString.split(',');

    if (field === sortField) {
        return order === 'desc' ? 'descend' : 'ascend';
    }

    return undefined;
};


export const renderRestrictionsByClass = (restrictions: any, targetClass: string) => {
    return restrictions.map((restriction: any) => {
        // Verifica se a classe do item corresponde à classe alvo
        if (restriction['@class'] === targetClass) {
            const minDate = formatDateString(restriction.minDate);
            const maxDate = restriction.maxDate.includes('2121')
                ? 'Sem Limite'
                : formatDateString(restriction.maxDate);


            const date = `${minDate} - ${maxDate}`
            return date;
        }
        return null; // Retorna null se a condição não for atendida
    }).filter(Boolean); // Remove os itens null
};



export const formattedDates = (dateArray: any, format: string) => {
    const formattedDate = dateArray ? dateArray.map((date: any) => date.format(format)) : [];
    return formattedDate
}


export const convertToDayjs = (dateString: any) => {
    return dayjs(dateString);
};

export const getFirstFilterDates = () => {
    const fDate = new Date();
    const tDate = new Date();

    // Define a data de 30 dias atrás
    fDate.setDate(fDate.getDate() - 30);

    // Função para formatar a data no padrão YYYY-MM-DD
    const formatDate = (date: any) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Retorna as datas formatadas
    return [formatDate(fDate), formatDate(tDate)];
}


export const roundPercentage = (value: number, decimals: number = 2) => {
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
}




//Categoria



interface BannerFile {
    uuid: string;
    name: string;
    file: {
        path: string;
        name: string;
    };
}

export const transformToUploadFile = (bannerFiles: BannerFile[]): UploadFile[] => {
    return bannerFiles.map((bannerFile, index) => ({
        uid: bannerFile.uuid || `-${index}`, // Gera um uid baseado no uuid ou no índice
        name: bannerFile.file.name, // Nome do arquivo
        status: 'done', // Status de upload
        url: bannerFile.file.path, // URL para o arquivo
        thumbUrl: bannerFile.file.path, // URL da miniatura (geralmente a mesma do URL)
    }));
};


// Cria um preview de imagens 
export const createPreviewImage = (values: any[]) =>
    values.map((file: any) =>
        file.preview
            ? file
            : Object.assign(file, {
                preview: URL.createObjectURL(file),
                path: file.name,
            })
    );


// Desabilita a selação de categoria pelo uuid passado.
export const disableNodesWithUuid = (data: any) => {
    return data.map((item: any) => ({
        ...item,
        disabled: !!item.uuid, // Desabilita se tiver um UUID
        children: item.children ? disableNodesWithUuid(item.children) : [],
    }));
};


export const removeSpecialCharacters = (value: string) => {
    const valueToString = value?.toString();
    return valueToString?.replace(/[^a-zA-Z0-9 ]/g, '');
};


export const updateOrAddRestriction = (existingRestrictions: any[], newRestriction: any) => {
    const index = existingRestrictions.findIndex(restriction => restriction["@class"] === newRestriction["@class"]);

    if (index !== -1) {
        // Se a restrição já existe, substitui
        existingRestrictions[index] = { ...existingRestrictions[index], ...newRestriction };
    } else {
        // Se não existe, adiciona uma nova
        existingRestrictions.push(newRestriction);
    }
};


export const convertString = (input: string) => {
    return input.split(' ').join('_');
}


export const transformToValueUnitLabel = (
    data: Item[],
    valueKey: string,
    labelKey: string
) => {
    return data.map(item => ({
        value: item[valueKey],
        label: item[labelKey],
        option: item,
    }));
};


export const inversionValueStatusClient = (prop: string) => {
    switch (prop) {
        case 'Novo':
            return 'NOVO';
        case 'Pendente Consulta Externa':
            return 'PENDENTE';
        case 'Pendente Análise de Crédito':
            return 'AAC';
        case 'Bloqueado':
            return 'BLOQUEADO';
        case 'Aguardando Segmentação':
            return 'AS';
        case 'Aprovado':
            return 'APROVADO';
        case 'Reprovado':
            return 'REPROVADO';
        default:
            return prop;
    }
};




export const replaceImageUrl = (url: string) => {
    if (url) {
        return url.replace(
            "https://s3.amazonaws.com/tres-coracoes-dev-sa/",
            "https://tres-coracoes-dev-sa.s3.amazonaws.com/"
        );
    }
};


export const decodeText = (text: string): string => {
    try {
        return decodeURIComponent(escape(text));
    } catch {
        return text; // Retorna o texto original se algo der errado
    }
};