import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { utils, writeFile } from 'xlsx';
import campaingStore from '../../../../../../stores/campaingStore';
import productStore from '../../../../../../stores/productStore';
import { getUniqueFilters } from '../../../../../../utils/functions';
import AutocompleteComponent from '../../../../../atoms/AutocompleteComponent';
import ActionButton from '../../../../../atoms/ButtonComponent';
import FileUpload from '../../../../../atoms/FileUploadComponent';
import ModalComponent from '../../../../../organisms/ModalComponent';


interface DataType {
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

const ProductAddPromotion: React.FC = observer(() => {
    const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const { productList, loading } = campaingStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const handleSearch = async (searchText: string) => {
        setAutocompleteOptions([]);
        if (searchText.length > 3) {
            try {
                const response = await productStore.searchProduct(searchText);
                setAutocompleteOptions(response);
                // Aqui você pode mapear a resposta para opções, se necessário
            } catch (error) {
                console.error('Erro ao buscar cliente:', error);
            }
        }
    };

    const addProduct = () => {
        if (selectedProduct.option) {
            campaingStore.updateProduct(selectedProduct?.option?.option);
            setAutocompleteOptions([]);
            setSelectedProduct([])
        }
    }


    const handleFileProcessed = async (data: any) => {
        const promises = data.map(async (item: any) => {
            const response = await campaingStore.searchProductBySkuCode(item.SKU);
            // Retornando a resposta, se necessário
            return response?.content[0];
        });

        const results = await Promise.all(promises);
        results.forEach((result) => {
            if (result) {
                campaingStore.updateProduct(result);
            }
        });
    };


    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            filters: getUniqueFilters(productList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        campaingStore.removeProdut(itemSelected);
        setIsModalOpen(false);
    };

    const handleExport = () => {
        const headings = [['SKU']];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, [{ skuCode: '20039896' }], {
            origin: 'A2',
            skipHeader: true,
        });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Produtos.xlsx');
    };

    return (
        <div>
            <div className="flex pt-3 gap-4 items-end">
                <AutocompleteComponent
                    label="Buscar Produtos"
                    name="searchTerm"
                    placeholder="Digite o nome do Produto"
                    fetchOptions={handleSearch}
                    options={autocompleteOptions}
                    onChange={(value: any) => setSelectedProduct(value)}
                />
                <Button type='primary' onClick={() => addProduct()}>Adicionar</Button>
                <FileUpload onFileProcessed={handleFileProcessed} requiredColumns={['SKU']} />
                <ActionButton icon actionType="export" onClick={() => handleExport()}>Baixar Planilha</ActionButton>
            </div>

            <div className="mt-4">
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={productList?.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            </div>

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o produto <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />
        </div>
    );
});

export default ProductAddPromotion;
