import { Divider, TabsProps } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';

export const statusOptions = [
    {
        value: 'PENDING',
        label: 'Pendente'
    },
    {
        value: 'ACCEPTED',
        label: 'Aprovado'
    },
    {
        value: 'REJECTED',
        label: 'Reprovado'
    }
];


interface DescriptionItemProps {
    title: string;
    content: React.ReactNode;
}

const DescriptionItem = ({ title, content }: DescriptionItemProps) => (
    <div className="flex gap-2 pb-2">
        <p className="inline-block pr-2 text-gray-500 font-normal">{title}:</p>
        <p className="font-semibold text-zinc-800 font-medium">{content}</p>
    </div>
);

interface ModalProductsProps {
    product: any;
    isModel?: boolean;
}

const InformationProduct: React.FC<ModalProductsProps> = observer(({ product, isModel }) => {

    return (
        <div className="pt-4">
            <div>
                <DescriptionItem title="Nome" content={product?.name} />
            </div>

            <div>
                <DescriptionItem title="Nome curto" content={product?.shortName} />
            </div>

            <div>
                <DescriptionItem title="Status" content={product?.active === true ? 'Ativo' : 'Inativo'} />
            </div>

            <div>
                <DescriptionItem title="Slug" content={product?.slug} />
            </div>

            <div>
                <DescriptionItem title="Código SKU" content={product?.skuCode} />
            </div>

            <div>
                <DescriptionItem title="EAN" content={product?.barcode} />
            </div>

            <Divider />
            <p className="pb-4 text-base text-primary-color">Descrição</p>

            <div>
                <DescriptionItem title="Descrição curta" content={product?.shortDescription} />
            </div>

            <div>
                <DescriptionItem title="Descrição completa" content={product?.description} />
            </div>

            {isModel &&
                <>
                    <Divider />
                    <p className="pb-4 text-base text-primary-color">Meta Dados</p>

                    <div className="grid grid-cols-2 gap-4" >
                        <DescriptionItem title="Meta Keywords" content={product?.metaKeywords} />
                        <DescriptionItem title="Meta Title" content={product?.metaTitle} />
                    </div>

                    <div className="grid grid-cols-2 gap-4" >
                        <DescriptionItem title="Meta Canônica" content={product?.metaCanonical} />
                        <DescriptionItem title="Meta Description" content={product?.metaDescription} />
                    </div>

                    <Divider />
                    <p className="pb-4 text-base text-primary-color">Ficha Tecnica</p>

                    <div className="grid grid-cols-2 gap-4" >
                        <DescriptionItem title="Peso Liquido" content={product?.netWeight} />
                        <DescriptionItem title="Peso Bruto" content={product?.weight} />
                    </div>

                    <div className="grid grid-cols-2 gap-4" >
                        <DescriptionItem title="Altura (cm)" content={product?.height} />
                        <DescriptionItem title="Profundidade (cm)" content={product?.length} />
                    </div>

                    <div className="grid grid-cols-2 gap-4" >
                        <DescriptionItem title="Peso envio" content={product?.shippingWeight} />
                        <DescriptionItem title="Altura envio" content={product?.shippingHeight} />
                    </div>

                    <div className="grid grid-cols-2 gap-4" >
                        <DescriptionItem title="Largura envio" content={product?.shippingWidth} />
                        <DescriptionItem title="Largura (cm)" content={product?.width} />
                    </div>

                    <div className="grid grid-cols-2 gap-4" >
                        <DescriptionItem title="Cubagem" content={product?.cubage} />
                    </div>
                </>
            }
        </div>
    );
});

export default InformationProduct;
