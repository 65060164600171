import { TimePicker, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Field, FieldProps } from 'formik';
import './styles.scss';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

interface TimePickerComponentProps {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    name: string; // Tornando o name obrigatório
    format?: string;
    size?: string;
    id: string;
}

const TimePickerComponent: React.FC<TimePickerComponentProps> = ({ label, name, disabled, placeholder, format, size, id }) => {
    const [time, setTime] = useState<dayjs.Dayjs | null>(null); // Estado local para o valor do TimePicker

    return (
        <div className="flex flex-col w-full input-container">
            {label && <Typography.Title className="text-sm" level={5}>{label}</Typography.Title>}
            <Field name={name}>
                {({ field, meta, form }: FieldProps) => {
                    const handleChange = (selectedTime: dayjs.Dayjs | null) => {
                        const formattedTime = selectedTime ? selectedTime.format('HH:mm:ss') : '';
                        setTime(selectedTime);
                        form.setFieldValue(name, formattedTime);
                    };

                    // Sincroniza o estado local com o valor do Formik
                    if (field.value && !time) {
                        setTime(dayjs(field.value, 'HH:mm:ss'));
                    }

                    return (
                        <>
                            <TimePicker
                                className="rounded"
                                //@ts-ignore
                                size={size ? size : 'large'}
                                disabled={disabled}
                                id={id}
                                placeholder={placeholder}
                                format={format ? format : 'HH:mm:ss'}
                                status={meta.touched && meta.error ? 'error' : ''}
                                defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                value={time} // Usa o estado local
                                onChange={handleChange}
                                onBlur={() => {
                                    form.setFieldTouched(name, true); // Marca o campo como tocado
                                }}
                            />
                            {meta.touched && meta.error && (
                                <Typography.Text type="danger">{meta.error}</Typography.Text>
                            )}
                        </>
                    );
                }}
            </Field>
        </div>
    );
};

export default TimePickerComponent;
