import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import ShippingService from '../services/ShippingService';
import { PaginationParams } from '../types';
import { message } from 'antd';
import { Shipping } from '../types/Shipping';


class ShipppingStore {
    loading: boolean = false;
    shippingList: Shipping[] = [];
    shipping: Shipping | undefined;
    sortDefault: string = 'created,desc';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
    }

    reset() {
        this.shipping = undefined;
        this.shippingList = [];
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await ShippingService.getList(params);

        runInAction(() => {
            if (!response.data.error) {
                this.shippingList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            }
            this.loading = false;
        });

        return response;
    }

    async createShipping(shipping: Shipping) {
        this.loading = true;
        const response = await ShippingService.createShipping(shipping);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao criar Frete`);
            } else {
                message.success('Frete cadastrado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }

    async editShipping(shipping: Shipping, uuid: string | undefined) {
        this.loading = true;
        const response = await ShippingService.editShipping(shipping, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao editar Frete`);
            } else {
                message.success('Frete editado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }

    async getShippingByUuid(uuid: string) {
        this.loading = true;
        const response = await ShippingService.getShippingByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar Frete`);
                return;
            }

            this.shipping = response.data;
            this.loading = false;
        });
        return response;
    }

    async deleteShippingAll(uuid: string) {
        this.loading = true;
        const response = await ShippingService.deleteShipping(uuid);

        runInAction(() => {
            this.shipping = response.data;
            this.loading = false;
        });
        return response;
    }

    async deleteShipping(uuid: string) {
        this.loading = true;
        const response = await ShippingService.deleteShipping(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Frete`);
            } else {
                message.success('Frete deletado com sucesso!');
            }

            this.shipping = response.data;
            this.loading = false;
        });
        return response;
    }


    async uploadCreateShipping(shipping: Shipping) {
        this.loading = true;
        const response = await ShippingService.createShipping(shipping);

        runInAction(() => {
            this.loading = false;
        });
        return response;
    }
}

const shipppingStore = new ShipppingStore();
export default shipppingStore;