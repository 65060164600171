import { Button, Drawer, Form } from 'antd';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { PermissionBanners } from '../../../../permissions/PermissionBanners';
import { PermissionCategories } from '../../../../permissions/PermissionCategories';
import bannerStore from '../../../../stores/bannerStore';
import categoryStore from '../../../../stores/categoryStore';
import channelStore from '../../../../stores/channelStore';
import componentsStore from '../../../../stores/componentsStore';
import homeStore from '../../../../stores/homeStore';
import { convertToDayjs, formattedDates } from '../../../../utils/functions';
import { ComponentesSelect, ResponsiveString } from '../../../../utils/selects';
import DatePickerRangeComponet from '../../../atoms/DatePickerRangeComponet';
import InputComponent from '../../../atoms/InputComponet';
import InputNumberComponente from '../../../atoms/InputNumberComponet';
import SelectComponent from '../../../atoms/SelectComponent';
import Permission from '../../../molecules/Permission';
import { validationSchemaBanner, validationSchemaCategory } from './schema';

interface DrawerComponentProps {
    isModalDrawer?: boolean;
    setIsDrawerOpen: Dispatch<SetStateAction<boolean | undefined>>;
    uuid?: string;
}

const DrawerComponent: React.FC<DrawerComponentProps> = observer(({ isModalDrawer, setIsDrawerOpen, uuid }: any) => {
    const [typeSelected, setTypeSelected] = useState<any>();
    const [selectedComponent, setSelectedComponent] = useState<any>();
    const { componentProps, loading } = componentsStore;
    const { bannerListSelected } = bannerStore;
    const { selectCategory } = categoryStore;
    const { selectChannelList } = channelStore;

    const handleSubmit = async (values: any) => {
        const formattedDate = formattedDates(values?.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];
        let response;

        const createProps = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesComponentInputDTO",
            type: values?.type?.value,
            name: values?.name,
            order: values.order,
            mobile: values?.mobile?.value === 'true' ? true : false,
            app: false,
            banners: [{
                uuid: values?.banners?.value
            }],
            category: {
                uuid: values?.category?.value
            },
            channel: {
                uuid: selectChannelList[0].value,
            },
            minDate: `${dateInitial}T00:00:00`,
            maxDate: `${dateFinal}T23:59:59`,
            active: values.active,
            uuid: componentProps?.uuid,
        }

        if (values?.type?.value === "BANNER_CAROUSEL") {
            //@ts-ignore
            delete createProps.category
        }

        if (values?.type?.value === "PRODUCT_CAROUSEL") {
            //@ts-ignore
            delete createProps?.banners
        }

        if (uuid) {
            response = await componentsStore.editComponent(createProps, uuid);
        } else {
            response = await componentsStore.createComponet(createProps);
        }

        const addComponent = {
            order: response.order,
            mobile: response.mobile,
            componentUuid: response,
            component: response,
            screen: true,
        }

        if (!response.erro) {
            await homeStore.addComponent(addComponent);
            setIsDrawerOpen(false);
        }
    };

    const initialValues: any = {
        name: componentProps?.name || '',
        order: componentProps?.order || '',
        active: componentProps?.active || false,
        type: { value: componentProps?.type, label: '' },
        date: componentProps ? [convertToDayjs(componentProps?.initialDate), convertToDayjs(componentProps?.finalDate)] : '',
        banners: componentProps ? { value: componentProps?.banners && componentProps?.banners[0]?.uuid, label: componentProps?.banners && componentProps?.banners[0]?.nameTag } : '',
        category: componentProps ? { value: componentProps?.category?.uuid, label: componentProps?.category?.categoryName } : '',
        mobile: { value: componentProps?.mobile === false ? 'Web' : 'Responsive', label: '' },
    };

    return (
        <Formik
            validationSchema={typeSelected?.value === "BANNER_CAROUSEL" ? validationSchemaBanner : validationSchemaCategory}
            initialValues={initialValues} onSubmit={async (values, { resetForm }) => {
                await handleSubmit(values);
                resetForm()
            }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastro de Componente"
                    width={450}
                    onClose={() => {
                        resetForm(); // Reseta o formulário ao fechar
                        setIsDrawerOpen(false);
                    }}
                    open={isModalDrawer}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsDrawerOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}>
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex flex-col gap-2">
                            <InputComponent size="middle" label="Nome" name="name" id="name" />
                            <InputNumberComponente size="middle" label="Ordenação" name="order" id="order" />
                            <SelectComponent size="middle" label="Responsivo" name="mobile" options={ResponsiveString} />
                            <SelectComponent size="middle" onChange={(value, option) => setTypeSelected(option)} label="Tipo" name="type" options={ComponentesSelect} />
                            <DatePickerRangeComponet size="middle" label="Data Inicial e Final" name="date" />

                            {typeSelected && typeSelected?.value === "PRODUCT_CAROUSEL" &&
                                <Permission permissionKeys={[PermissionCategories.GET]} inputType name="Categoria">
                                    <SelectComponent size="middle" onChange={(value, option) => setSelectedComponent(option?.option)} label="Categoria" name="category" options={selectCategory} />
                                </Permission>
                            }

                            {typeSelected && typeSelected?.value === "BANNER_CAROUSEL" &&
                                <Permission permissionKeys={[PermissionBanners.GET]} inputType name="Banners">
                                    <SelectComponent size="middle" onChange={(value, option) => setSelectedComponent(option?.option)} label="Banners" name="banners" options={bannerListSelected} />
                                </Permission>
                            }
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
});

export default DrawerComponent;
