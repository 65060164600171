// src/components/organisms/LoginForm/index.tsx
import { Button } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import authStore from '../../../../stores/authStore';
import validationSchema from './validationSchema';
import InputPassword from '../../../atoms/InputPassword';

interface ChangePasswordProps {
    setStep: Dispatch<SetStateAction<number>>;
    email: string;
    token: string;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ setStep, email, token }) => {
    const initialValues = {
        newPassword: '',
        confirmNewPassword: '',
    };

    const handleSubmit = async (values: { newPassword: string }) => {
        const response = await authStore.changePassword(email, token, values.newPassword);

        if (!response.error) {
            setStep(0);
        }
    };

    return (

        <div className="flex flex-col items-center justify-center rounded-xl p-8 bg-white min-w-96" >
            <div className="flex flex-col items-center justify-center pb-6">
                <p className="font-semibold text-lg">Recuperação de Senha</p>
                <p className="text-slate-400 text-sm">Redefinir senha com stoom.</p>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <div className="w-full">
                        <Form className="flex flex-col justify-start gap-4 w-full">
                            <InputPassword label="Nova Senha" name="newPassword" id="newPassword" type='password' />
                            <InputPassword label="Confirmar Senha" name="confirmNewPassword" id="confirmNewPassword" type='password' />

                            <div className="w-full max-w-80 text-sm">
                                <p>A senha deve conter:</p>
                                <p>- Letras maiúsculas</p>
                                <p>- Letras minúsculas</p>
                                <p>- Números</p>
                                <p>- Caracteres especiais (Ex: @ # *)</p>
                            </div>

                            <div className="pt-2 w-full">
                                <Button className="w-full mb-2" htmlType="submit" type="primary">Alterar Senha</Button>
                                <Button className="w-full" htmlType="submit" type="default" onClick={() => setStep(1)}>Voltar</Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default ChangePassword;