import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionVoltage } from '../../../permissions/PermissionVoltage';
import voltagemStore from '../../../stores/voltagemStore';
import { Voltage } from '../../../types/Voltage';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/voltagem">Listagem de Voltagens</a>,
    },
    {
        title: <p>Cadastro de Voltagem</p>,
    },
]

const CreateVoltagem: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { voltagem, loading } = voltagemStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionVoltage.GET_UUID)) {
                voltagemStore.getVoltagemByUuid(uuid);
            }
        };

        getInformation();

        return () => {
            voltagemStore.reset();
        };
    }, []);


    const initialValues: Voltage = {
        code: voltagem?.code || '',
        description: voltagem?.description || '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Voltage) => {
        let response;

        const updatedProps = {
            ...voltagem,
            ...values,
        };

        if (uuid) {
            response = await voltagemStore.editVoltagem(updatedProps, uuid);
        } else {
            response = await voltagemStore.createVoltagem(updatedProps);
        }

        if (!response.error) listRouter();
    };


    const listRouter = () => {
        navigate(`/voltagem`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Nova Voltagem" description='Tela para cadastro de Voltagem'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionVoltage.POST, PermissionVoltage.PUT, PermissionVoltage.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Nova Voltagem" description='Tela para cadastro de Voltagem'>
                                <div className="flex w-full gap-4 pb-4">
                                    <InputComponet label="Código" name="code" id="code" disabled={uuid ? true : false} />
                                    <InputComponet label="Descrição" name="description" id="description" />
                                </div>
                            </ContentBox>
                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionVoltage.PUT, PermissionVoltage.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateVoltagem;