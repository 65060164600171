import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, Form, message, Space, Table } from 'antd';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionProperties } from '../../../permissions/PermissionProperties';
import propertiesStore from '../../../stores/propertiesStore';
import { Properties } from '../../../types/Properties';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponent from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    className: any;
    ordering: any;
    uuid: any;
    name: string;
    key: React.Key;
}

interface QueryParams {
    size?: number;
    page?: number;
    sort?: string;
    name?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Especificação</p>,
    },
]

const ListProperties: React.FC = observer(() => {
    const { propertieList, loading, page, totalElements, defaultparams, sort, size } = propertiesStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const navigate = useNavigate();
    const [queryParams, setQueryParams] = useQueryParams();
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const { hasPermission, isColumnVisible } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        className: 'className',
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionProperties.GET)) {
                await propertiesStore.getList(params);
            }
        };

        fetchData();
    }, []);


    // Interação da Tabela como sorte
    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await propertiesStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort, // Use o sort da tabela se disponível
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
        });

        return params;
    };


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'className',
            key: 'className',
            sorter: true,
            filters: getUniqueFilters(propertieList, 'className'),
            onFilter: (value, record) => record.className.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('className', queryParams.sort),
        },
        {
            title: 'Ordenação',
            dataIndex: 'ordering',
            key: 'ordering',
            sorter: true,
            filters: getUniqueFilters(propertieList, 'ordering'),
            defaultSortOrder: getSortOrder('ordering', queryParams.sort),
            onFilter: (value, record) => record.ordering === value,
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => <p>{formatDateString(text)}</p>,
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionProperties.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/especificacao/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionProperties.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const listRouter = () => {
        navigate(`/especificacao/cadastro`);
    }

    const handleOk = async () => {
        const response = await propertiesStore.deletePropertie(itemSelected.uuid);

        if (!response?.error) {
            propertiesStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => propertiesStore.deletePropertieAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    setSelectionCheckbox([]);
                    message.error('Erro ao deletar uma ou mais Especificações.');
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todas as Especificações foram deletados com sucesso!');
                    await propertiesStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar as Especificações.');
        }
    };

    const handleExport = async () => {
        const params = buildParamsFromQuery(queryParams, mappings);
        const exportParams = {
            ...params,
            size: 999999,
        }

        const dataExport = await propertiesStore.getList(exportParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: Properties) => ({
                name: item.className,
                ordering: item.ordering,
            }));

            exportToExcel(listExport, ["Nome", "Ordenação"], "Tabela de Especificação.xlsx");
        }
    };

    const initialValues = {
        name: queryParams.name || '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: { className: string; username: string; email: string; active: { value: string; label: string; } }) => {
        const newParams: any = {
            className: values.className || undefined,
        };

        const search = [
            values.className ? `;className;"${values.className}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort,
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await propertiesStore.getList(params);
    };

    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL
        await propertiesStore.getList(defaultparams);
    };


    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionProperties.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionProperties.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Especificação" description='Tela de listagem de Especificação'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm, values }) => (  // Passa o resetForm do render prop de Formik
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <InputComponent label="Nome" name="className" id="className" />
                                    </div>

                                    <div className="flex items-end gap-4 justify-end ">
                                        <Button onClick={() => handleClearAndSearch(resetForm)}>
                                            Limpar
                                        </Button>
                                        <Button onClick={() => handleSubmit(values)} htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" title='Especificação' description='Tela de listagem de Especificação' actions={
                    <>
                        <Permission permissionKeys={[PermissionProperties.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={propertieList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Propriedade <b>{itemSelected?.code}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListProperties;