import { Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { Field } from 'formik';
import React from 'react';
import runes from 'runes2';
import './input.scss';

//@ts-ignore
interface TextAreaComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
    showMaxLength?: boolean;
    name: string; // Tornando o name obrigatório
    rows?: number;
    size?: string;
}

const TextAreaComponent: React.FC<TextAreaComponentProps> = ({ label, rows, size, showMaxLength, maxLength, name, disabled, placeholder, ...props }) => (
    <div className="flex flex-col w-full input-container">
        {label && <Typography.Title level={5}>{label}</Typography.Title>}
        <Field name={name}>
            {({ field, meta }: any) => {
                return (
                    <>
                        <TextArea
                            className="rounded"
                            rows={rows}
                            {...field}
                            maxLength={maxLength}
                            size={size ? size : "large"}
                            disabled={disabled}
                            placeholder={placeholder}
                            count={showMaxLength ? {
                                show: true,
                                strategy: (txt) => runes(txt).length,
                            } : null}
                            status={meta.touched && meta.error ? 'error' : ''}
                        />
                        {meta.touched && meta.error && (
                            <Typography.Text type="danger">{meta.error}</Typography.Text>
                        )}
                    </>
                );
            }}
        </Field>
    </div>
);

export default TextAreaComponent;
