import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import ProductService from '../services/ProductService';
import { PaginationParams } from '../types';
import { transformToValueLabel } from '../utils/functions';


class ProductStore {
    loading: boolean = false;
    productList: any = [];
    productListByCategory: any = [];
    product: any = {
        unitOfMeasurementList: [],
        prices: [],
        productProperties: [],
        files: [],
        categories: [],
    };
    stockList: any = [];
    productMassListError: any = [];
    arrayImageUpload: any = [];
    arrayImageUrl: any = [];
    listPrice: any = []
    removeImageArray: any = [];
    unitSelected: any = [];
    categoriesSelected: any = [];
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.product = {
            unitOfMeasurementList: [],
            prices: [],
            productProperties: [],
            files: [],
            categories: [],
            active: false,
        };
        this.categoriesSelected = [];
        this.stockList = [];
    }

    async getList(params: PaginationParams, type: string) {
        this.loading = true;
        const response = await ProductService.getList(params, type);

        runInAction(() => {
            if (!response.error) {
                this.productList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            }
            this.loading = false;
        });

        return response;
    }


    async getProductByCategory(slug: string, params: PaginationParams) {
        this.loading = true;
        const response = await ProductService.getProductByCategory(slug, params);

        runInAction(() => {
            if (!response.error) {
                this.productListByCategory = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            } else {
                this.productListByCategory = [];
                this.totalPages = 1;
                this.page = 0;
                this.totalElements = 0;
                this.size = 20;
            }
            this.loading = false;
        });

        return response;
    }


    async getProductByUuid(uuid: string) {
        this.loading = true;
        const response = await ProductService.getProductByUuid(uuid);

        if (response.error) {
            message.error('Erro ao buscar Produto');
            return;
        }

        await this.getStockList(response.data.skuCode);

        runInAction(() => {
            this.product = response.data;
            this.product.prices = response.data.prices;
            this.listPrice = response.data.prices.filter((item: any) => item.show === true);
            this.unitSelected = transformToValueLabel(response.data.unitOfMeasurementList, 'uuid', 'name');
            this.loading = false;
        });
        return response.data;
    }

    async createProduct(data: any) {
        this.loading = true;

        data.categories = [];
        const response = await ProductService.createProduct(data);

        if (response.error) {
            message.error(`Erro ao criar Produto`);
        } else {
            message.success('Produto cadastrado com sucesso!');
            await this.editCategories(response.data.uuid);
            await this.saveImageUrl(response.data.uuid);
            await this.saveImageUpload(response.data.uuid);
        }

        this.loading = false;
        return response;
    }


    async editProduct(data: any, uuid: string | undefined) {
        this.loading = true;
        const response = await ProductService.editProduct(data, uuid);

        if (response.error) {
            message.error(`Erro ao editar Produto`);
        } else {
            message.success('Produto editado com sucesso!');
            await this.editCategories(response.data.uuid);
            await this.saveImageUrl(response.data.uuid);
            await this.saveImageUpload(response.data.uuid);
            await this.deleteImage();
            this.arrayImageUpload = [];
            this.arrayImageUrl = [];
        }

        this.loading = false;
        return response;
    }


    async extractUUIDs(categories: any[]) {
        return categories.map((category) => category.uuid);
    };


    async searchProduct(searchTerm: string) {
        this.loading = true;
        const response = await ProductService.searchProduct(searchTerm);

        if (!response.data.error) {
            const selectCustomerList = transformToValueLabel(response.data.content, 'uuid', 'name');

            if (response.data.content.length === 0) {
                message.error('Nenhum produto foi encontrado para a busca realizada.');
            }
            this.loading = false;
            return selectCustomerList;
        }
        this.loading = false;
    }

    async deleteProduct(uuid: string) {
        this.loading = true;
        const response = await ProductService.deleteProduct(uuid);

        if (response.error) {
            message.error(`Erro ao deletar Produto`);
        } else {
            message.success('Produto deletado com sucesso!');
        }

        this.product = response.data;
        this.loading = false;
        return response;
    }

    async deleteProductAll(uuid: string) {
        this.loading = true;
        const response = await ProductService.deleteProduct(uuid);
        this.product = response.data;
        this.loading = false;
        return response;
    }

    async searchProductBySkuCode(searchTerm: string) {
        const response = await ProductService.searchProductAllBySku(searchTerm);

        if (!response.error) {
            return response.data
        }
    }

    // Regra de Estoque
    async getStockList(skuCode: any) {
        this.loading = true;
        const data = {
            skuProduct: skuCode
        }

        const response = await ProductService.getStock(data);

        if (response.error) {
            this.loading = false;
            // message.error('Erro ao buscar Estoque');
            return;
        }

        this.stockList = response.data;
        this.loading = false;
        return response;
    }

    // Regras de Preço
    async addPrice(price: any) {
        // Verificação do BaseQtyUnidade
        if (parseInt(price.initialAmount) < parseInt(price?.unit?.valStart)) {
            message.error('Quantidade inicial não pode ser menor que a quantidade Base');
            return 'Error'
        }

        // Verificação de Quantidade Maxima
        const valEnd = price?.unit?.valEnd === 0 ? 999999 : price?.unit?.valEnd
        if (parseInt(price.initialAmount) > parseInt(valEnd)) {
            message.error('Não é possível cadastrar uma quantidade superior ao valor da unidade base');
            return 'Error'
        }

        // Verificação de Centro e Tabela
        if (this.product.prices.find((item: any) => (
            item?.center === price?.center &&
            item?.tableId === price?.tableId &&
            item?.nameMeasurement === price?.nameMeasurement &&
            item.deleted === false
        )
        )) {
            message.error('Não é possível cadastrar Centro e Tabela repetidos');
            return 'Error'
        }

        const priceUpdate = {
            ...price,
            finalAmount: price.unit.valEnd,
            price: Number(price.price), // Converte para número
            minimumPrice: Number(price.minimumPrice) // Converte para número
        };

        if (this.product.prices.find((item: any) => (
            item?.center === price?.center &&
            item?.tableId === price?.tableId &&
            item.deleted === false
        )
        )) {
            // Atualiza todas as Unidades do produto
            this.product.prices.push(priceUpdate);
            this.listPrice.push(priceUpdate);

            const filtereSamePriceCenterAndTable = this.product.prices.filter((item: any) => {
                return (
                    item.center === price.center && item.tableId === price.tableId
                );
            });

            const sortPriceList = filtereSamePriceCenterAndTable.sort((a: any, b: any) => {
                const initialAmountA = parseInt(a.initialAmount);
                const initialAmountB = parseInt(b.initialAmount);

                if (initialAmountA < initialAmountB) {
                    return -1;
                }
                if (initialAmountA > initialAmountB) {
                    return 1;
                }
                return 0;
            });


            function updateFinalAmount(prices: any) {
                for (let i = 0; i < prices.length - 1; i++) {
                    const next = prices[i + 1];

                    const nextFinalAmount = parseInt(next.initialAmount) - 1;
                    prices[i].finalAmount = nextFinalAmount;
                }

                return prices;
            }

            const resultado = this.product.prices.filter((itemCompleto: any) =>
                !sortPriceList.some((itemFiltrado: any) => itemFiltrado.uuid === itemCompleto.uuid)
            );

            const newArray = [...sortPriceList, ...resultado];
            const listPrice = updateFinalAmount(newArray);
            this.product.prices = listPrice;
            this.listPrice = listPrice;
            return;
        }

        this.product.prices.push(priceUpdate);
        this.listPrice.push(priceUpdate);
        return 'Sucess'
    }


    async removePrice(uuid: string) {
        const findProductChange = this.product.prices.find((item: any) => item.uuid === uuid);

        if (!findProductChange) return; // Se não encontrar o item, encerra a função.

        if (findProductChange.newItem) {
            // Remove o item das listas
            this.product.prices = this.product.prices.filter((item: any) => item.uuid !== uuid);
            this.listPrice = this.product.prices.filter((item: any) => item.uuid !== uuid);
        } else {
            // Marca o item como deletado
            findProductChange.deleted = true;
        }
        // Atualiza a lista visível (`listPrice`) com base na nova situação
        this.listPrice = [...this.product.prices];
    }

    async updateFakePrice(values: any, clear: boolean) {
        if (clear === false) {
            const filterPrice = this.product.prices.filter((item: any) => {
                return (
                    (values.tableId ? item.tableId === values.tableId : true) &&
                    (values.center ? item.center === values.center : true) &&
                    (values.unit ? item.nameMeasurement === values?.unit?.label : true) &&
                    (values.fakePrice?.value !== 'Todos' ? item.show === values.fakePrice.value : true)
                );
            });
            this.listPrice = filterPrice;
        } else {
            this.listPrice = this.product.prices.filter((item: any) => item.show === true);
        }
    }

    // Regras de Especificação
    async addSpecification(data: any) {
        this.product.productProperties.push(data);
    }

    async removeSpecification(uuid: string) {
        this.product.productProperties = this.product.productProperties.filter((item: any) => item.uuid !== uuid);
    }

    // Regras de Unidade
    async addUnit(data: any) {
        const verifySameName = this.product.unitOfMeasurementList.find((item: any) => item.name === data.name);

        if (!verifySameName) {
            this.product.unitOfMeasurementList.push(data);
            this.unitSelected = transformToValueLabel(this.product.unitOfMeasurementList, 'uuid', 'name');
            return 'Sucess'
        } else {
            message.error('Já existe uma unidade com mesmo nome');
            return 'Error'
        }
    }

    async removeUnit(uuid: any) {
        this.product.unitOfMeasurementList = this.product?.unitOfMeasurementList.filter((item: any) => {
            if (item.uuid === uuid) {
                if (item.new === true) {
                    return false;
                } else {
                    item.deleted = true;
                }
            }
            return true;
        });
    }


    // Regras de Categoria
    async addCategories(category: any) {
        this.categoriesSelected = category.checked;
    }

    async editCategories(productUuid: string) {
        const originalUUIDs = await this.extractUUIDs(this.product?.categories);

        const newCategories = await this.categoriesSelected.filter((key: any) => !originalUUIDs.includes(key as string));
        const newCategoriesList = [...new Set(newCategories)];

        if (newCategoriesList?.length > 0) {
            newCategoriesList.forEach(async (uuid: any) => {
                await ProductService.addCategoryProduct(uuid, [{ uuid: productUuid }]);
            });
        }

        const removeCategories = await originalUUIDs.filter((uuid) => !this.categoriesSelected?.includes(uuid));
        const removeCategoriesList = [...new Set(removeCategories)];

        if (removeCategoriesList?.length > 0) {
            removeCategoriesList?.forEach(async (uuid: any) => {
                await ProductService.removeCategoryProduct(uuid, [{ productUuid: productUuid }]);
            });
        }
    }

    //Desvincular Produto
    async unlinkProduct(product: any) {
        product.baseProduct = null;
        const response = await ProductService.editProduct(product, product.uuid);

        if (response.error) {
            message.error(`Erro ao desvincular Produto`);
        } else {
            message.success('Produto desvinculado com sucesso!');
            this.getProductByUuid(product.uuid);
        }
    }

    // Regras Imagem
    async addImageUrl(file: any) {
        this.product.files.push(file);
        this.arrayImageUrl.push(file);
    }

    async addImageUpload(file: any) {
        this.product.files.push(file);
        this.arrayImageUpload.push(file);
    }

    async removeImage(file: any) {
        this.removeImageArray.push(file);
        const removeItem = this.product.files.filter((item: any) => item.uuid !== file.uuid);
        this.product.files = removeItem;
    }

    async saveImageUrl(productUuid: string) {
        if (this.arrayImageUrl.length > 0) {
            for (const newImage of this.arrayImageUrl) {

                // A variável newImage deve ser um objeto File
                const file = newImage.file; // Ajuste conforme a estrutura do seu objeto
                const metadata = {
                    metaTags: newImage.metaTags,
                    ordering: newImage.ordering,
                    path: newImage.path,
                };

                const formData = new FormData();
                formData.append('file', file); // O arquivo deve ser um objeto File
                formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));

                await ProductService.addImageURL(productUuid, formData);
            }
        }
    }


    async saveImageUpload(productUuid: string) {
        if (this.arrayImageUpload.length > 0) {
            for (const newImage of this.arrayImageUpload) {

                // A variável newImage deve ser um objeto File
                const file = newImage.file; // Ajuste conforme a estrutura do seu objeto
                const metadata = {
                    metaTags: newImage.metaTags,
                    ordering: newImage.ordering,
                    path: newImage.path,
                };

                const formData = new FormData();
                formData.append('file', file); // O arquivo deve ser um objeto File
                formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));

                await ProductService.addImageUpload(productUuid, formData);
            }
        }
    }


    async deleteImage() {
        const filterOldImage = this.removeImageArray.filter((item: any) => !item.new);

        if (filterOldImage.length > 0) {
            for (const image of filterOldImage) {
                await ProductService.deleteImage(image.uuid);
            }
        }
    }


    async massProductUpload(file: any) {
        this.productMassListError = [];
        this.loading = true;
        const formData = new FormData();
        formData.append('file', file);

        const result = await ProductService.massProductUpload(formData);

        if (result.error) {
            message.error(`Erro ao importar Produtos`);
            this.productMassListError = result.details.response.data.errors;
        } else {
            message.success('Produtos importados com sucesso!');
        }

        this.loading = false;
        return result;
    }

    async massProductUpdateUpload(file: any) {
        this.productMassListError = [];
        this.loading = true;
        const formData = new FormData();
        formData.append('file', file);

        const result = await ProductService.massProductUpdateUpload(formData);

        if (result.error) {
            message.error(`Erro ao importar Produtos`);
            this.productMassListError = result.details.response.data.errors;
        } else {
            message.success('Produtos importados com sucesso!');
        }

        this.loading = false;
        return result;
    }
}

const productStore = new ProductStore();
export default productStore;