import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import categoryStore from '../../../../stores/categoryStore';
import productStore from '../../../../stores/productStore';
import ContentBox from '../../../molecules/ContentBox';
import LoadingComponent from '../../../molecules/LoadingComponent';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import Permission from '../../../molecules/Permission';
import { PermissionCategories } from '../../../../permissions/PermissionCategories';
import PermissionComponent from '../../../atoms/PermissionComponent';

const SelectedCategories: React.FC = observer(() => {
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>(); // Gerenciar chaves marcadas
    const { categoryListSelect, loading } = categoryStore;
    const { product } = productStore;

    useEffect(() => {
        if (product && product.categories) {
            const uuids = product.categories.map((category: any) => category.uuid);
            setCheckedKeys(uuids);
        }
    }, [product]);

    // Função chamada ao marcar/desmarcar nós
    const onCheck = (checkedKeysValue: any) => {
        setCheckedKeys(checkedKeysValue as React.Key[]); // Atualizar o estado com as chaves selecionadas
        productStore.addCategories(checkedKeysValue);
    };

    return (
        <>
            <Permission permissionKeys={[PermissionCategories.GET]} fallback={<PermissionComponent />}>
                {loading &&
                    <ContentBox className="mb-4 p-6" title="Selecione uma Categoria" description='Escolha a categoria que este produto está vinculado.'>
                        <LoadingComponent />
                    </ContentBox>
                }

                {!loading &&
                    <ContentBox className="mb-4 p-6" title="Selecione uma Categoria" description='Escolha a categoria que este produto está vinculado.'>
                        <Tree
                            checkable
                            checkStrictly
                            onCheck={onCheck}
                            checkedKeys={checkedKeys}
                            treeData={categoryListSelect}
                        />
                    </ContentBox>
                }
            </Permission>
        </>
    );
});

export default SelectedCategories;