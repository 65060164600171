import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import campaingStore from '../../../../../../stores/campaingStore';
import categoryStore from '../../../../../../stores/categoryStore';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';


const CategorySelectedPromotion: React.FC = observer(() => {
    const { categoryListSelect } = categoryStore;
    const { campaignProps } = campaingStore;
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>();

    // Função chamada ao marcar/desmarcar nós
    const onCheck = (checkedKeysValue: any) => {
        setCheckedKeys(checkedKeysValue as React.Key[]);
        campaingStore.updateCategory(checkedKeysValue);
    };

    useEffect(() => {
        if (campaignProps) {
            const categoryRestriction = campaignProps?.restrictions.find((restriction: any) =>
                restriction["@class"] === "br.com.stoom.kernel.model.persistence.restriction.ProductCategoryRestriction"
            );

            const uuids = categoryRestriction?.categories?.map((category: any) => category.uuid);
            setCheckedKeys(uuids);
        }
    }, [campaignProps]);

    return (
        <div className="pt-3">
            <Tree
                checkable
                checkStrictly
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                treeData={categoryListSelect}
            />
        </div>
    );
});

export default CategorySelectedPromotion;
