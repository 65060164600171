import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionShipping } from '../../../permissions/PermissionShipping';
import shipppingStore from '../../../stores/shipppingStore';
import { Shipping } from '../../../types/Shipping';
import { extractTime } from '../../../utils/dateFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import InputNumberComponente from '../../atoms/InputNumberComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import TimePickerComponent from '../../atoms/TimePickerComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/fretes">Listagem de Fretes</a>,
    },
    {
        title: <p>Cadastro de Fretes</p>,
    },
]

const CreateShipping: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { loading, shipping } = shipppingStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionShipping.GET_UUID)) {
                shipppingStore.getShippingByUuid(uuid);
            }
        };

        getInformation();

        return () => {
            shipppingStore.reset();
        };
    }, []);

    const initialValues: Shipping = {
        shippingCompany: shipping?.shippingCompany || '',
        orgVendas: shipping?.orgVendas || '',
        regVendas: shipping?.regVendas || '',
        corte: shipping?.corte ? dayjs(shipping.corte).format('HH:mm:ss') : '',
        sla: shipping?.sla || ''
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Shipping) => {
        let response;

        const createProps = {
            ...shipping,
            ...values,
            corte: extractTime(values.corte),
        };

        const updatedProps = {
            ...shipping,
            ...values,
            corte: extractTime(values.corte),
        };

        if (uuid) {
            response = await shipppingStore.editShipping(updatedProps, uuid);
        } else {
            response = await shipppingStore.createShipping(createProps);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/fretes`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionShipping.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Frete" description='Tela para cadastro de Frete'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionShipping.POST, PermissionShipping.PUT, PermissionShipping.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <>
                            <Form>
                                <ContentBox className="mb-4 p-6" title="Cadastro de Frete" description='Tela para cadastro de Frete'>
                                    <div className="flex w-full gap-4 pb-4">
                                        <InputComponet label="Empresa" name="shippingCompany" id="shippingCompany" />
                                        <InputComponet label="ORG Vendas" name="orgVendas" id="orgVendas" />
                                        <InputComponet label="Região Vendas" name="regVendas" id="regVendas" />
                                    </div>

                                    <div className="grid grid-cols-12 gap-4">
                                        <div className="col-span-4">
                                            <TimePickerComponent label="Hora do corte" name="corte" id="corte" />
                                        </div>
                                        <div className="col-span-4">
                                            <InputNumberComponente label="SLA Região" name="sla" id="sla" />
                                        </div>
                                    </div>
                                </ContentBox>

                                <div className="flex justify-end">
                                    <Permission permissionKeys={[PermissionShipping.PUT, PermissionShipping.POST]} buttonType>
                                        <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                    </Permission>
                                </div>
                            </Form>
                        </>
                    </Formik >
                </Permission>
            }
        </>
    );
});

export default CreateShipping;