import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    username: Yup.string().required('Usuario é obrigatório'),
    password: Yup.string()
        .min(8, "A senha deve ter no mínimo 8 caracteres")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&\-\[\]{}()*+?.,\\^$|#\s]+$/,
            "A senha deve conter letras maiúsculas, letras minúsculas, números e caracteres especiais"
        )
        .required("Senha é obrigatória"),
    email: Yup.string().email('E-mail inválido').required('Email obrigatório!'),
    confirmEmail: Yup.string()
        .email('E-mail inválido')
        .required('Confirmação de E-mail é obrigatório')
        .oneOf([Yup.ref('email')], 'Os E-mails não são iguais'),
});


export const editSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
});