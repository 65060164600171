import type { TabsProps } from 'antd';
import { Button, message, Tabs } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionCategories } from '../../../permissions/PermissionCategories';
import categoryStore from '../../../stores/categoryStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import CategoryInformation from './CategoryInformation';
import CategorySelected from './CategorySelected';
import ImageCategory from './ImageCategory';
import { validationSchema } from './schema';
import authStore from '../../../stores/authStore';
import { Merchant } from '../../../types/Merchant';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/categorais">Listagem de Categorias</a>,
    },
    {
        title: <p>Cadastro de Categorias</p>,
    },
]

interface FormValues {
    "@class": string;
    active: { value: string; label: string }
    deleted: boolean;
    categoryName: string;
    type: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    metaCanonical: string;
    newType: string;
    menuDisplay: { value: string; label: string }
    homeDisplay: { value: string; label: string }
    externalId: string;
    ordering: number;
    merchant: Merchant | undefined;
    files: any[];
    name?: string;
    shortDescription: string;
    imageCategory: any;
    imageCategoryResponsive: any;
    imageIconCategory: any;
}

const CreateCategory: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { loading, categoryProps } = categoryStore;
    const navigate = useNavigate();
    const { merchant } = authStore;
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { hasPermission } = useAuth();

    useEffect(() => {
        const getInformation = async () => {
            if (uuid) {
                await categoryStore.getCategoryByUuid(uuid);
            }
            await categoryStore.getList();
        };

        if (uuid && hasPermission(PermissionCategories.GET_UUID)) {
            getInformation();
        }

        return () => {
            categoryStore.reset();
        };
    }, [uuid]);

    const findFileByMetaTag = (filesArray: any[], metaTag: string) => {
        return filesArray && filesArray?.find(file => file.metaTags.includes(metaTag));
    };

    //@ts-ignore
    const initialValues: FormValues = {
        "@class": "br.com.stoom.kernel.model.persistence.Category",
        active: categoryProps?.active,
        deleted: false,
        categoryName: categoryProps?.categoryName || '',
        ordering: categoryProps?.ordering || '',
        type: categoryProps?.type || 'VITRINE',
        metaTitle: categoryProps?.metaTitle || '',
        externalId: categoryProps?.externalId || '',
        metaKeywords: categoryProps?.metaKeywords || '',
        metaDescription: categoryProps?.metaDescription || '',
        metaCanonical: categoryProps?.metaCanonical || '',
        newType: categoryProps?.newType || '',
        menuDisplay: { value: categoryProps?.menuDisplay === true ? 'true' : 'false', label: '' },
        homeDisplay: { value: categoryProps?.homeDisplay === true ? 'true' : 'false', label: '' },
        shortDescription: categoryProps?.shortDescription || '',
        merchant: merchant,
        files: [],
        imageCategory: findFileByMetaTag(categoryProps?.files, 'category') ? [{
            name: 'category-image',
            preview: findFileByMetaTag(categoryProps?.files, 'category')?.path,
            thumbUrl: findFileByMetaTag(categoryProps?.files, 'category')?.path,
            uuid: findFileByMetaTag(categoryProps?.files, 'category')?.uuid,
        }] : [],
        imageCategoryResponsive: findFileByMetaTag(categoryProps?.files, 'category-mobile') ? [{
            name: 'category-mobile-image',
            preview: findFileByMetaTag(categoryProps?.files, 'category-mobile')?.path,
            thumbUrl: findFileByMetaTag(categoryProps?.files, 'category-mobile')?.path,
            uuid: findFileByMetaTag(categoryProps?.files, 'category-mobile')?.uuid,
        }] : [],
        imageIconCategory: findFileByMetaTag(categoryProps?.files, 'icon-category') ? [{
            name: 'icon-category-image',
            preview: findFileByMetaTag(categoryProps?.files, 'icon-category')?.path,
            thumbUrl: findFileByMetaTag(categoryProps?.files, 'icon-category')?.path,
            uuid: findFileByMetaTag(categoryProps?.files, 'icon-category')?.uuid,
        }] : [],
    };

    const handleSubmit = async (values: FormValues) => {
        let response;

        const updatedCategory = {
            ...categoryProps,
            ...values,
            menuDisplay: values.menuDisplay.value,
            homeDisplay: values.homeDisplay.value,
            active: values.active || false,
        };

        delete updatedCategory["@class"];

        if (uuid) {
            response = await categoryStore.editCategory(updatedCategory, uuid);
        } else {
            response = await categoryStore.createCategory(updatedCategory);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/categorias`);
    }

    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <CategoryInformation />,
        },
        {
            key: '2',
            label: 'Imagem Categoria',
            children: <ImageCategory />,
        },
        {
            key: '3',
            label: 'Seleção de Categoria',
            children: <CategorySelected />,
        },
        {
            key: '4',
            label: 'Produto Categoria',
            children: <CategorySelected />,
        },
    ];

    const deleteCategory = async () => {
        setIsModalOpen(true);
    }

    const handleOk = async () => {
        const response = await categoryStore.deleteCategory(categoryProps.uuid);
        if (!response.error) {
            listRouter()
        }
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionCategories.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Categorias" description='Tela para cadastro de Categorias'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionCategories.POST, PermissionCategories.PUT, PermissionCategories.GET_UUID]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ validateForm }) => (
                            <Form>
                                <Tabs activeKey={activeTabKey} className="mb-0" type="card" defaultActiveKey="1" items={items} onChange={onChange} />

                                <div className={`flex ${uuid ? 'justify-between' : ''}`}>
                                    {uuid &&
                                        <Permission permissionKeys={[PermissionCategories.DELETE]} buttonType>
                                            <ActionButton onClick={() => deleteCategory()} actionType="delete">Deletar Categoria</ActionButton>
                                        </Permission>
                                    }

                                    <Permission permissionKeys={[PermissionCategories.PUT, PermissionCategories.POST, PermissionCategories.POST_IMAGE]} buttonType>
                                        <ActionButton onClick={async () => {
                                            // Valida o formulário e atualiza o objeto errors
                                            const formErrors = await validateForm();

                                            if (Object.keys(formErrors).length > 0) {
                                                setActiveTabKey('1');
                                                message.error(`Há campos que não foram preenchidos`);
                                            }
                                        }} htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                    </Permission>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Permission>
            }

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Categoria <b>{categoryProps?.categoryName}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                handleCancel={() => setIsModalOpen(false)}
            />
        </>
    );
});

export default CreateCategory;