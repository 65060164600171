import { Button, Drawer } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction, useState } from 'react';
import productStore from '../../../../../stores/productStore';
import propertiesStore from '../../../../../stores/propertiesStore';
import { transformToValueLabel } from '../../../../../utils/functions';
import SelectComponent from '../../../../atoms/SelectComponent';
import { validationSchema } from './schema';



export const MultipleRule = [
    { value: 'HE', label: 'Ativo' },
    { value: 'IC', label: 'Inativo' }
];


interface DrawerSpecificationProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const DrawerSpecification: React.FC<DrawerSpecificationProps> = ({ isModalOpen, setIsModalOpen }) => {
    const [selectedPropertie, setSelectedPropertie] = useState<any>([]);
    const { propertieListSelect } = propertiesStore;

    const handleSubmit = async (values: any) => {
        const createProps = {
            displayString: values?.selectedOption?.label,
            ordering: null,
            propertyClass: {
                className: values.propertie.label,
                merchant: values.propertie.option.merchant,
                uuid: values.propertie.value,
            },
            propertyValue: values?.selectedOption?.label,
            uuid: values?.selectedOption?.value
        }

        productStore.addSpecification(createProps);
        setIsModalOpen(false);
    };

    const initialValues = {
        propertie: { value: '', label: '' },
        selectedOption: { value: '', label: '' },
    };

    const handlePropertie = (propertie: any) => {
        setSelectedPropertie([]);

        const newArrayValues = transformToValueLabel(propertie?.option.values, 'uuid', 'propertyValue');
        setSelectedPropertie(newArrayValues);
    }

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={async (values, { resetForm }) => {
                await handleSubmit(values);
                resetForm()
            }}
            enableReinitialize
        >
            {({ submitForm, setFieldValue, resetForm }) => {
                return (
                    <Drawer
                        title="Cadastrar Especificação"
                        width={540}
                        onClose={() => {
                            resetForm(); // Reseta o formulário ao fechar
                            setIsModalOpen(false);
                        }}
                        open={isModalOpen}
                        footer={
                            <div className="flex gap-4 justify-end">
                                <Button onClick={() => setIsModalOpen(false)}>Cancelar</Button>
                                <Button type="primary" onClick={submitForm}>
                                    Cadastrar
                                </Button>
                            </div>
                        }
                    >
                        <Form>
                            <div className="flex gap-4 flex-col">
                                <SelectComponent
                                    onChange={(value, option) => {
                                        handlePropertie(option);
                                        setFieldValue('selectedOption', {});
                                    }}
                                    size="middle"
                                    label="Escolha a especificação*"
                                    name="propertie"
                                    options={propertieListSelect}
                                />
                                <SelectComponent
                                    size="middle"
                                    label="Selecione uma opção"
                                    name="selectedOption"
                                    options={selectedPropertie}
                                />
                            </div>
                        </Form>
                    </Drawer>
                );
            }}
        </Formik>
    );
};

export default DrawerSpecification;
