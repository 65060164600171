import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import ReportCardService from '../services/ReportCardService';


class ReportStore {
    loading: boolean = false;
    reportList: any = [];
    reportTotal: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: any) {
        this.loading = true;
        const pagination = params.page
        const size = params.size
        delete params.page;
        delete params.size;

        const response = await ReportCardService.getList(params, pagination, size);

        if (!response.error) {
            this.reportList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        }
        this.loading = false;

        return response;
    }

    async getReportTotal(params: any) {
        this.loading = true;
        delete params.page;
        delete params.size;

        const response = await ReportCardService.getReportTotal(params);

        if (!response.error) {
            this.reportTotal = response.data;
        }
        this.loading = false;
        return response;
    }
}

const reportStore = new ReportStore();
export default reportStore;