import { observer } from 'mobx-react-lite';
import React from 'react';
import BannerFull from './BannerFull';
import BranchComponent from './BranchComponent';
import SlideShowPrincipal from './SlideShowPrincipal';
import ImagemHomeComponent from './ImagemHomeComponent';
import CategoryCarrosel from './CategoryCarrosel';
import BlogComponent from './BlogComponent';
import './styles.scss';
import ProductCarousel from './ProductCarousel';


interface PreviewComponentsProps {
    banners?: any;
    tag?: string;
    bannerPage?: boolean;
    isMobile?: boolean;
}

const PreviewComponents: React.FC<PreviewComponentsProps> = observer(({ banners, tag, bannerPage, isMobile }) => {

    return (
        <div className={`${isMobile ? 'mobile-size m-auto p-3' : ''}`}>
            {tag === 'SLIDESHOW_PRINCIPAL' &&
                <SlideShowPrincipal banner={banners} bannerPage={bannerPage} isMobile={isMobile} />
            }

            {tag === 'BLOG' &&
                <BlogComponent banner={banners} isMobile={isMobile} />
            }

            {tag === "IMAGENS_HOME" &&
                <ImagemHomeComponent banner={banners} bannerPage={bannerPage} isMobile={isMobile} />
            }

            {tag === 'SLIDESHOW' &&
                <SlideShowPrincipal banner={banners} bannerPage={bannerPage} isMobile={isMobile} />
            }

            {tag === 'BANNER_FULL' &&
                <BannerFull banner={banners} />
            }

            {tag === 'CATEGORY_CAROUSEL' &&
                <CategoryCarrosel banner={banners} isMobile={isMobile} />
            }

            {tag === "MARCAS" &&
                <BranchComponent banner={banners} isMobile={isMobile} />
            }

            {tag === "PRODUCT_CAROUSEL" &&
                <ProductCarousel category={banners} isMobile={isMobile} />
            }
        </div>
    );
});

export default PreviewComponents;