import type { TableColumnsType } from "antd";
import { Button, Statistic, Table } from "antd";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import useQueryParams from "../../../hooks/useQueryParams";
import { PermissionReports } from "../../../permissions/PermissionReports";
import channelStore from "../../../stores/channelStore";
import reportGeneralSalesStore from "../../../stores/reportGeneralSalesStore";
import subChannel from "../../../stores/subChannel";
import { exportToExcel } from "../../../utils/excelFunction";
import {
  convertToDayjs,
  formatCurrency,
  formattedDates,
  getFirstFilterDates,
  roundPercentage,
} from "../../../utils/functions";
import BreadCrumbComponent from "../../atoms/BreadCrumbComponent";
import ActionButton from "../../atoms/ButtonComponent";
import DatePickerComponet from "../../atoms/DatePickerComponet";
import NotPermissionComponent from "../../atoms/NotPermissionComponent";
import SelectComponent from "../../atoms/SelectComponent";
import ContentBox from "../../molecules/ContentBox";
import Permission from "../../molecules/Permission";
import { useAuth } from "../../protected/ProviderAuth";
import { PermissionChannel } from "../../../permissions/PermissionChannel";
import { PermissionSubChannel } from "../../../permissions/PermissionSubChannel";

interface DataType {
  indicador: any;
  total: string;
}

// BreadCrumb
const breadCrumb = [
  {
    title: <a href="/dashboard">Dashboard</a>,
  },
  {
    title: <p>Visão Geral</p>,
  },
];

const ReportGeneralSales: React.FC = observer(() => {
  const { generalReport, loading } = reportGeneralSalesStore;
  const { selectChannelList } = channelStore;
  const { selectSubChannelList } = subChannel;
  const [queryParams, setQueryParams] = useQueryParams();
  const [initialDate, finalDate] = getFirstFilterDates();
  const { hasPermission } = useAuth();

  const initialValues = {
    date:
      queryParams.from && queryParams.to
        ? [convertToDayjs(queryParams.from), convertToDayjs(queryParams.to)]
        : [convertToDayjs(initialDate), convertToDayjs(finalDate)],
    channel: queryParams.channel
      ? { value: queryParams.channel, label: queryParams.channel }
      : "",
    subChannel: queryParams.subChannel
      ? { value: queryParams.subChannel, label: queryParams.subChannel }
      : "",
  };

  useEffect(() => {
    const initialParams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      paymentsTypes: "INVOICE",
      status: "ANALYSIS",
      from: queryParams.from
        ? queryParams.from + "T00:00:00.556"
        : initialDate + "T00:00:00.556",
      to: queryParams.to
        ? queryParams.to + "T00:00:00.556"
        : finalDate + "T00:00:00.556",
      channel: queryParams.channel || "",
      subChannel: queryParams.subChannel || "",
    };

    const fetchData = async () => {
      const defaultparamsChannel = {
        size: 99999,
        page: 0,
        sort: "created,desc",
        search: "",
      };

      if (hasPermission(PermissionReports.SALES_GENERAL)) {
        reportGeneralSalesStore.getGeneralSales(initialParams);
      }

      if (hasPermission(PermissionChannel.GET)) {
        await channelStore.getList(defaultparamsChannel);
      }

      if (hasPermission(PermissionSubChannel.GET)) {
        await subChannel.getList(defaultparamsChannel);
      }
    };

    fetchData();
  }, []);

  // Colunas da Tabela
  const columns: TableColumnsType<DataType> = [
    {
      title: "Indicador",
      dataIndex: "indicador",
      key: "indicador",
      width: 500,
      align: "center",
    },
    {
      title: "Valor Total",
      dataIndex: "total",
      key: "total",
      width: 500,
      align: "center",
    },
  ];

  // Faz o filtro da tabela
  const handleSubmit = async (values: {
    date: any;
    channel: any;
    subChannel: any;
  }) => {
    const formattedDate = formattedDates(values.date, "YYYY-MM-DD");
    const dateInitial = formattedDate[0];
    const dateFinal = formattedDate[1];

    const params = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: dateInitial + "T00:00:00.556",
      to: dateFinal + "T00:00:00.556",
      paymentsTypes: "INVOICE",
      status: "ANALYSIS",
      channel: values.channel.value || "",
      subChannel: values.subChannel.value || "",
    };

    const newParams: any = {
      to: dateFinal || undefined,
      from: dateInitial || undefined,
      channel: params.channel || undefined,
      subChannel: params.subChannel || undefined,
    };

    setQueryParams(newParams);

    //@ts-ignore
    await reportGeneralSalesStore.getGeneralSales(params);
  };

  // Limpa os dados do formulario
  const handleClearAndSearch = async (resetForm: () => void) => {
    resetForm(); // Limpa os valores do formulário
    setQueryParams({}); // Limpa a URL

    const clearparams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: initialDate + "T00:00:00.556",
      to: finalDate + "T00:00:00.556",
      paymentsTypes: "INVOICE",
      status: "ANALYSIS",
      channel: "",
      subChannel: "",
    };

    await reportGeneralSalesStore.getGeneralSales(clearparams);
  };

  const returnFormatedList = (reportResponse: any) => {
    const newList = [
      {
        indicador: "Valor total de vendas",
        total: formatCurrency(reportResponse?.totalSalesValues),
      },
      {
        indicador: "Número de Pedidos",
        total: reportResponse?.totalSalesOrders,
      },
      {
        indicador: "Valor total de desconto",
        total: formatCurrency(reportResponse?.totalDiscountValues),
      },
      {
        indicador: "Número de pedidos com desconto",
        total: reportResponse?.totalDiscountOrders,
      },
      {
        indicador: "Valor total de frete",
        total: formatCurrency(reportResponse?.totalShippingValues),
      },
      {
        indicador: "Número de pedidos com frete",
        total: reportResponse?.totalShippingOrders,
      },
      {
        indicador: "% de Pedidos pagos por Boleto",
        total: `${reportResponse?.paymentsReports
          ? roundPercentage(
            reportResponse?.paymentsReports[0]?.percentage || 0
          )
          : "0"
          }%`,
      },
      {
        indicador: "Conversão de Carrinhos em Pedidos",
        total: `${roundPercentage(reportResponse?.percentageOrdersConverted)}%`,
      },
    ];

    return newList;
  };

  const handleExport = async () => {
    const defaultParams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      paymentsTypes: "INVOICE",
      status: "ANALYSIS",
      from: queryParams.from
        ? queryParams.from + "T00:00:00.556"
        : initialDate + "T00:00:00.556",
      to: queryParams.to
        ? queryParams.to + "T00:00:00.556"
        : finalDate + "T00:00:00.556",
      channel: queryParams.channel || "",
      subChannel: queryParams.subChannel || "",
    };
    const dataExport = await reportGeneralSalesStore.getGeneralSales(defaultParams);

    if (dataExport) {
      const listExport = [{
        totalSalesValues: formatCurrency(dataExport.totalSalesValues),
        totalSalesOrders: dataExport.totalSalesOrders,
        totalDiscountValues: formatCurrency(dataExport.totalDiscountValues),
        totalDiscountOrders: dataExport.totalDiscountOrders,
        totalShippingValues: formatCurrency(dataExport.totalShippingValues),
        totalShippingOrders: dataExport.totalShippingOrders,
        paymentsReports: `${dataExport?.paymentsReports
          ? roundPercentage(
            dataExport?.paymentsReports[0]?.percentage || 0
          )
          : "0"
          }%`,
        percentageOrdersConverted: `${roundPercentage(dataExport?.percentageOrdersConverted)}%`

      }];

      exportToExcel(listExport, ["Valor total de vendas", "Número de Pedidos", "Valor total de desconto", "Número de pedidos com desconto", "Valor total de frete", "Número de pedidos com frete", "% de Pedidos pagos por Boleto", "Conversão de Carrinhos em Pedidos"], "Tabela de Visão Geral.xlsx");
    }
  };

  return (
    <>
      <BreadCrumbComponent breadCrumbList={breadCrumb} />

      <Permission permissionKeys={[PermissionReports.SALES_GENERAL]} fallback={NotPermissionComponent()}>
        <ContentBox
          className="mb-4 p-6"
          title="Visão Geral"
          description="Informações gerais sobre as vendas"
        >
          {/* @ts-ignore */}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ resetForm }) => (
              <Form>
                <div className="flex items-end w-full gap-4 pb-4">
                  <div className="w-full">
                    <DatePickerComponet label="Data inicial" name="date" />
                  </div>
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionChannel.GET]} inputType name="Canal">
                      <SelectComponent
                        label="Canal"
                        name="channel"
                        options={selectChannelList}
                      />
                    </Permission>
                  </div>
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionSubChannel.GET]} inputType name="SubCanal">
                      <SelectComponent
                        label="Sub Canal"
                        name="subChannel"
                        options={selectSubChannelList}
                      />
                    </Permission>
                  </div>
                  <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                    <Button
                      className="w-full"
                      onClick={() => handleClearAndSearch(resetForm)}
                    >
                      Limpar
                    </Button>
                    <Button className="w-full" htmlType="submit" type="primary">
                      Filtrar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ContentBox>

        <ContentBox className="mb-4 p-6">
          <div className="flex items-end w-full gap-4 pb-4 items-center justify-between">
            <p>Visão Geral</p>

            <div className="flex gap-4">
              <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
            </div>
          </div>

          <div className="flex mb-4 gap-8 justify-between px-8 py-4 bg-slate-50 rounded-lg">
            <Statistic
              title="Valor total"
              value={`${generalReport?.totalSalesValues
                ? formatCurrency(generalReport?.totalSalesValues)
                : "0"
                }`}
              precision={2}
            />
            <Statistic
              title="Número de pedidos"
              value={`${generalReport?.totalSalesOrders
                ? generalReport?.totalSalesOrders
                : "0"
                }`}
            />
            <Statistic
              title="Ticket médio"
              value={`${generalReport?.totalSalesValues && generalReport?.totalSalesOrders
                ? formatCurrency(
                  generalReport?.totalSalesValues /
                  generalReport?.totalSalesOrders
                )
                : "0"
                }`}
            />
            <Statistic
              title="Taxa de conversão"
              value={`${generalReport?.percentageOrdersConverted
                ? generalReport?.percentageOrdersConverted
                : "0"
                }`}
              suffix="%"
              precision={2}
            />
          </div>

          <Table
            className="table-custom"
            columns={columns}
            dataSource={returnFormatedList(generalReport).map(
              (item: any, index: number) => ({
                ...item,
                key: item.uuid || index,
              })
            )}
            loading={loading}
            size="middle"
            bordered={false}
            pagination={false}
          />
        </ContentBox>
      </Permission>
    </>
  );
});

export default ReportGeneralSales;
