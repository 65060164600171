import { Carousel } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ProductCard from './ProductCard';
import SkeletonProduct from './SkeletonProduct';
import './styles.scss';


interface ProductCarouselProps {
    category?: any;
    isMobile?: boolean;
    products: any[]; // Agora o `products` é recebido como prop
    loading: boolean;
}

const ProductCarousel: React.FC<ProductCarouselProps> = observer(({ category, isMobile, products, loading }) => {
    const skeletonProductWeb = [1, 2, 3, 4];
    const skeletonProductMobile = [1, 2];

    const settings = {
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
    };

    const settingsMobile = {
        infinite: true,
        slidesToShow: 1.5,
        slidesToScroll: 1,
    };

    return (
        <div>
            {loading &&
                <div className="py-8">
                    <div className="flex pb-8 items-center justify-between">
                        <p className="text-2xl font-semibold">{category?.categoryName}</p>
                        <div className="button-carrousel">
                            <p>Ver Todos</p>
                        </div>
                    </div>

                    {isMobile &&
                        <div className="container-skeleton-web py-8">
                            {skeletonProductMobile.map((index) => (
                                <SkeletonProduct key={index} />
                            ))}
                        </div>
                    }

                    {!isMobile &&
                        <div className="container-skeleton py-8">
                            {skeletonProductWeb.map((index) => (
                                <SkeletonProduct key={index} />
                            ))}
                        </div>
                    }
                </div>
            }

            {!loading &&
                <div>
                    {products && products.length > 0 && (
                        <div className="carrosel-products py-8 max-width-view">
                            <div>

                                {!isMobile &&
                                    <div className="flex pb-8 items-center justify-between">
                                        <p className="text-2xl font-semibold">{category?.categoryName}</p>
                                        <div className="button-carrousel">
                                            <p>Ver Todos</p>
                                        </div>
                                    </div>
                                }

                                {isMobile &&
                                    <div className="flex pb-8 items-center justify-between">
                                        <p className="text-xl font-semibold">{category?.categoryName}</p>
                                    </div>
                                }

                                <Carousel {...(isMobile ? settingsMobile : settings)} arrows>
                                    {products.map((item: any, index: number) => (
                                        <div key={index}>
                                            <ProductCard product={item} />
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    )}
                </div>
            }

        </div>
    );
});

export default ProductCarousel;