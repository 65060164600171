import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cupomStore from '../../../../../stores/cupomStore';
import { campaignItems, YesOrNot } from '../../../../../utils/selects';
import DatePickerComponet from '../../../../atoms/DatePickerComponet';
import InputComponent from '../../../../atoms/InputComponet';
import InputNumberComponente from '../../../../atoms/InputNumberComponet';
import SelectComponent from '../../../../atoms/SelectComponent';
import SwitchComponent from '../../../../atoms/SwitchComponent';
import ContentBox from '../../../../molecules/ContentBox';


const InformationComponent: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const [restrictPrice, setRestrictPrice] = useState<any>();
    const [limitCupons, setLimitCupons] = useState<any>();
    const { cupomProps } = cupomStore;

    const setCampaingType = (option: any) => {
        cupomStore.updateValue(option.value);
    }

    useEffect(() => {
        const findRestriction = cupomProps && cupomProps?.restrictions?.find((item: any) => item['@class'] === 'br.com.stoom.kernel.model.persistence.restriction.PriceRestriction');

        if (findRestriction?.excludes === false) {
            setRestrictPrice({ value: 'false', label: 'Não' },)
        }

        if (findRestriction?.excludes === true) {
            setRestrictPrice({ value: 'true', label: 'Sim' },)
        }
    }, [cupomProps])


    useEffect(() => {

        if (!cupomProps) {
            setCampaingType({
                label: 'Desconto Absoluto',
                value: 'Desconto Absoluto X',
            })
        }

        if (!cupomProps) {
            setRestrictPrice({ value: 'false', label: 'Não' })
        }
    })

    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Cadastro de Cupom" description='Tela para cadastro de Cupom' actions={
                <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
            }>
                <div className="flex gap-4">
                    <InputComponent label="Nome" name="name" id="name" />
                    <DatePickerComponet label="Data inicial" name="date" />
                </div>
            </ContentBox>

            <ContentBox className="mb-4 p-6" title="Modelo do Cupom" description="Selecione o modelo do Cupom">
                <div className="flex gap-4 mt-2">
                    <SelectComponent onChange={(value, option) => setCampaingType(option)} label="Tipo de desconto*" name="type" options={campaignItems} />

                    {cupomProps?.type === 'Desconto Percentual X' &&
                        <InputNumberComponente label="Valor do desconto Percentual %" name="productsPercentageDiscount" id="productsPercentageDiscount" />
                    }

                    {cupomProps?.type === 'Desconto Absoluto X' &&
                        <InputNumberComponente label="Valor do desconto Absoluto*" name="productsAbsoluteDiscount" id="productsAbsoluteDiscount" />
                    }
                </div>
            </ContentBox>

            <ContentBox className="mb-4 p-6" title="Cadastro de Cupom" description='Tela para cadastro de Cupom'>
                <div className="flex gap-4 mt-2">
                    <SelectComponent onChange={(value, option) => setLimitCupons(option)} label="O uso do cupom é limitado?*" disabled={uuid ? true : false} name="yesOrNoMaxUses" options={YesOrNot} />
                    <InputNumberComponente disabled={limitCupons?.value === 'false' ? true : false} label="Limite de Utilizações*" name="maxUses" id="maxUses" />
                </div>

                <div className="flex gap-4 mt-4">
                    <SelectComponent onChange={(value, option) => setRestrictPrice(option)} label="Restringir Preço ?" name="priceRestrictionExcludes" options={YesOrNot} />


                    <InputComponent disabled={restrictPrice?.value === 'false' ? true : false} label="Mínimo para o cupom ser aplicável" name="minPrice" id="minPrice" />
                    <InputComponent disabled={restrictPrice?.value === 'false' ? true : false} label="Maximo para o cupom ser aplicável" name="maxPrice" id="maxPrice" />
                </div>
            </ContentBox>

        </>
    );
});

export default InformationComponent;