import { observer } from 'mobx-react-lite';
import React from 'react';
import ColorPickerComponent from '../../../atoms/ColorPickerComponent';
import InputComponent from '../../../atoms/InputComponet';
import ContentBox from '../../../molecules/ContentBox';
import PaymentTypes from '../PaymentTypes';



const FooterConfiguration: React.FC = observer(() => {
    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Cores do Header" description='Cadastro das Cores do Header'>
                <div className="w-full flex flex-col gap-4">
                    <div className="flex gap-4">
                        <ColorPickerComponent label="Background Footer*" name="colorFooterBackground" id="colorFooterBackground" />
                        <ColorPickerComponent label="Cor da Fonte*" name="colorFooterFont" id="colorFooterFont" />
                    </div>
                </div>
            </ContentBox>

            <ContentBox className="mb-4 p-6" title="Links rede social" description='Cadastro de icones de Rede Social'>
                <div className="w-full flex flex-col gap-4">
                    <div className="flex gap-4">
                        <InputComponent label="Link Facebook*" name="facebook" id="facebook" />
                        <InputComponent label="Link Instagram*" name="instagram" id="instagram" />
                        <InputComponent label="Link Youtube*" name="youtube" id="youtube" />
                    </div>
                    <div className="grid grid-cols-12 gap-6 mb-2" >
                        <div className="col-span-4 mb-4">
                            <InputComponent label="Link Linkedin*" name="linkedin" id="linkedin" />
                        </div>
                        <div className="col-span-4 mb-4">
                            <InputComponent label="Link Twitter*" name="twitter" id="twitter" />
                        </div>
                    </div>
                </div>
            </ContentBox>

            <PaymentTypes />
        </>
    );
});

export default FooterConfiguration;