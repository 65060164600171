import URLS from '../config/urls';
import { Voltage, VoltageList } from '../types/Voltage';
import BaseAPI from './BaseApi';


class VoltageService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get<VoltageList>(`${URLS.VOLTAGE}`, { params });
            return response.data; // Retorna apenas o conteúdo da resposta
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async createVoltagem(voltage: Voltage): Promise<any> {
        try {
            const response = await BaseAPI.post<Voltage>(`${URLS.VOLTAGE}`, voltage);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async editVoltagem(voltage: Voltage, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put<Voltage>(`${URLS.VOLTAGE}/${uuid}`, voltage);
            return response;
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async getVoltagemByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.VOLTAGE}/${uuid}`);
            return response;
        } catch (e) {
            return { error: "Erro ao buscar dados" };
        }
    }


    async deleteVoltagem(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.VOLTAGE}/${uuid}`);
            return response;
        } catch (e) {
            return { error: "Erro ao deletar" };
        }
    }
}

export default new VoltageService;