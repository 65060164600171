import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import campaingStore from '../../../../../stores/campaingStore';
import { formatDateString } from '../../../../../utils/dateFunctions';
import { getUniqueFilters } from '../../../../../utils/functions';
import { YesOrNot } from '../../../../../utils/selects';
import SelectComponent from '../../../../atoms/SelectComponent';
import ContentBox from '../../../../molecules/ContentBox';
import ModalComponent from '../../../../organisms/ModalComponent';
import DrawerZipCode from '../DrawerZipCode';


interface DataType {
    endZipCode: string;
    startZipCode: string;
}


const TableZipCodeRestriction: React.FC = observer(() => {
    const { loading, zipCodeRestrictionList } = campaingStore;
    const [isModalDrawer, setIsDrawerOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'CEP Inicial',
            dataIndex: 'startZipCode',
            key: 'startZipCode',
            filters: getUniqueFilters(zipCodeRestrictionList, 'startZipCode'),
            onFilter: (value, record) => record.startZipCode.indexOf(value as string) === 0,
        },
        {
            title: 'CEP Final',
            dataIndex: 'endZipCode',
            key: 'endZipCode',
            filters: getUniqueFilters(zipCodeRestrictionList, 'endZipCode'),
            onFilter: (value, record) => record.endZipCode.indexOf(value as string) === 0,
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 50,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        campaingStore.removeZipCode(itemSelected.uuid);
        setIsModalOpen(false);
    };

    return (
        <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Faixa de CEP" description='Caso seja informado faixa(s) de CEP, o cupom será aplicado aos clientes cujo CEP esteja contido em uma das faixas cadastradas. Caso não exista faixas configuradas, o cupom será aplicado à todos os clientes.'>
            <div className="flex gap-4 items-end mb-4">
                <SelectComponent label="Remove Faixa CEP" name="excludeZipCode" options={YesOrNot} />
                <Button type="primary" onClick={() => setIsDrawerOpen(true)}>Adicionar</Button>
            </div>

            <Table
                className="table-custom"
                columns={columns}
                dataSource={zipCodeRestrictionList !== undefined && zipCodeRestrictionList.map((item: any, index: number) => ({
                    ...item,
                    key: item.uuid || index,
                }))}
                loading={loading}
                pagination={false}
                size="middle"
                bordered={false}
                showSorterTooltip={{ target: 'sorter-icon' }}
            />

            {/* @ts-ignore */}
            <DrawerZipCode setIsDrawerOpen={setIsDrawerOpen} isModalDrawer={isModalDrawer} />


            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o ZipCode <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />
        </ContentBox>
    );
});

export default TableZipCodeRestriction;
