import React from "react";
import ContentBox from "../../molecules/ContentBox";

const NotPermissionComponent = () => (
  <div className="flex flex-col w-full">
    <ContentBox className="flex p-6 text-center h-full items-center justify-center">
      <p>O usuário não possui permissão para acessar este conteúdo.</p>
    </ContentBox>
  </div>
);

export default NotPermissionComponent;
