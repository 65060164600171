import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class LogService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.LOG}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }
}

export default new LogService();