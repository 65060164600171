import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import EvaluationService from '../services/EvaluationService';
import { PaginationParams } from '../types';


class EvaluationStore {
    loading: boolean = false;
    evaluationList: any = [];
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        statuses: 'ACCEPTED,PENDING,REJECTED',
        rating: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await EvaluationService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.evaluationList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
                this.sort = params.sort;
            }
            this.loading = false;
        });

        return response;
    }


    async updateEvaluations(status: string, uuid: string) {
        this.loading = true;
        const response = await EvaluationService.updateEvaluations(uuid, status);

        if (response.error) {
            message.error(`Erro ao editar Avaliação`);
        } else {
            message.success('Avaliação editado com sucesso!');
        }
        return response;
    };
}

const evaluationStore = new EvaluationStore();
export default evaluationStore;