import { observer } from 'mobx-react-lite';
import React from 'react';
import ColorPickerComponent from '../../../atoms/ColorPickerComponent';
import ContentBox from '../../../molecules/ContentBox';

const ButtonColor: React.FC = observer(() => {
    return (
        <ContentBox className="mb-4 p-6" title="Cores do Botão" description='Selecione as Cores de Botões da Plataforma'>
            <div className="flex gap-4">
                <ColorPickerComponent label="Cor primária (Botão)" name="primaryColor" id="primaryColor" />
                <ColorPickerComponent label="Cor secundaria (Botão)" name="secondaryColor" id="secondaryColor" />
            </div>
        </ContentBox>
    );
});

export default ButtonColor;