import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import DashboardService from '../services/DashboardService';
import { message } from 'antd';


class DashboardStore {
    loading: boolean = false;
    dashboardProps: any = [];
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList() {
        this.loading = true;
        const response = await DashboardService.getList();

        if (!response.error) {
            this.dashboardProps = response.data;
        } else {
            message.error('Erro ao trazer informações da Dashboard');
        }
        this.loading = false;

        return response;
    }
}

const dashboardStore = new DashboardStore();
export default dashboardStore;