import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { DEFAULT_PAGE_SIZE } from '../config/config';


const useQueryParams = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const setQueryParams = (params: Record<string, any>) => {
        const currentParams = queryString.parse(location.search);
        const newParams = { ...currentParams, ...params };

        // Se params for um objeto vazio, deve remover todos os parâmetros da URL
        if (Object.keys(params).length === 0) {
            navigate({
                pathname: location.pathname,
                search: '',
            });
        } else {
            navigate({
                pathname: location.pathname,
                search: queryString.stringify(newParams),
            });
        }
    };

    return [queryString.parse(location.search), setQueryParams] as const;
};

export default useQueryParams;


type Mappings = {
    [key: string]: string; // Mapeia cada chave de query para a chave do parâmetro
};

type Params = {
    size: number;
    page: number;
    sort: string;
    search: string;
    params?: any;
    simbol?: string;
    secondSimbol?: string;
    [key: string]: any; // Permite adicionar outros parâmetros se necessário
};


export const buildParamsFromQuery = (queryParams: any, mappings: Mappings, simbol?: string, secondSimbol?: string): Params => {
    const params = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    };

    for (const [key, value] of Object.entries(queryParams)) {
        if (mappings[key]) {
            if (key === 'page' || key === 'size') {
                params[key] = Number(value); // Converte para número
            } else if (key === 'sort') {
                params.sort = value as string; // Presume que o valor de sort é uma string
            } else {
                // Usando o mapeamento para construir a string de busca
                const mappedKey = mappings[key];
                params.search += `${secondSimbol ? secondSimbol : ""}${mappedKey}${simbol ? simbol : ";"}"${value}" `;
            }
        }
    }


    return params;
};


export const buildParamsFromQueryReceivedParams = (queryParams: any, mappings: Mappings, params: any, simbol?: string, secondSimbol?: string): Params => {
    for (const [key, value] of Object.entries(queryParams)) {
        if (mappings[key]) {
            if (key === 'page' || key === 'size') {
                params[key] = Number(value); // Converte para número
            } else if (key === 'sort') {
                params.sort = value as string; // Presume que o valor de sort é uma string
            } else {
                // Usando o mapeamento para construir a string de busca
                const mappedKey = mappings[key];
                params.search += `${secondSimbol ? secondSimbol : ""}${mappedKey}${simbol ? simbol : ";"}"${value}" `;
            }
        }
    }


    return params;
};