import { makeAutoObservable } from 'mobx';
import reportLogAcessService from '../services/ReportLogService';
import { DEFAULT_PAGE_SIZE } from '../config/config';


class ReportLogAcessStore {
    loading: boolean = false;
    reportList: any = [];
    reportTotal: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
    }


    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: any) {
        this.loading = true;
        let url;

        if (params.customer) {
            url = `/manager/reports/logins?page=${params.page}&size=${params.size}&sort=created&search=user_username;"${params.customer}":created:"${params.from} 00-00-00;${params.to} 23-59-59"`;
        } else {
            url = `/manager/reports/logins?page=${params.page}&size=${params.size}&sort=created&search=created:"${params.from} 00-00-00;${params.to} 23-59-59"`;
        }

        const response = await reportLogAcessService.getList(url);

        if (!response.error) {
            this.reportList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        }
        this.loading = false;
        return response;
    }
}

const reportLogAcessStore = new ReportLogAcessStore();
export default reportLogAcessStore;