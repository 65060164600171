import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class CupomService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COUPONS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async getCupomByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COUPONS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async createCupom(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.COUPONS}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editCupom(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.COUPONS}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async deleteCupom(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.COUPONS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async getReports(params: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.REPORT_CUPOM}`, params);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar" }; // Retorna um objeto de erro
        }
    }
}

export default new CupomService();