export function mergeAuthorities(data: any) {
    const uniqueAuthorities = new Map();

    // Adiciona os authorities externos
    data.authorities.forEach((authority: any) => {
        // Clonamos o objeto e adicionamos a propriedade "source" como "external"
        const authorityWithSource = { ...authority, source: false };
        uniqueAuthorities.set(authority.uuid, authorityWithSource);
    });

    // Adiciona os authorities internos
    data.authorityGroups.forEach((group: any) => {
        group.authorities.forEach((authority: any) => {
            // Clonamos o objeto e adicionamos a propriedade "source" como "internal"
            const authorityWithSource = { ...authority, source: true };
            uniqueAuthorities.set(authority.uuid, authorityWithSource);
        });
    });

    // Retorna o array de authorities únicos, incluindo a origem
    return Array.from(uniqueAuthorities.values());
}


export const getDifferences = (authoritiesFromDB: any[], individualPermission: any[]) => {
    // Obter os UUIDs de cada lista
    const dbUUIDs = authoritiesFromDB.map((item) => item.uuid);
    const frontUUIDs = individualPermission.map((item) => item.uuid);

    // Determinar itens novos (adicionar)
    const toAdd = individualPermission.filter((item) => !dbUUIDs.includes(item.uuid));

    // Determinar itens removidos (deletar)
    const toRemove = authoritiesFromDB.filter((item) => !frontUUIDs.includes(item.uuid));

    return { toAdd, toRemove };
};