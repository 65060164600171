import { InputNumber, Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';
import './input.scss';

//@ts-ignore
interface InputNumberComponenteProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    maxLength?: number;
    disabled?: boolean;
    showMaxLength?: boolean;
    name: string; // Tornando o name obrigatório
    size?: string;
    value?: any;
    min?: number;
    max?: number;
    onChange?: (option: any) => void;
}

const InputNumberComponente: React.FC<InputNumberComponenteProps> = ({ label, min, max, name, size, disabled, placeholder, onChange, value }) => {
    return (
        <div className="flex flex-col w-full input-container">
            {label && <Typography.Title level={5}>{label}</Typography.Title>}
            <Field name={name}>
                {({ field, meta }: any) => {
                    const hasError = meta.touched && meta.error;

                    return (
                        <>
                            <InputNumber
                                {...field}
                                min={min}
                                max={max}
                                size={size || "large"}
                                disabled={disabled}
                                placeholder={placeholder}
                                status={hasError ? 'error' : ''}
                                value={value || field.value || undefined} // Defina o valor como undefined se estiver vazio
                                onChange={(value) => {
                                    // Tratar valor vazio como undefined
                                    const newValue = value === null ? '' : Number(value);
                                    field.onChange({ target: { name, value: newValue } }); // Atualize aqui
                                    onChange && onChange(newValue);
                                }}
                            />
                            {hasError && (
                                <Typography.Text type="danger">{meta.error}</Typography.Text>
                            )}
                        </>
                    );
                }}
            </Field>
        </div>
    );
};

export default InputNumberComponente;
