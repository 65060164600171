import { DeleteOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import homeStore from '../../../../stores/homeStore';
import { formatDateString } from '../../../../utils/dateFunctions';
import { getUniqueFilters, getUniquePropertyFilters } from '../../../../utils/functions';
import ContentBox from '../../../molecules/ContentBox';
import ModalComponent from '../../../organisms/ModalComponent';

interface DataType {
    component: any;
    active: any;
    maxDate: any;
    type: any;
    order: any;
    valEnd: any;
    valStart: any;
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

interface ListComponentsProps {
    title?: string;
    subtitle?: string;
    listComponents: any;
    isMobile: boolean;
}

const ListComponents: React.FC<ListComponentsProps> = observer(({ title, subtitle, listComponents, isMobile }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Ordenação',
            dataIndex: 'order',
            key: 'order',
            width: 80,
            filters: getUniqueFilters(listComponents, 'order'),
            onFilter: (value, record) => record.order.indexOf(value as string) === 0,
        },
        {
            title: 'Nome',
            dataIndex: 'component',
            key: 'component.name',
            filters: getUniquePropertyFilters<any, 'component', 'name'>(listComponents, 'component', 'name'),
            onFilter: (value, record) => record.component.name.indexOf(value as string) === 0,
            render: (item) => {
                return (
                    <>{item && <p>{item.name}</p>}</>
                );
            },
        },
        {
            title: 'Imagem ou Carrosel',
            dataIndex: 'component',
            key: 'component.type',
            filters: getUniquePropertyFilters<any, 'component', 'type'>(listComponents, 'component', 'type'),
            onFilter: (value, record) => record.component.type.indexOf(value as string) === 0,
            render: (item) => {
                return (
                    <>{item && <p>{item.type}</p>}</>
                );
            },
        },
        {
            title: 'Data Inicial',
            dataIndex: 'component',
            key: 'component.minDate',
            render: (text, record) => {
                return (
                    <>{<p>{formatDateString(record.component.minDate)}</p>}</>
                );
            },
        },
        {
            title: 'Data Inicial',
            dataIndex: 'maxDate',
            key: 'component.maxDate',
            render: (text, record) => {
                return (
                    <>{<p>{formatDateString(record.component.maxDate)}</p>}</>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'component.maxDate',
            key: 'maxDate',
            render: (text) => {
                const today = new Date(); // Data e hora atuais
                const finalDate = new Date(text); // Data final

                // Verifica se a data final já passou em relação à data de hoje
                const isExpired = finalDate < today;

                return (
                    <>
                        <Tag color={isExpired ? "red" : "green"}><p>{isExpired ? 'Vencido' : 'Ativo'}</p></Tag>
                    </>
                );
            },
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        homeStore.removeComponent(itemSelected.componentUuid);
        setIsModalOpen(false);
    };

    return (
        <div>
            <ContentBox className="mb-4 p-6 rounded-none" title={title} description={subtitle}>
                <div className="w-full flex flex-col gap-2">
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={listComponents && listComponents?.filter((item: any) => item.mobile === isMobile).map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={false}
                        size="middle"
                        bordered={false}
                        pagination={false}
                    />
                </div>
            </ContentBox>

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Componente <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />
        </div>
    );
});

export default ListComponents;