import { observer } from 'mobx-react-lite';
import React from 'react';
import { Status } from '../../../../utils/selects';
import ColorPickerComponent from '../../../atoms/ColorPickerComponent';
import SelectComponent from '../../../atoms/SelectComponent';
import ContentBox from '../../../molecules/ContentBox';
import TableTopBar from './Table';


const TopBarConfiguration: React.FC = observer(() => {
    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Barra Superior" description='Gerenciamento de Barra Superior'>
                <div className="w-full flex flex-col gap-4">
                    <div className="flex gap-4">
                        <SelectComponent label="Desabilitar Barra (Web)" name="activeWeb" options={Status} />
                        <SelectComponent label="Desabilitar Barra (Responsivo)" name="activeMobile" options={Status} />
                    </div>
                    <div className="flex gap-4">
                        <ColorPickerComponent label="Background Barra Superior*" name="colorSubHeaderBackground" id="colorSubHeaderBackground" />
                        <ColorPickerComponent label="Cor da Fonte*" name="colorSubHeaderFont" id="colorSubHeaderFont" />
                    </div>
                </div>
            </ContentBox>

            <TableTopBar title="Links de acesso (WEB)" description="Cadastro de Links para redirecionamento dentro do site" mobile={false} />
            <TableTopBar title="Links de acesso (MOBILE)" description="Cadastro de Links para redirecionamento dentro do site" mobile={true} />
        </>
    );
});

export default TopBarConfiguration;