import { message, Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import bannerStore from '../../../../stores/bannerStore';
import { BannerType } from '../../../../utils/selects';
import './styles.scss';
import { ImageIcon, RuleIcon } from '../../../molecules/IconComponent';
import { canChangeBannerType } from '../../../../utils/bannerFunctions';


interface ModalBannerTagProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    setSelectedTag: Dispatch<SetStateAction<any>>;
    selectedTag?: any;
}


const ModalBannerTag: React.FC<ModalBannerTagProps> = observer(({ isModalOpen, setIsModalOpen, setSelectedTag, selectedTag }) => {
    const isSelected = (item: any) => item.value === selectedTag;
    const { banner } = bannerStore;

    const selectBanner = (item: any) => {
        const currentImages = banner.files.length; // Número atual de imagens

        if (!canChangeBannerType(item, currentImages)) {
            message.error(`Para selecionar o tipo ${item}, remova algumas imagens para não exceder o limite.`);
        } else {
            // Código para trocar o tipo de banner
            bannerStore.updateValue('tag', selectedTag);
            setIsModalOpen(false);
            setSelectedTag(item);
        }
    }

    return (
        <>
            <Modal width={1000} title="Selecione o Modelo do Banner" onOk={() => selectBanner(selectedTag)} open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
                <div className="flex w-full gap-4 pb-4 grid grid-cols-12 modal-banner-tag pr-2">
                    {BannerType?.map((item) => (
                        <div
                            key={item.value}
                            onClick={() => selectBanner(item.value)}
                            className={`col-span-4 rounded cursor-pointer ${isSelected(item) ? 'border-2 border-blue-500' : 'border border-slate-200'}`}
                        >
                            <div className="p-4 flex justify-center">
                                <img src={item.img} alt={item.label}></img>
                            </div>
                            <div className="flex flex-col gap-2 px-5 pb-3">
                                <p className="font-medium">{item.label}</p>
                                <p className="text-slate-700">{item.description}</p>
                            </div>
                            <div className="flex justify-between px-5 border-t py-3">
                                <div className="flex gap-2 items-center min-w-24">
                                    <RuleIcon />
                                    <p className="text-slate-700">{item.dimension}</p>
                                </div>
                                <div className="text-slate-700">/</div>
                                <div className="flex gap-2 items-center min-w-24">
                                    <ImageIcon />
                                    <p className="text-slate-700">{item.maxImage}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    );
});

export default ModalBannerTag;