import { message, Tabs, TabsProps } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionHome } from '../../../permissions/PermissionHome';
import bannerStore from '../../../stores/bannerStore';
import categoryStore from '../../../stores/categoryStore';
import channelStore from '../../../stores/channelStore';
import componentsStore from '../../../stores/componentsStore';
import homeStore from '../../../stores/homeStore';
import subChannel from '../../../stores/subChannel';
import { convertToDayjs, formattedDates } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import CreateComponent from './CreateComponent';
import GeneralInfomation from './GeneralInfomation';
import ViewHomeFull from './ViewHomeFull';
import { PermissionCategories } from '../../../permissions/PermissionCategories';
import { PermissionChannel } from '../../../permissions/PermissionChannel';
import { PermissionSubChannel } from '../../../permissions/PermissionSubChannel';
import { PermissionBanners } from '../../../permissions/PermissionBanners';
import { PermissionComponents } from '../../../permissions/PermissionComponents';
import { validationSchema } from './schema';

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/home">Listagem de Home</a>,
    },
    {
        title: <p>Cadastro de Home</p>,
    },
]

const CreateHome: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { homeProps, loading, previewList } = homeStore;
    const { hasPermission } = useAuth();
    const [isMobile, setMobile] = useState<boolean>(false);

    const defaultparams = {
        size: 999999,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    const navigate = useNavigate();
    const [activeTabKey, setActiveTabKey] = useState('1');

    useEffect(() => {
        const getInformation = async () => {
            if (uuid && hasPermission(PermissionHome.PUT)) {
                await homeStore.getHomeByUuid(uuid);
            }
            if (hasPermission(PermissionCategories.GET)) {
                categoryStore.getList();
            }
            if (hasPermission(PermissionChannel.GET)) {
                channelStore.getList(defaultparams);
            }
            if (hasPermission(PermissionSubChannel.GET)) {
                subChannel.getList(defaultparams);
            }
            if (hasPermission(PermissionBanners.GET)) {
                bannerStore.getList(defaultparams);
            }
            if (hasPermission(PermissionComponents.GET)) {
                componentsStore.getList(defaultparams);
            }
            // configStore.getConfig();
        };

        getInformation();

        return () => {
            homeStore.reset();
        };
    }, []);

    const initialValues: any = {
        name: homeProps?.name || '',
        model: { value: homeProps?.model, label: '' },
        uuid: homeProps?.uuid || undefined,
        channel: { value: homeProps?.channel, label: '' },
        subChannel: { value: homeProps?.subChannel, label: '' },
        active: homeProps?.active || false,
        date: homeProps ? [convertToDayjs(homeProps?.initialDate), convertToDayjs(homeProps?.finalDate)] : '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: any) => {
        const formattedDate = formattedDates(values?.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];
        let response;

        const createPros = {
            ...values,
            model: values?.model?.value,
            subChannel: values?.subChannel?.value,
            channel: values?.channel?.value,
            initialDate: `${dateInitial} 00:00:00`,
            finalDate: `${dateFinal} 23:59:59`,
            active: values.active || false,
        }

        if (values?.model.value === 'subChannel') {
            delete createPros.channel;
            homeStore.clearCustomer();
        }

        if (values?.model.value === 'channel') {
            delete createPros.subChannel
            homeStore.clearCustomer();
        }

        if (values?.model.value === 'customer') {
            delete createPros.subChannel
            delete createPros?.channel
        }

        if (values?.model.value === 'default') {
            delete createPros.subChannel
            delete createPros?.channel;
            homeStore.clearCustomer();
        }

        delete createPros.date;
        delete createPros.searchTermCustomer;

        if (uuid) {
            response = await homeStore.editHome(createPros, uuid);
        } else {
            response = await homeStore.createHome(createPros);
        }

        if (!response.error) listRouter();
    };



    const onChange = (key: string) => {
        setActiveTabKey(key);
    };

    const listRouter = () => {
        navigate(`/home`);
    }


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Informação Geral',
            children: <GeneralInfomation />,
        },
        {
            key: '2',
            label: 'Cadastro de Componentes',
            children: <CreateComponent />,
        },
        {
            key: '3',
            label: 'Visualização',
            children: <ViewHomeFull setMobile={setMobile} isMobile={isMobile} home={previewList} />,
        },
    ];

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionHome.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading ? (
                <ContentBox className="mb-4 p-6" title="Cadastro de Home" description="Tela para cadastro de Home">
                    <LoadingComponent />
                </ContentBox>
            ) : (
                <Permission
                    permissionKeys={[PermissionHome.POST, PermissionHome.PUT, PermissionHome.GET_UUID]}
                    fallback={NotPermissionComponent()}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ errors, validateForm }) => (
                            <Form>
                                <Tabs
                                    className="mb-0"
                                    type="card"
                                    activeKey={activeTabKey}
                                    onChange={onChange}
                                    items={items}
                                />
                                <div className="flex justify-end">
                                    <Permission permissionKeys={[PermissionHome.PUT, PermissionHome.POST]} buttonType>
                                        <ActionButton onClick={async () => {
                                            const formErrors = await validateForm();

                                            if (Object.keys(formErrors).length > 0) {
                                                setActiveTabKey('1');
                                                message.error(`Há campos que não foram preenchidos`);
                                            }
                                        }} htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                    </Permission>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Permission>
            )}
        </>
    );
});

export default CreateHome;