import { observer } from 'mobx-react-lite';
import React from 'react';
import { PermissionColor } from '../../../../permissions/PermissionColor';
import { PermissionMaterials } from '../../../../permissions/PermissionMaterials';
import { PermissionSize } from '../../../../permissions/PermissionSize';
import { PermissionVoltage } from '../../../../permissions/PermissionVoltage';
import colorStore from '../../../../stores/colorStore';
import materialStore from '../../../../stores/materialStore';
import sizeStore from '../../../../stores/sizeStore';
import voltagemStore from '../../../../stores/voltagemStore';
import SelectComponent from '../../../atoms/SelectComponent';
import ContentBox from '../../../molecules/ContentBox';
import Permission from '../../../molecules/Permission';



const VariationSelect: React.FC = observer(() => {
    const { colorSelectList } = colorStore;
    const { sizeSelectList } = sizeStore;
    const { materialSelectList } = materialStore;
    const { voltageSelectList } = voltagemStore;


    return (
        <div>
            <ContentBox className="mb-4 p-6 rounded-none" title="Variação do Produto" description='Selecione a Variação do Produto'>
                <div className="w-full flex flex-col gap-2">
                    <div className="flex gap-4">
                        <Permission permissionKeys={[PermissionColor.GET]} inputType name="Cor">
                            <SelectComponent label="Cor" name="color" options={colorSelectList} />
                        </Permission>
                    </div>

                    <div className="flex gap-4">
                        <Permission permissionKeys={[PermissionVoltage.GET]} inputType name="Cor">
                            <SelectComponent label="Voltagem" name="voltage" options={voltageSelectList} />
                        </Permission>
                    </div>

                    <div className="flex gap-4">
                        <Permission permissionKeys={[PermissionMaterials.GET]} inputType name="Cor">
                            <SelectComponent label="Material" name="material" options={materialSelectList} />
                        </Permission>
                    </div>

                    <div className="flex gap-4">
                        <Permission permissionKeys={[PermissionSize.GET]} inputType name="Cor">
                            <SelectComponent label="Tamanho" name="size" options={sizeSelectList} />
                        </Permission>
                    </div>
                </div>
            </ContentBox>
        </div>
    );
});

export default VariationSelect;