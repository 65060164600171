import { Button, Drawer } from 'antd';
import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { v4 as uuidv4 } from 'uuid';
import productStore from '../../../../../stores/productStore';
import InputComponent from '../../../../atoms/InputComponet';
import SelectComponent from '../../../../atoms/SelectComponent';
import ActionButton from '../../../../atoms/ButtonComponent';
import { YesOrNotOrAll } from '../../../../../utils/selects';


export const MultipleRule = [
    { value: 'HE', label: 'Ativo' },
    { value: 'IC', label: 'Inativo' }
];


interface DrawerFilterPriceProps {
    isModalOpen?: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    product: any
}

const DrawerFilterPrice: React.FC<DrawerFilterPriceProps> = ({ isModalOpen, setIsModalOpen, product }) => {
    const { unitSelected } = productStore;

    const handleSubmit = async (values: any) => {
        productStore.updateFakePrice(values, false);
    };

    const initialValues: any = {
        name: '',
        fakePrice: { value: true, label: '' },
    };

    const handleClearAndSearch = async (resetForm: any) => {
        productStore.updateFakePrice(product, true);
        resetForm({
            fakePrice: { value: true, label: '' },
        });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
            setIsModalOpen(false);
        }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastrar de Preço"
                    width={540}
                    onClose={() => {
                        setIsModalOpen(false);
                        resetForm();
                    }}
                    open={isModalOpen}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <ActionButton actionType="outline" onClick={() => handleClearAndSearch(resetForm)}>
                                Limpar
                            </ActionButton>
                            <Button type="primary" onClick={submitForm}>
                                Filtrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex flex-col w-full gap-4 pb-4">
                            <InputComponent label="Centro" name="center" id="center" />
                            <InputComponent label="Tabela de Preço" name="tableId" id="tableId" />
                            <SelectComponent label="Mostrar Preço (False)" name="fakePrice" options={YesOrNotOrAll} />
                            <SelectComponent label="Unidade de Medida" name="unit" options={unitSelected} />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
};

export default DrawerFilterPrice;
