import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { message, Space, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, defaultparamsExport, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionInstitucional } from '../../../permissions/PermissionInstitucional';
import channelStore from '../../../stores/channelStore';
import institucionalStore from '../../../stores/institucionalStore';
import { Institucional } from '../../../types/Institucional';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    externalLink: any;
    topic: any;
    ordination: any;
    code: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}


// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Institucionais</p>,
    },
]

const ListInstitucional: React.FC = observer(() => {
    const { institucionalList, loading, page, totalElements, defaultparams, sort, size } = institucionalStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const navigate = useNavigate();
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const listRouter = () => { navigate(`/institucional/cadastro`); }
    const { hasPermission, isColumnVisible } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        name: 'name',
        code: 'code',
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionInstitucional.GET)) {
                await institucionalStore.getList(params);
            }
        };
        fetchData();
    }, []);


    const columns: TableColumnsType<DataType> = [
        {
            title: 'Ordenação',
            dataIndex: 'ordination',
            filters: getUniqueFilters(institucionalList, 'ordination'),
            onFilter: (value, record) => record.ordination.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('ordination', queryParams.sort),
            key: 'ordination',
            sorter: true,
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            filters: getUniqueFilters(institucionalList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            key: 'name',
            sorter: true,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
        },
        {
            title: 'Tópico',
            dataIndex: 'topic',
            filters: getUniqueFilters(institucionalList, 'topic'),
            onFilter: (value, record) => record.topic.indexOf(value as string) === 0,
            key: 'topic',
            sorter: true,
            defaultSortOrder: getSortOrder('topic', queryParams.sort),
        },
        {
            title: 'Link',
            dataIndex: 'externalLink',
            filters: getUniqueFilters(institucionalList, 'externalLink'),
            onFilter: (value, record) => record.externalLink.indexOf(value as string) === 0,
            key: 'externalLink',
            sorter: true,
            defaultSortOrder: getSortOrder('externalLink', queryParams.sort),
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionInstitucional.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/institucional/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionInstitucional.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    const handleSubmit = async (values: { name: string; code: string }) => {
        const newParams: any = {
            name: values.name || undefined,
            code: values.code || undefined,
        };

        const search = [
            values.name ? `name;"${values.name}"` : null,
            values.code ? `code;"${values.code}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort,
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await channelStore.getList(params);
    };


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = async () => {
        const response = await institucionalStore.deleteInstitucional(itemSelected.uuid);
        if (!response?.error) {
            await institucionalStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };

    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await institucionalStore.getList(params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort, // Use o sort da tabela se disponível
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
        });

        return params;
    };



    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };


    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => institucionalStore.deleteInstitucionalAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    message.error('Erro ao deletar um ou mais Institucionais.');
                    setSelectionCheckbox([]);
                } else {
                    message.success('Todos os Institucionais foram deletados com sucesso!');
                    await institucionalStore.getList(defaultparams);
                    setSelectionCheckbox([]);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os Institucionais.');
        }
    };

    const handleExport = async () => {
        const dataExport = await institucionalStore.getList(defaultparamsExport);

        if (dataExport) {
            const listExport = dataExport.data.result.content.map((item: Institucional) => ({
                ordination: item.ordination,
                name: item.name,
                topic: item.topic,
                externalLink: item.externalLink,
                position: item.position,
            }));

            exportToExcel(listExport, ["Ordenação", "Nome", "Tópico", "Link", 'Posição'], "Tabela de Institucionais.xlsx");
        }
    };


    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionInstitucional.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionInstitucional.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Institucional" description='Tela de listagem de Institucionais' actions={
                    <>
                        <Permission permissionKeys={[PermissionInstitucional.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={institucionalList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        onChange={onChange}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                    />
                </ContentBox>
            </Permission>

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Institucional <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListInstitucional;
