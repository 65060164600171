import { makeAutoObservable } from 'mobx';

class UserStore {
    user: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    setUser(user: string) {
        this.user = user;
    }

    clearUser() {
        this.user = null;
    }
}

const userStore = new UserStore();
export default userStore;