import { Button, Drawer, Form } from 'antd';
import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import cupomStore from '../../../../../stores/cupomStore';
import InputMaskComponent from '../../../../atoms/InputMaskComponent';
import { validationSchema } from './schema';

const DrawerZipCode: React.FC = observer(({ isModalDrawer, setIsDrawerOpen }: any) => {
    const handleSubmit = async (values: { startZipCode: string, endZipCode: string }) => {
        const updateValues = {
            ...values,
            uuid: uuidv4(),
        }

        cupomStore.updateZipCodeList(updateValues);
        setIsDrawerOpen(false);
    };

    const initialValuesForm = {
        startZipCode: '',
        endZipCode: '',
    };

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValuesForm} onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm()
        }}
            enableReinitialize>
            {({ submitForm, resetForm }) => (
                <Drawer
                    title="Cadastro de Faixa CEP"
                    width={450}
                    onClose={() => {
                        resetForm(); // Reseta o formulário ao fechar
                        setIsDrawerOpen(false);
                    }}
                    open={isModalDrawer}
                    footer={
                        <div className="flex gap-4 justify-end">
                            <Button onClick={() => setIsDrawerOpen(false)}>Cancelar</Button>
                            <Button type="primary" onClick={submitForm}> {/* Agora funciona */}
                                Cadastrar
                            </Button>
                        </div>
                    }
                >
                    <Form>
                        <div className="flex gap-4 flex-col">
                            <InputMaskComponent mask="99999-999" size="middle" label="CEP Inicio" name="startZipCode" id="startZipCode" />
                            <InputMaskComponent mask="99999-999" size="middle" label="CEP Fim" name="endZipCode" id="endZipCode" />
                        </div>
                    </Form>
                </Drawer>
            )}
        </Formik>
    );
});

export default DrawerZipCode;
