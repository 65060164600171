import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    category: Yup.object()
    .shape({
        value: Yup.string().required('Selecione uma Categoria válida'),
    })
    .nullable()
    .required('Categoria é obrigatório'),
});