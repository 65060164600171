import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class ConfigService {
    async getConfig(): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CONFIG}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async editConfig(data: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.CONFIG}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async saveFavicon(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.CONFIG}/favicon`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }


    async saveLogo(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.CONFIG}/logo`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async getMultipleRule(): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.MULTIPLE}/unitDisplayController`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async saveMultiple(data: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.SAVE_MULTIPLE}/trescoracoesconfig/${data.uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }
}

export default new ConfigService();