import ApexCharts from 'apexcharts';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { getChartData } from '../../functionDashboard';


interface LineChartsProps {
    dashboardData: any;
}


const LineCharts: React.FC<LineChartsProps> = observer(({ dashboardData }) => {
    const chartData = dashboardData && getChartData(dashboardData, "Receita Mês a Mês Ano Atual x Ano Passado");
    const chartLineRef = useRef(null);

    useEffect(() => {
        const options = {
            chart: {
                type: 'area',
                height: 350,
                renderTo: chartLineRef.current,
            },
            plotOptions: {
                bar: {
                    borderRadius: 6,
                }
            },
            series: [
                {
                    name: chartData?.series[0]?.name,
                    data: chartData ? chartData.series[0].data.map((value: any) => parseInt(value)) : [],
                },
                {
                    name: chartData?.series[1]?.name,
                    data: chartData ? chartData.series[1].data.map((value: any) => parseInt(value)) : [],
                }
            ],
            xaxis: {
                categories: chartData ? chartData.categories : [],
            },
            colors: ['#f2a73b', '#2d2d2d'],
        };

        // Cria o gráfico
        const chart = new ApexCharts(chartLineRef.current, options);
        chart.render();

        return () => {
            chart.destroy();
        };
    }, [chartData]);

    return (
        <div id="chart-line">
            <div ref={chartLineRef}></div>
        </div>
    );
});


export default LineCharts;