import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { replaceImageUrl } from '../../../../utils/functions';
import './styles.scss';


interface BranchComponentProps {
    banner?: any;
    isMobile?: boolean;
    viewDate?: boolean;
}

const BranchComponent: React.FC<BranchComponentProps> = observer(({ banner, isMobile, viewDate }) => {
    return (
        <div className="box-branch">
            {banner && banner?.files?.map((file: any, index: number) => (
                <div className="branch-card" key={index}>
                    <a target="_blank" rel="noopener noreferrer" href={file.link || ""}>
                        <img src={replaceImageUrl(file.file.path)} alt={file.name} />
                    </a>
                </div>
            ))}
        </div>
    );
});

export default BranchComponent;