import React, { ReactNode } from 'react';
import './styles.scss';

interface ContentBoxProps {
    children?: ReactNode;
    title?: string;
    description?: string;
    className?: string;
    borderCustom?: string;
    actions?: ReactNode;
}

const ContentBox: React.FC<ContentBoxProps> = ({ children, description, title, actions, className, borderCustom }) => {
    return (
        <div className={`${className} content-box ${borderCustom ? borderCustom : 'rounded-lg'} p-4 bg-white dark:bg-charcoal dark:border-gray-700`}>
            {(title || description || actions) && (
                <div className="flex justify-between items-center pb-4">
                    {(title || description) && (
                        <div className="flex justify-between items-center">
                            <div className="flex flex-col">
                                <h1 className="text-lg font-semibold text-gray-800 dark:text-gray-100">{title}</h1>
                                <p className="text-gray-500 dark:text-white text-sm">{description}</p>
                            </div>
                        </div>
                    )}

                    {actions && (
                        <div className="flex gap-4 items-center">
                            {actions}
                        </div>
                    )}
                </div>
            )}
            {children}
        </div>
    )
};

export default ContentBox;