import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import SubChannelService from '../services/SubChannelService';
import { PaginationParams } from '../types';
import { transformToValueLabel } from '../utils/functions';
import { SubChannelModel } from '../types/SubChannel';


class SubChannel {
    loading: boolean = false;
    subChannelList: SubChannelModel[] = [];
    selectSubChannelList: any[] = [];
    subChannelProps: SubChannelModel | undefined;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    reset() {
        this.subChannelProps = undefined;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await SubChannelService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.selectSubChannelList = transformToValueLabel(response.data.content, 'uuid', 'name');
                this.subChannelList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;
            }
            this.loading = false;
        });


        return response;
    }

    async createSubChannel(subChannel: SubChannelModel) {
        this.loading = true;
        const response = await SubChannelService.createSubChannel(subChannel);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao criar SubCanal`);
                this.subChannelProps = subChannel;
            } else {
                message.success('SubCanal cadastrado com sucesso!');
            }
            this.loading = false;
        });
        return response;
    }


    async editSubChannel(subChannel: SubChannelModel, uuid: string | undefined) {
        this.loading = true;
        const response = await SubChannelService.editSubChannel(subChannel, uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao editar SubCanal`);
                this.subChannelProps = subChannel;
            } else {
                message.success('SubCanal editado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }


    async getSubChannelByUuid(uuid: string) {
        this.loading = true;
        const response = await SubChannelService.getSubChannelByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao buscar SubCanal`);
                return;
            }

            this.subChannelProps = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteSubChannel(uuid: string) {
        this.loading = true;
        const response = await SubChannelService.deleteSubChannel(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar SubCanal`);
            } else {
                message.success('SubCanal deletado com sucesso!');
            }

            this.subChannelProps = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteSubChannelAll(uuid: string) {
        this.loading = true;
        const response = await SubChannelService.deleteSubChannel(uuid);
        runInAction(() => {
            this.subChannelProps = response.data;
            this.loading = false;
        });
        return response;
    }
}

const subChannel = new SubChannel();
export default subChannel;