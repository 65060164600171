import type { TableColumnsType, TableProps } from 'antd';
import { Button, Table } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../config/config';
import useQueryParams from '../../../hooks/useQueryParams';
import { PermissionReports } from '../../../permissions/PermissionReports';
import categoryStore from '../../../stores/categoryStore';
import productStore from '../../../stores/productStore';
import reportBestSellingStore from '../../../stores/reportBestSellingStore';
import { Product } from '../../../types/Product';
import { exportToExcel } from '../../../utils/excelFunction';
import { convertToDayjs, formatCurrency, formattedDates, getFirstFilterDates, getSortOrder, getUniqueFilters, roundPercentage } from '../../../utils/functions';
import AutocompleteComponent from '../../atoms/AutocompleteComponent';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import DatePickerComponet from '../../atoms/DatePickerComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { PermissionProducts } from '../../../permissions/PermissionProducts';
import { PermissionCategories } from '../../../permissions/PermissionCategories';


interface DataType {
    totalAmount: any;
    categories: any;
    customer: any;
    document: string;
    type: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    categoryName: any;
    to: string;
    from: string;
    size?: number;
    page?: number;
    labelTerm?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Relatório de Produtos mais Comprados</p>,
    },
]

const ReportMostPurchasedProduct: React.FC = observer(() => {
    const { reportList, loading, page, totalElements, sort } = reportBestSellingStore;
    const { categoryListSelect } = categoryStore;
    const [queryParams, setQueryParams] = useQueryParams();
    const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
    const [initialDate, finalDate] = getFirstFilterDates();
    const { hasPermission } = useAuth();

    const initialValues = {
        date: queryParams.from && queryParams.to ? [convertToDayjs(queryParams.from), convertToDayjs(queryParams.to)] : [convertToDayjs(initialDate), convertToDayjs(finalDate)],
        searchTerm: typeof queryParams.searchTerm === 'string'
            ? { value: queryParams.searchTerm, label: queryParams.labelTerm }
            : { value: '', label: '' },
        categoryName: typeof queryParams.categoryName === 'string'
            ? { value: queryParams.categoryName, label: queryParams.categoryName }
            : { value: '', label: '' },
    };

    useEffect(() => {
        const initialParams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            productName: queryParams.searchTerm || undefined,
            from: queryParams.from ? queryParams.from + 'T00:00:00.556' : initialDate + 'T00:00:00.556',
            to: queryParams.to ? queryParams.to + 'T00:00:00.556' : finalDate + 'T00:00:00.556',
            sort: "name",
            categoryName: queryParams.categoryName || "",
            page: queryParams.page || "0",
            size: queryParams.size || "15"
        }

        const fetchData = async () => {
            if (hasPermission(PermissionReports.BEST_SELLING_PRODUCTS)) {
                reportBestSellingStore.getList(initialParams);
            }
        };


        const fetchDataCategory = async () => {
            await categoryStore.getListCategorySelect();
        };


        if (hasPermission(PermissionCategories.GET)) {
            fetchDataCategory();
        }
        fetchData();
    }, []);


    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name', // Verifique a estrutura dos dados
            key: 'name',
            filters: getUniqueFilters(reportList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
        },
        {
            title: 'Categorias',
            dataIndex: 'categories', // Verifique a estrutura dos dados
            key: 'categories',
            filters: getUniqueFilters(reportList, 'categories'),
            onFilter: (value, record) => record.categories.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('categories', queryParams.sort),
        },
        {
            title: 'Quantidade',
            dataIndex: 'totalAmount', // Verifique a estrutura dos dados
            key: 'totalAmount',
            filters: getUniqueFilters(reportList, 'totalAmount'),
            onFilter: (value, record) => record.totalAmount.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('totalAmount', queryParams.sort),
        },
        {
            title: 'Total',
            dataIndex: 'price', // Verifique a estrutura dos dados
            key: 'price',
            render: (price) => {
                return (
                    <>{price && <p>{formatCurrency(price)}</p>}</>
                );
            },
        },
        {
            title: 'Porcentagem',
            dataIndex: 'percentage', // Verifique a estrutura dos dados
            key: 'percentage',
            render: (percentage) => {
                return (
                    <>{percentage && <p>{roundPercentage(percentage)}%</p>}</>
                );
            },
        },
    ];


    // Faz o filtro da tabela
    const handleSubmit = async (values: { searchTerm: { value: string; label: string }; date: any; categoryName: { value: string; label: string } }) => {
        const formattedDate = formattedDates(values.date, 'YYYY-MM-DD');
        const dateInitial = formattedDate[0];
        const dateFinal = formattedDate[1];

        const params = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            productName: values.searchTerm.value || queryParams.searchTerm || "",
            from: dateInitial + 'T00:00:00.556',
            to: dateFinal + 'T00:00:00.556',
            categoryName: values.categoryName.label || queryParams.categoryName || "",
            sort: queryParams.sort || sort || "name",
            page: queryParams.page || "0",
            size: queryParams.size || DEFAULT_PAGE_SIZE
        }

        const newParams: any = {
            searchTerm: values.searchTerm.value || undefined,
            labelTerm: values.searchTerm.label || undefined,
            categoryName: values.categoryName.label || undefined,
            to: dateFinal || undefined,
            from: dateInitial || undefined,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await reportBestSellingStore.getList(params);
    };


    // Limpa os dados do formulario
    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL
        setAutocompleteOptions([]);

        const clearparams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            "productName": '',
            "from": initialDate + 'T00:00:00.556',
            "to": finalDate + 'T00:00:00.556',
            "categoryName": "",
            "sort": "created",
            "page": "0",
            "size": DEFAULT_PAGE_SIZE
        }


        await reportBestSellingStore.getList(clearparams);
    };


    const handleSearch = async (searchText: string) => {
        if (searchText.length > 3) {
            try {
                const response = await productStore.searchProduct(searchText);
                setAutocompleteOptions(response);
                // Aqui você pode mapear a resposta para opções, se necessário
            } catch (error) {
                console.error('Erro ao buscar cliente:', error);
            }
        }
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        //@ts-ignore
        const params = buildParams(pagination, queryParams);
        await reportBestSellingStore.getList(params);
    };

    const buildParams = (
        pagination: any,
        queryParams: QueryParams
    ) => {
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            "productName": queryParams.labelTerm || undefined,
            "from": queryParams.from + 'T00:00:00.556',
            "categoryName": queryParams.categoryName,
            "to": queryParams.to + 'T00:00:00.556',
            "sort": "created"
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            productName: params.productName,
            from: queryParams.from,
            to: queryParams.to,
            sort: params.sort,
        });

        return params;
    };

    const handleExport = async () => {
        const defaultParams = {
            "@class": "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
            "productName": queryParams.labelTerm || undefined,
            "categoryName": queryParams.categoryName,
            from: queryParams.from ? queryParams.from + 'T00:00:00.556' : initialDate + 'T00:00:00.556',
            to: queryParams.to ? queryParams.to + 'T00:00:00.556' : finalDate + 'T00:00:00.556',
            size: 9999,
            page: 0,
            sort: "created"
        };
        const dataExport = await reportBestSellingStore.getList(defaultParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: Product) => ({
                name: item.name,
                sku: item?.skuCode,
                categories: item.categories,
                amount: item.totalAmount,
                total: formatCurrency(item.price),
                percentage: `${roundPercentage(item.percentage ?? 0)}%`,
            }));

            exportToExcel(listExport, ["Nome", "SKU", "Categoria", "Quantidade", "Total", "Porcentagem"], "Tabela de Relatório de produtos mais comprados.xlsx");
        }
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} />

            <Permission permissionKeys={[PermissionReports.BEST_SELLING_CATEGORIES]} fallback={NotPermissionComponent()}>
                <ContentBox className="mb-4 p-6" title="Relatório de produtos mais comprados" description='Informações sobre produtos mais comprados'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <Permission permissionKeys={[PermissionProducts.GET]} inputType name="Buscar-Produtos">
                                            <AutocompleteComponent
                                                label="Buscar Produtos"
                                                name="searchTerm"
                                                placeholder="Digite o nome do Produto"
                                                //@ts-ignore
                                                fetchOptions={handleSearch}
                                                options={autocompleteOptions}
                                            />
                                        </Permission>
                                    </div>
                                    <div className="w-full">
                                        <Permission permissionKeys={[PermissionCategories.GET]} inputType name="SubCanal">
                                            <SelectComponent label="Categoria" name="categoryName" options={categoryListSelect} />
                                        </Permission>
                                    </div>
                                    <div className="w-full">
                                        <DatePickerComponet label="Data inicial" name="date" />
                                    </div>
                                </div>

                                <div className="flex items-end gap-4 justify-end ">
                                    <Button onClick={() => handleClearAndSearch(resetForm)}>
                                        Limpar
                                    </Button>
                                    <Button htmlType="submit" type="primary">Filtrar</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6">
                    <div className="flex items-end w-full gap-4 pb-4 items-center justify-between">
                        <p>Relatório de produtos mais comprados</p>

                        <div className="flex gap-4">
                            <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                        </div>
                    </div>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={reportList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        onChange={onChange}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: DEFAULT_PAGE_SIZE,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>
        </>
    );
});

export default ReportMostPurchasedProduct;