import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import OrderApi from '../services/OrderApi';
import { PaginationParams } from '../types';
import { message } from 'antd';


class OrderStore {
    loading: boolean = false;
    orderList: any = [];
    orderCustomerList: any = [];
    sort: string = '';
    order: any = [];
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'idOrder,desc',
        search: 'status_statusName!CART',
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await OrderApi.getList(params);
        if (!response.error) {
            this.orderList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        } else {
            message.error(`Erro ao buscar Listagem`);
        }
        this.loading = false;
        return response;
    }


    async getListByCustomer(uuid: any, params: any) {
        this.loading = true;
        const response = await OrderApi.getListByCustomer(uuid, params);
        if (!response.data.error) {
            this.orderCustomerList = response.data.content;
            this.totalPages = response.data.totalPages;
            this.page = response.data.number;
            this.totalElements = response.data.totalElements;
            this.size = response.data.size;
        } else {
            message.error(`Erro ao buscar Listagem`);
        }
        this.loading = false;
        return response;
    }


    async getOrder(uuidOrder: any, uuidCustomer: any) {
        this.loading = true;
        const response = await OrderApi.getOrder(uuidOrder, uuidCustomer);

        if (response.error) {
            message.error(`Erro ao buscar Pedido`);
            return;
        }

        this.order = response.data
        this.loading = false;
        return response;
    }
}

const orderStore = new OrderStore();
export default orderStore;