import { observer } from 'mobx-react-lite';
import React from 'react';
import ColorPickerComponent from '../../../atoms/ColorPickerComponent';
import ImageUploadComponent from '../../../atoms/UploadComponent';
import ContentBox from '../../../molecules/ContentBox';


const HeaderConfiguration: React.FC = observer(() => {
    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Cores do Header" description='Cadastro das Cores do Header'>
                <div className="w-full flex flex-col gap-4">
                    <div className="flex gap-4">
                        <ColorPickerComponent label="Background Header*" name="colorHeaderBackground" id="colorHeaderBackground" />
                        <ColorPickerComponent label="Cor da Fonte*" name="colorHeaderFont" id="colorHeaderFont" />
                    </div>
                </div>
            </ContentBox>

            <ContentBox className="mb-4 p-6" title="Imagens de Cabeçalho" description='Cadastro de imagens de Logo e Cabeçalho HTML'>
                <div className="flex gap-4">
                    <ImageUploadComponent maxCount={1} name="logo" label="Selecione a Imagem do Logo" />
                    <ImageUploadComponent maxCount={1} name="favicon" label="Selecione a Imagem do Favicon" />
                </div>
            </ContentBox>
        </>
    );
});

export default HeaderConfiguration;