import { observer } from 'mobx-react-lite';
import React from 'react';
import { replaceImageUrl } from '../../../../utils/functions';


interface ExampleProps {
    banner?: any;
}

const BannerFull: React.FC<ExampleProps> = observer(({ banner }) => {
    return (
        <div>
            {banner && banner?.files?.map((file: any, index: number) => (
                <div rel="noopener" key={index} >
                    <img src={replaceImageUrl(file?.file?.path)} alt="Imagem logo" />
                </div>
            ))}
        </div>
    );
});

export default BannerFull;