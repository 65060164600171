import React from 'react';
import { Modal } from 'antd';

interface ModalComponentProps {
    content?: React.ReactNode;
    title?: string;
    isModalOpen: boolean;
    handleCancel: () => void;
    handleOk: () => void;
}

const ModalComponent: React.FC<ModalComponentProps> = ({ isModalOpen, title, handleCancel, content, handleOk }) => {


    return (
        <Modal title={title} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {content}
        </Modal>
    );
};

export default ModalComponent;