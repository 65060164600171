const darkTheme = {
    token: {
        colorPrimary: '#1f2224',
        colorBgBase: '#141414',
        colorTextBase: '#ffffff',
        colorBorder: '#434343',
        colorBgContainer: '#1f1f1f',
        colorTextSecondary: '#bfbfbf',
        background: '#131516',
        colorActive: '#fff',
    },
};

export default darkTheme;