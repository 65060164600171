import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionStamps } from '../../../permissions/PermissionStamps';
import selosStore from '../../../stores/selosStore';
import { Stamp } from '../../../types/Selos';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import InputNumberComponet from '../../atoms/InputNumberComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';


const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/selos">Listagem de Selos</a>,
    },
    {
        title: <p>Cadastro de Selos</p>,
    },
]

const CreateSelos: React.FC = observer(() => {
    const { loading } = selosStore;
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            selosStore.reset();
        };
    }, []);

    const initialValues: Stamp = {
        name: '',
        link: '',
        ordination: '',
        domain: '',
        script: '',
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: Stamp) => {
        const response = await selosStore.createSelo(values);
        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/selos`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionStamps.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de Selo" description='Tela para cadastro de Selos'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionStamps.POST, PermissionStamps.PUT]}
                    fallback={<PermissionComponent />}
                >

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Cadastro de Selo" description='Tela para cadastro de Selos'>
                                <div className="flex w-full gap-4 pb-4">
                                    <InputComponet label="Nome" name="name" id="name" />
                                    <InputNumberComponet min={1} max={999} label="Ordenação" name="ordination" />
                                </div>

                                <div className="flex w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <InputComponet label="Link" name="link" id="link" />
                                    </div>
                                </div>

                                <div className="flex w-full gap-4 pb-4">
                                    <div className="w-full">
                                        <InputComponet label="Script" name="script" id="script" />
                                    </div>
                                </div>
                            </ContentBox>

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionStamps.PUT, PermissionStamps.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">Cadastrar</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateSelos;