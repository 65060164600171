import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, message, Space, Table, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionChannel } from '../../../permissions/PermissionChannel';
import componentsStore from '../../../stores/componentsStore';
import { formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import { getStatusColor } from '../../../utils/statusColorMapper';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    type: any;
    active: boolean | React.Key;
    code: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface QueryParams {
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}


// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Componentes</p>,
    },
]

const ListComponents: React.FC = observer(() => {
    const { componentsList, loading, page, totalElements, defaultparams, sort, size } = componentsStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const navigate = useNavigate();
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const listRouter = () => { navigate(`/componentes/cadastro`); }
    const { hasPermission, isColumnVisible } = useAuth();

    const initialValues = {
        name: queryParams.name || '',
        type: queryParams.type || '',
    };

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        name: 'name',
        type: 'type',
    };

    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionChannel.GET)) {
                await componentsStore.getList(params);
            }
        };
        fetchData();
    }, []);


    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'name',
            filters: getUniqueFilters(componentsList, 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('name', queryParams.sort),
            key: 'name',
            sorter: true,
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            filters: getUniqueFilters(componentsList, 'type'),
            onFilter: (value, record) => record.type.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('type', queryParams.sort),
            key: 'type',
            sorter: true,
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            render: (text) => <p>{formatDateString(text)}</p>,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
        },
        {
            title: 'Ativo',
            dataIndex: 'active',
            key: 'active',
            sorter: true,
            filters: [
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            render: (active) => {
                let text = active === true ? 'Ativo' : 'Inativo';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionChannel.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/componentes/editar/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionChannel.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    const handleSubmit = async (values: { name: string; type: string }) => {
        const newParams: any = {
            name: values.name || undefined,
            type: values.type || undefined,
        };

        const search = [
            values.name ? `name;"${values.name}"` : null,
            values.type ? `type;"${values.type}"` : null,
        ]
            .filter(Boolean)
            .join(' ');

        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sort,
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await componentsStore.getList(params);
    };

    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm(); // Limpa os valores do formulário
        setQueryParams({}); // Limpa a URL
        await componentsStore.getList(defaultparams);
    };

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = async () => {
        const response = await componentsStore.deleteComponente(itemSelected.uuid);
        if (!response?.error) {
            await componentsStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await componentsStore.getList(params);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = buildParamsFromQuery(queryParams, mappings);

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || sort,
            search: filters.search || queryParams.search || '',
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };


    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => componentsStore.deleteCompoentesAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    setSelectionCheckbox([]);
                    message.error('Erro ao deletar um ou mais Componentes.');
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos os Componentes foram deletados com sucesso!');
                    await componentsStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os Componentes.');
        }
    };

    const handleExport = async () => {
        const params = buildParamsFromQuery(queryParams, mappings);
        const exportParams = {
            ...params,
            size: 999999,
        }

        const dataExport = await componentsStore.getList(exportParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: any) => ({
                name: item.name,
                type: item.type,
                created: formatDateString(item.created ?? ""),
                active: item?.active ? "Sim" : "Não",
            }));

            exportToExcel(listExport, ["Código", "Tipo", "Data de Criação", "Ativo"], "Tabela de Componentes.xlsx");
        }
    };

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionChannel.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            <Permission permissionKeys={[PermissionChannel.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Componentes" description='Tela de listagem de Componentes'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4">
                                    <div className="w-full">
                                        <InputComponet label="Nome" name="name" id="name" />
                                    </div>
                                    <div className="w-full">
                                        <InputComponet label="Tipo" name="type" id="type" />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>
                <ContentBox className="mb-4 p-6" description='Listagem de Componentes' actions={
                    <>
                        <Permission permissionKeys={[PermissionChannel.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>

                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={componentsList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        loading={loading}
                        onChange={onChange}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                    />
                </ContentBox>
            </Permission>
            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Componente <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListComponents;
