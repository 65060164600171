import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { MutableRefObject, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ContentBox from '../../../../molecules/ContentBox';
import { getChartData } from '../../functionDashboard';


interface BarChartsProps {
    dashboardData: any;
    loading: boolean;
    ref4: MutableRefObject<null>;
}

const BarCharts: React.FC<BarChartsProps> = observer(({ dashboardData, loading, ref4 }) => {
    const [chartsTitle, setChartsTitle] = useState('Faturamento Mensal');
    const chartData = dashboardData && getChartData(dashboardData, "Quantidade de Vendas (Itens)");
    const dailyChart = dashboardData && getChartData(dashboardData, "Faturamento Diário", true);

    const configChartsOptions1: any = {
        options: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            colors: ['#f2a73b'],  // Cor das barras do gráfico
            plotOptions: {
                bar: {
                    borderRadius: 6,
                }
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: chartData ? chartData?.categories : [],
                position: 'bottom',
                axisBorder: {
                    show: true,
                    color: '#e0e0e0' // Cor da borda do eixo X
                },
                axisTicks: {
                    show: true,
                    color: '#e0e0e0' // Cor dos ticks do eixo X
                },
                labels: {
                    style: {
                        colors: '#CACACA', // Cor das labels do eixo X
                        fontSize: '12px', // Tamanho da fonte
                    }
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#002F8B',
                            colorTo: '#002F8B',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false,
                    color: '#e0e0e0' // Cor da borda do eixo Y
                },
                axisTicks: {
                    show: false,
                    color: '#e0e0e0' // Cor dos ticks do eixo Y
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#CACACA', // Cor das labels do eixo Y
                        fontSize: '12px', // Tamanho da fonte
                    },
                }
            },
        },
    };

    const dataOption1: any = {
        series: [
            {
                name: 'Itens Ano Atual',
                data: chartData ? chartData?.series[0]?.data.map((value: any) => value) : [],
            },
            {
                name: 'Itens Ano Passado',
                data: chartData ? chartData?.series[1]?.data.map((value: any) => value) : [],
            }
        ],
    };

    const configChartsOptions2: any = {
        options: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false,
                },
            },
            colors: ['#f2a73b', '#2d2d2d'],  // Cor das barras do gráfico
            plotOptions: {
                bar: {
                    borderRadius: 6,
                }
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: dailyChart ? dailyChart?.categories : [],
                position: 'bottom',
                axisBorder: {
                    show: true,
                    color: '#e0e0e0' // Cor da borda do eixo X
                },
                axisTicks: {
                    show: true,
                    color: '#e0e0e0' // Cor dos ticks do eixo X
                },
                labels: {
                    style: {
                        colors: '#CACACA', // Cor das labels do eixo X
                        fontSize: '12px', // Tamanho da fonte
                    }
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#002F8B',
                            colorTo: '#002F8B',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false,
                    color: '#e0e0e0' // Cor da borda do eixo Y
                },
                axisTicks: {
                    show: false,
                    color: '#e0e0e0' // Cor dos ticks do eixo Y
                },
                labels: {
                    show: true,
                    style: {
                        colors: '#CACACA', // Cor das labels do eixo Y
                        fontSize: '12px', // Tamanho da fonte
                    },
                }
            },
        },
    };

    const dataOption2: any = {
        series: [
            {
                name: 'Receita Dia a Dia Mês Atual',
                data: dailyChart ? dailyChart?.series[0]?.data.map((value: any) => parseInt(value)) : [],
            },
        ],
    };

    const [configCharts, setConfigCharts] = useState(configChartsOptions1);
    const [dataCharts, setDataCharts] = useState(dataOption1);


    useEffect(() => {
        setDataCharts(dataOption1);
        setConfigCharts(configChartsOptions1);
    }, [dashboardData])

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <p onClick={() => {
                    setConfigCharts(configChartsOptions1);
                    setDataCharts(dataOption1);
                    setChartsTitle('Faturamento Mensal')
                }
                }>Faturamento Mensal</p>
            ),
        },
        {
            key: '2',
            label: (
                <p onClick={() => {
                    setConfigCharts(configChartsOptions2);
                    setDataCharts(dataOption2);
                    setChartsTitle('Faturamento Diario')
                }
                }>Faturamento Diario</p>
            ),
        },
    ];

    return (
        <ContentBox className="mb-0 p-6" title={chartsTitle} actions={
            <Dropdown className="cursor-pointer" placement="bottomRight" menu={{ items }}>
                <div ref={ref4} onClick={(e) => e.preventDefault()}>
                    <MenuOutlined className="dark:text-white" />
                </div>
            </Dropdown>
        }>

            <ReactApexChart options={configCharts.options} series={dataCharts.series} type="bar" height={300} />
        </ContentBox>
    );
});


export default BarCharts;