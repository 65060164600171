export const getActionLog = (action: string): string => {
    switch (action) {
        case 'DEL':
            return 'Deletar';
        case 'UPDATE':
            return 'Atualização';
        case 'MOD':
            return 'Atualização';
        case 'ADD':
            return 'Criação';
        case 'CREATE':
            return 'Criação';
        default:
            return '';
    }
};


export const getActionColor = (action: string): string => {
    switch (action) {
        case 'DEL':
            return 'red';
        case 'UPDATE':
            return 'blue';
        case 'MOD':
            return 'blue';
        case 'CREATE':
            return 'green';
        case 'ADD':
            return 'green';
        default:
            return '';
    }
};


export const compareValues = (data: any) => {
    if (data !== null) {
        const { oldValue, newValue } = data;

        // Parse os valores de JSON para objetos
        const oldObject = oldValue ? JSON.parse(oldValue) : {};
        const newObject = JSON.parse(newValue);

        // Array para armazenar os resultados
        const differences = [];

        // Cria um conjunto único de chaves (de oldObject e newObject)
        const allKeys = new Set([...Object.keys(oldObject), ...Object.keys(newObject)]);

        // Itera pelas chaves únicas
        for (const key of allKeys) {
            const oldVal = oldObject[key];
            const newVal = newObject[key];

            // Adiciona as diferenças ou apenas os novos valores
            differences.push({
                field: key,
                oldValue: oldVal || null, // Valor antigo (null se não existir)
                newValue: newVal || null, // Valor novo (null se não existir)
                changed: oldVal !== newVal // Verifica se houve mudança
            });
        }

        return differences;
    }

    return [];
};