import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import './styles.scss';
import React from "react";

type ProductCarrouselProps = {
  loading?: boolean;
};

const SkeletonProduct = ({ loading }: ProductCarrouselProps) => {
  return (
    <>
      <div className="skeleton-container">
        {!loading && (
          <SkeletonTheme baseColor="#f1eff1" highlightColor="#fff">
            <div className="skeleton-image">
              <Skeleton count={1} />
            </div>

            <div className="skeleton-container">
              <div className="skeleton-description">
                <Skeleton count={1} />
              </div>
              <div className="skeleton-price">
                <Skeleton count={1} />
              </div>
              <div>
                <Skeleton count={1} />
              </div>
            </div>
          </SkeletonTheme>
        )}
      </div>
    </>
  );
};

export default SkeletonProduct;
