import React from 'react';
import ContentBox from '../../molecules/ContentBox';

interface PermissionComponentProps {
    label?: string;
    className?: string;
}

const PermissionComponent: React.FC<PermissionComponentProps> = ({
    label,
    className,
}) => {
    return (
        <ContentBox className={className ? className : "flex p-6 text-center items-center justify-center"}>
            <p>{label ? label : 'O usuário não possui permissão para acessar este conteúdo.'}</p>
        </ContentBox>
    );
};

export default PermissionComponent;
