import URLS from '../config/urls';
import { Size } from '../types/Size';
import BaseAPI from './BaseApi';


class SizeService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SIZES}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createSize(size: Size): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.SIZES}`, size);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async editSize(size: Size, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.SIZES}/${uuid}`, size);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async getSizeByUuid(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SIZES}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async deleteSize(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.SIZES}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new SizeService();