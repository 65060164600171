import { message, Table, TableColumnsType, Tag } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { utils, writeFile } from 'xlsx';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import shipppingStore from '../../../stores/shipppingStore';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import FileUpload from '../../atoms/FileUploadComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import { useAuth } from '../../protected/ProviderAuth';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import { PermissionShipping } from '../../../permissions/PermissionShipping';
import Permission from '../../molecules/Permission';
import PermissionComponent from '../../atoms/PermissionComponent';

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Upload de Fretes</p>,
    },
]

interface DataType {
    active: boolean;
    hexColorCode: string;
    shortName: string;
    name: string;
    uuid: string;
    key: React.Key;
}

const CreateShippingUpload: React.FC = observer(() => {
    const [loading, setLoading] = useState<boolean>(false);
    const [shippingData, setShippingData] = useState([]);
    const navigate = useNavigate();
    const listRouter = () => { navigate(`/fretes`); }
    const { hasPermission } = useAuth();

    const initialValues = {
    };

    // Faz o filtro da tabela
    const handleSubmit = async () => {
        const updatedShippingData = [...shippingData];

        await Promise.all(
            updatedShippingData.map(async (item: any, index) => {
                const response = await shipppingStore.uploadCreateShipping(item);

                if (response.error) {
                    //@ts-ignore
                    updatedShippingData[index].status = 'Falha';
                } else {
                    //@ts-ignore
                    updatedShippingData[index].status = 'Sucesso';
                }
            })
        );

        const findSucess = updatedShippingData.find((item: any) => item.status === 'Sucesso');

        if (findSucess === undefined) {
            message.error(`Erro ao importar Frete`);
        } else {
            const findError = updatedShippingData.find((item: any) => item.status === 'Falha');

            if (findError === undefined) {
                message.success(`Importação realizada com sucesso`);
            } else {
                message.warning(`Importação realizada de forma parcial`);
            }
        }

        setShippingData(updatedShippingData);
    };


    const handleFileProcessed = async (data: any) => {
        setLoading(true);

        const updateShipping = data.map((item: any) => ({
            status: "Pendente",
            shippingCompany: item['EMPRESA'],
            hour: item['HORA DO CORTE'],
            orgVendas: item['ORG VENDAS'],
            regVendas: item['REGIÃO VENDAS'],
            sla: item['SLA REGIÃO'],
            slaView: item['SLA REGIÃO'],
        }));

        setShippingData(updateShipping);
        setLoading(false);
    };


    const columns: TableColumnsType<DataType> = [
        {
            title: 'Empresa',
            dataIndex: 'shippingCompany',
            key: 'shippingCompany',
            sorter: false,
        },
        {
            title: 'Hora do corte',
            dataIndex: 'hour',
            key: 'hour',
            sorter: false,
        },
        {
            title: 'Org Vendas',
            dataIndex: 'orgVendas',
            key: 'orgVendas',
            sorter: false,
        },
        {
            title: 'Região Vendas',
            dataIndex: 'regVendas',
            key: 'regVendas',
            sorter: false,
        },
        {
            title: 'Sla Região',
            dataIndex: 'sla',
            key: 'sla',
            sorter: false,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: false,
            render: (item) => {
                let color;
                switch (item) {
                    case 'Falha':
                        color = 'red';
                        break;
                    case 'Sucesso':
                        color = 'green';
                        break;
                    default: // 'Pendente' ou qualquer outro status
                        color = 'orange';
                        break;
                }
                return (
                    <Tag color={color}>{item}</Tag>
                );
            },
        },
    ];


    const handleExport = () => {
        const headings = [
            ['EMPRESA', 'ORG VENDAS', 'REGIÃO VENDAS', 'HORA DO CORTE', 'SLA REGIÃO'],
        ];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(
            ws,
            [
                {
                    shippingCompany: 'STAC',
                    orgVendas: 'BEL - BELÉM',
                    regVendas: 'CAP - CAPITAL',
                    corte: '17:30:00',
                    sla: '3',
                },
            ],
            { origin: 'A2', skipHeader: true }
        );
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Shipping.xlsx');
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Importação de Fretes" description='Tela para importação de Tabela Frete'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission
                    permissionKeys={[PermissionShipping.POST, PermissionShipping.GET]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Importação de Fretes" description='Tela para importação de Tabela Frete'>
                                <div className="flex pt-3 gap-4 items-end">
                                    <div className="w-full">
                                        <FileUpload title="Selecione o arquivo" onFileProcessed={handleFileProcessed} requiredColumns={['EMPRESA', 'ORG VENDAS', 'REGIÃO VENDAS', 'HORA DO CORTE', 'SLA REGIÃO']} />
                                    </div>
                                    <ActionButton icon actionType="export" onClick={() => handleExport()}>Baixar Planilha Modelo</ActionButton>
                                </div>
                            </ContentBox>

                            <ContentBox className="mb-4 p-6" title="Tabela de Importação de Fretes" description='Listagem de Importação de Fretes'>

                                <Table
                                    className="table-custom"
                                    columns={columns}
                                    dataSource={shippingData.map((item: any, index: number) => ({
                                        ...item,
                                        key: item.uuid || index,
                                    }))}
                                    loading={loading}
                                    size="middle"
                                    bordered={false}
                                    pagination={false}
                                    showSorterTooltip={{ target: 'sorter-icon' }}
                                />
                            </ContentBox >

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionShipping.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">Processar</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik >
                </Permission >
            }
        </>
    );
});

export default CreateShippingUpload;