import { DeleteOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import productStore from '../../../../stores/productStore';
import { formatCurrency, getUniqueFilters } from '../../../../utils/functions';
import { getStatusColor } from '../../../../utils/statusColorMapper';
import ActionButton from '../../../atoms/ButtonComponent';
import NotificationUnavailable from '../../../atoms/NotificationUnavailable';
import ModalComponent from '../../../organisms/ModalComponent';
import DrawerFilterPrice from './DrawerFilterPrice';
import DrawerPrice from './DrawerPrice';


interface DataType {
    active: boolean | React.Key;
    finalAmount: any;
    initialAmount: any;
    price: any;
    minimumPrice: any;
    nameMeasurement: any;
    tableId: any;
    center: any;
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

interface TablePriceProps {
    priceList?: any;
    modalProduct?: boolean;
    unitList?: any;
}

const TablePrice: React.FC<TablePriceProps> = observer(({ priceList, modalProduct, unitList }) => {
    const { product, loading, unitSelected } = productStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Centro',
            dataIndex: 'center',
            key: 'center',
            filters: getUniqueFilters(product?.prices, 'center'),
            onFilter: (value, record) => record.center.indexOf(value as string) === 0,
        },
        {
            title: 'Tabela de Preço',
            dataIndex: 'tableId',
            key: 'tableId',
            filters: getUniqueFilters(product?.prices, 'tableId'),
            onFilter: (value, record) => record.tableId.indexOf(value as string) === 0,
        },
        {
            title: 'Unidade',
            dataIndex: 'nameMeasurement',
            key: 'nameMeasurement',
            filters: getUniqueFilters(product?.prices, 'nameMeasurement'),
            onFilter: (value, record) => record.nameMeasurement.indexOf(value as string) === 0,
        },
        {
            title: 'Preço Minimo',
            dataIndex: 'minimumPrice',
            key: 'minimumPrice',
            render: (minimumPrice) => {
                return (
                    <><p>{formatCurrency(minimumPrice)}</p></>
                );
            },
        },
        {
            title: 'Preço Máximo',
            dataIndex: 'price',
            key: 'price',
            render: (price) => {
                return (
                    <><p>{formatCurrency(price)}</p></>
                );
            },
        },
        {
            title: 'Quantidade Inicial',
            dataIndex: 'initialAmount',
            key: 'initialAmount',
        },
        {
            title: 'Quantidade Final',
            dataIndex: 'finalAmount',
            key: 'finalAmount',
        },
        {
            title: 'Visivel',
            dataIndex: 'show',
            key: 'show',
            sorter: true,
            hidden: modalProduct,
            filters: [
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
            ],
            onFilter: (value, record) => record.active === value,
            render: (active) => {
                let text = active ? 'Sim' : 'Não';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            hidden: modalProduct,
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        productStore.removePrice(itemSelected.uuid);
        setIsModalOpen(false);
    };

    const initialValues: any = {
        name: '',
        fakePrice: { value: true, label: '' },
    };

    const handleSubmit = async (values: any) => {
        productStore.updateFakePrice(values, false);
    };

    const handleClearAndSearch = async (resetForm: any) => {
        productStore.updateFakePrice(product, true);
        resetForm({
            fakePrice: { value: true, label: '' },
        });
    };

    return (
        <div>
            {!modalProduct && unitList.length > 0 &&
                <div className="flex justify-between items-center mb-4">
                    <div className="flex flex-col">
                        <h1 className="text-lg font-semibold">Cadastro de Preço</h1>
                        <p className="text-gray-500 text-sm">Tela para cadastro de Preço</p>
                    </div>

                    {product?.unitOfMeasurementList.length > 0 &&
                        <div className="flex gap-2">
                            <ActionButton icon actionType="filter" onClick={() => setOpenModalFilter(true)}>Filtrar</ActionButton>
                            <ActionButton actionType="create" onClick={() => setOpenModalRegister(true)}>Cadastrar</ActionButton>
                        </div>
                    }
                </div>
            }

            {!modalProduct && unitList.length > 0 &&
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={priceList.filter((item: any) => item.deleted === false).map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            }

            {modalProduct &&
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={priceList.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            }


            {!modalProduct && unitList.length === 0 &&
                <NotificationUnavailable title="Para adicionar preços ao produto, é necessário que você faça o cadastro de uma Unidade primeiro." />
            }

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Preço <b>{itemSelected?.center + '/' + itemSelected?.nameMeasurement}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {openModalRegister && <DrawerPrice isModalOpen={openModalRegister} setIsModalOpen={setOpenModalRegister} />}
            {openModalFilter && <DrawerFilterPrice product={product} isModalOpen={openModalFilter} setIsModalOpen={setOpenModalFilter} />}
        </div>
    );
});

export default TablePrice;