import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class CampaignService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CAMPAIGNS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async getCampaingByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CAMPAIGNS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async createCampaing(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.CAMPAIGNS}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editCampaing(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.CAMPAIGNS}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async deleteCampaing(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.CAMPAIGNS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async getReports(params: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.REPORT_CAMPAIGNS}`, params);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }


    async searchProduct(name: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.SEARCH_PRODUCT}/search/ALL?page=0&size=20&sort=created,desc&search=,skuCode;${name}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }
}

export default new CampaignService();