import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class CategoryService {
    async getList(): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CATEGORIES}/tree?size=9999`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createCategory(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.CATEGORIES}`, data);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async editCategory(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.CATEGORIES}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async getCategoryByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CATEGORIES}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async deleteCategory(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.CATEGORIES}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }


    async saveCategoryImage(data: any, categoryUuid: string): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.FILES}/categories/${categoryUuid}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }


    async deleteCategoryImage(imageUuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.FILES}/${imageUuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new CategoryService();