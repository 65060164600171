import type { TableColumnsType } from 'antd';
import { Divider, Image, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { PermissionOrder } from '../../../../../permissions/PermissionOrder';
import orderStore from '../../../../../stores/orderStore';
import { formatCurrency, valueStatusConvert } from '../../../../../utils/functions';
import NotPermissionComponent from '../../../../atoms/NotPermissionComponent';
import ContentBox from '../../../../molecules/ContentBox';
import Permission from '../../../../molecules/Permission';
import StatusRuleComponent from '../StatusRuleComponent';
import { orderStatusRules } from '../../../../../utils/selects';
import PermissionComponent from '../../../../atoms/PermissionComponent';
import LoadingComponent from '../../../../molecules/LoadingComponent';




interface DataType {
    baseQty: any;
    amount: any;
    total: any;
    name: any;
    skuCode: any;
    description: any;
    code: any;
    uuid: any;
    key: React.Key;
}

interface DescriptionItemProps {
    title: string;
    content: string;
    className?: string;
}

const DescriptionItem = ({ title, content, className }: DescriptionItemProps) => (
    <div title={content} className={`${className} flex gap-2 pb-2`}>
        <p className="text-sm inline-block text-gray-500 font-normal">{title}:</p>
        <p className="text-sm text-zinc-700 font-medium">{content}</p>
    </div>
);

const OrderDetailsPayment: React.FC = observer(({ orderStatus }: any) => {
    const { order, loading } = orderStore;

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Imagem',
            dataIndex: 'product',
            key: 'product.files',
            render: (item) => {
                return (
                    <Image
                        width={50}
                        //@ts-ignore
                        src={item?.files.length > 0 && item?.files[0]?.path}
                    />
                );
            },
        },
        {
            title: 'SKU',
            dataIndex: 'product',
            key: 'product.skuCode',
            render: (item) => {
                return (
                    <>{item && <p>{item.skuCode}</p>}</>
                );
            },
        },
        {
            title: 'Nome',
            dataIndex: 'product',
            key: 'product.name',
            render: (item) => {
                return (
                    <>{item && <p>{item.name}</p>}</>
                );
            },
        },
        {
            title: 'Preço Unitario',
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            render: (item) => {
                return (
                    <>{item && <p>{formatCurrency(item)}</p>}</>
                );
            },
        },
        {
            title: 'Quantidade',
            dataIndex: 'amount',
            key: 'amount',
            render: (item, record) => {
                return (
                    <>{record && <p>{record.baseQty * record.amount}</p>}</>
                );
            },
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (item) => {
                return (
                    <>{item && <p>{formatCurrency(item)}</p>}</>
                );
            },
        },
    ];

    return (
        <Permission permissionKeys={[PermissionOrder.GET]} fallback={<PermissionComponent />}>
            <ContentBox borderCustom='rounded-b-lg' title="Informações do Pedido" description='Informações do Pedido' className="mb-4 p-6">
                <div className="flex gap-4">
                    <div className="w-full">
                        {!loading ?
                            <StatusRuleComponent
                                orderStatus={orderStatusRules}
                                statusChanges={orderStatus}
                                paymentOptionName={order?.paymentDetails?.paymentOptionName}
                                order={order}
                            /> : <LoadingComponent />
                        }

                        <Divider />

                        <p className="pb-4 text-base text-primary-color">Informação do Pedido</p>

                        <div className="grid grid-cols-2 gap-8">
                            <DescriptionItem title="Numero" content={order?.idOrder} />
                            <DescriptionItem title="SAP Número" content={order?.externalId} />
                        </div>

                        <div className="grid grid-cols-2 gap-8" >
                            <DescriptionItem title="Número Interno ERP" content={order?.orderInternalId} />
                            <DescriptionItem title="Data criação" content={order?.created} />
                        </div>

                        <div className="grid grid-cols-2 gap-8" >
                            <DescriptionItem title="Status" content={valueStatusConvert(order?.status?.statusName)} />
                            <DescriptionItem title="IP" content={order?.clientIpAddress} />
                        </div>

                        <Divider />

                        <p className="pb-3 text-base text-primary-color">Dados de Pagamento</p>

                        <div className="grid grid-cols-2 gap-8" >
                            <DescriptionItem title="Pagamento" content={order?.paymentDetails?.paymentType} />
                            <DescriptionItem title="Total" content={formatCurrency(order?.total)} />
                        </div>

                        <div className="grid grid-cols-2 gap-8" >
                            <DescriptionItem title="Numero de parcelas" content={order?.paymentDetails?.installments} />
                            <DescriptionItem title="Valor da parcela" content={formatCurrency(order?.paymentDetails?.installmentValue)} />
                        </div>

                        <div className="grid grid-cols-2 gap-8" >
                            <DescriptionItem title="Desconto de cupom" content={formatCurrency(order?.voucherDiscounts)} />
                            <DescriptionItem title="Desconto de campanha" content={formatCurrency(order?.campaignDiscounts)} />
                        </div>

                        <div className="grid grid-cols-2 gap-8" >
                            <DescriptionItem title="SubTotal" content={formatCurrency(order?.totalValue)} />
                            <DescriptionItem title="Total Faturado" content={formatCurrency(order?.totalBilled)} />
                        </div>

                        <Divider />

                        <Table
                            columns={columns}
                            className="table-custom"
                            dataSource={order?.orderItems?.map((item: any, index: number) => ({
                                ...item,
                                key: item.uuid || index,
                            }))}
                            pagination={false}
                            loading={loading}
                            size="middle"
                            showSorterTooltip={{ target: 'sorter-icon' }}
                        />
                    </div>
                </div>
            </ContentBox>
        </Permission>
    );
});

export default OrderDetailsPayment;