import { Button, Modal, Table, TableColumnsType, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { Dispatch, SetStateAction } from 'react';
import { compareValues, getActionColor, getActionLog } from '../../../../../utils/statusLogMapper';
import { getStatusColor } from '../../../../../utils/statusColorMapper';


interface ModalLogComparativoProps {
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    itemSelected?: any;
}


interface DataType {
    changed: boolean | React.Key;
    active: boolean;
    hexColorCode: string;
    shortName: string;
    name: string;
    uuid: string;
    key: React.Key;
}

const ModalLogComparativo: React.FC<ModalLogComparativoProps> = observer(({ isModalOpen, setIsModalOpen, itemSelected }) => {
    const tableItens = compareValues(itemSelected)

    const isBoolean = (value: string | number | boolean) => {
        if (value === true || value === false) {
            return value.toString();
        }

        if (typeof value === 'string') {
            const lowerValue = value.toLowerCase();
            if (lowerValue === 'true' || lowerValue === 'false') {
                return lowerValue;
            }
        }

        return value ? value : '-';
    };

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Campo',
            dataIndex: 'field',
            key: 'field',
            onCell: () => ({
                style: { backgroundColor: '#fafafa' },
            }),
        },
        {
            title: 'Antes',
            dataIndex: 'oldValue',
            key: 'oldValue',
            render: (oldValue) => {
                return (
                    <p>{isBoolean(oldValue)}</p>
                );
            },
        },
        {
            title: 'Depois',
            dataIndex: 'newValue',
            key: 'newValue',
            render: (newValue) => {
                return (
                    <p>{isBoolean(newValue)}</p>
                );
            },
        },
        {
            title: 'Alterado',
            dataIndex: 'changed',
            key: 'changed',
            filters: [
                { text: 'Sim', value: true },
                { text: 'Não', value: false },
            ],
            onFilter: (value, record) => record.changed === value,
            render: (changed) => {
                let text = changed ? 'Sim' : 'Não';
                return (
                    <Tag color={getStatusColor(text)}><p>{text}</p></Tag>
                );
            },
        },
    ];

    return (
        <>
            <Modal cancelText="Fechar" width={900} title="Detalhamento de Alterações" open={isModalOpen} onCancel={() => setIsModalOpen(false)} footer={[
                <Button key="cancel" type='primary' onClick={() => setIsModalOpen(false)}>
                    Fechar
                </Button>,
            ]}>
                <Table
                    title={() =>
                        <div className="flex justify-between">
                            <div>
                                <p>Usuário: <b>{itemSelected?.modifiedBy}</b></p>
                            </div>
                            <div>
                                <Tag color={getActionColor(itemSelected?.operation)}> <p>{getActionLog(itemSelected?.operation)}</p></Tag>
                            </div>
                        </div>
                    }
                    className="table-custom"
                    columns={columns}
                    dataSource={tableItens.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    bordered
                    size="middle"
                    pagination={false}
                    showSorterTooltip={{ target: 'sorter-icon' }}
                    scroll={{ y: 350 }}
                />
            </Modal>
        </>
    );
});

export default ModalLogComparativo;