export const formatDocument = (value: string | undefined) => {
    if (value !== undefined) {
        const cleanedValue = value.replace(/\D+/g, ''); // Remove qualquer caractere não numérico

        if (cleanedValue.length === 11) { // CPF
            return cleanedValue
                .replace(/(\d{3})(\d)/, '$1.$2') // 1ª parte
                .replace(/(\d{3})(\d)/, '$1.$2') // 2ª parte
                .replace(/(\d{3})(\d{2})$/, '$1-$2'); // último bloco
        } else if (cleanedValue.length === 14) { // CNPJ
            return cleanedValue
                .replace(/(\d{2})(\d)/, '$1.$2') // Captura 2 primeiros dígitos
                .replace(/(\d{3})(\d)/, '$1.$2') // Captura os 3 dígitos
                .replace(/(\d{3})(\d)/, '$1/$2') // Captura os 3 dígitos e adiciona /
                .replace(/(\d{4})(\d)/, '$1-$2') // Captura os 4 últimos dígitos e adiciona -
                .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números
        }

        return value; // Se não for CPF nem CNPJ, retorna o valor original
    }
};


export const formatPhone = (value: any) => {
    if (value !== undefined) {
        const cleanedValue = value?.replace(/\D+/g, ''); // Remove qualquer caractere não numérico

        if (cleanedValue.length === 10) { // Telefone fixo (ex: (11) 2345-6789)
            return cleanedValue
                .replace(/(\d{2})(\d)/, '($1) $2') // DDD
                .replace(/(\d{4})(\d)/, '$1-$2'); // Número
        } else if (cleanedValue.length === 11) { // Celular (ex: (11) 91234-5678)
            return cleanedValue
                .replace(/(\d{2})(\d)/, '($1) $2') // DDD
                .replace(/(\d{5})(\d)/, '$1-$2'); // Número com 5 dígitos na primeira parte
        }

        return value; // Retorna o valor original se não tiver 10 ou 11 dígitos
    }
};

export const formatCep = (value: any) => {
    if (value !== undefined) {
        const stringValue = value.toString();

        const cleanedValue = stringValue?.replace(/\D+/g, ''); // Remove qualquer caractere não numérico

        if (cleanedValue.length === 8) { // Formato CEP (ex: 12345-678)
            return cleanedValue.replace(/(\d{5})(\d)/, '$1-$2');
        }

        return value; // Retorna o valor original se não tiver 8 dígitos
    }
};