import URLS from '../config/urls';
import { Channel } from '../types/Channel';
import BaseAPI from './BaseApi';


class ChannelService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CHANNELS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async createChannel(channel: Channel): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.CHANNELS}`, channel);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async editChannel(channel: Channel, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.CHANNELS}/${uuid}`, channel);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async getChannelByUuid(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.CHANNELS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async deleteChannel(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.CHANNELS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new ChannelService();