import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class TemplateService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.TEMPLATE_EMAIL}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async getTemplateByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.TEMPLATE_EMAIL}/${uuid}`);
            return response;
        } catch (e) {
            return { error: "Erro ao buscar dados" };
        }
    }

    async editTemplate(template: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.TEMPLATE_EMAIL}/${uuid}`, template);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async sendEmailTesting(data: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.TEMPLATE_EMAIL}/test`, data);
            return response;
        } catch (e) {
            return { error: "Erro ao enviar e-mail" };
        }
    }

    async getBodyEmailData(type: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.TEMPLATE_EMAIL}/search?search=example:true,type:${type}`,);
            return response;
        } catch (e) {
            return { error: "Erro ao enviar e-mail" };
        }
    }

}

export default new TemplateService();