const lightTheme = {
    token: {
        colorPrimary: '#f2a73b',
        // colorBgBase: '#ffffff',
        // colorTextBase: '#000000',
        // colorBorder: '#d9d9d9',
        // colorBgContainer: '#ffffff',
        // colorTextSecondary: '#595959',
    },
};

export default lightTheme;