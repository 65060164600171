import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { TableColumnsType, TableProps } from 'antd';
import { Button, message, Space, Table } from 'antd';
import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE, TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import useQueryParams, { buildParamsFromQuery } from '../../../hooks/useQueryParams';
import { PermissionShipping } from '../../../permissions/PermissionShipping';
import shipppingStore from '../../../stores/shipppingStore';
import { Shipping } from '../../../types/Shipping';
import { convertIsoToTime, formatDateString } from '../../../utils/dateFunctions';
import { exportToExcel } from '../../../utils/excelFunction';
import { getSortField, getSortOrder, getUniqueFilters } from '../../../utils/functions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import ModalComponent from '../../organisms/ModalComponent';
import { useAuth } from '../../protected/ProviderAuth';

interface DataType {
    corte: any;
    sla: any;
    orgVendas: any;
    uuid: any;
    key: React.Key;
    shippingCompany: string;
}


interface QueryParams {
    orgVendas?: string;
    shippingCompany?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
}

// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Fretes</p>,
    },
]

const ListShipping: React.FC = observer(() => {
    const { shippingList, loading, page, totalElements, defaultparams, sortDefault, size } = shipppingStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const navigate = useNavigate();
    const [selectionCheckbox, setSelectionCheckbox] = useState<any>();
    const [queryParams, setQueryParams] = useQueryParams();
    const listRouter = () => { navigate(`/frete/create`); }
    const { hasPermission, isColumnVisible } = useAuth();

    const initialValues = {
        orgVendas: queryParams.orgVendas || '',
        shippingCompany: queryParams.shippingCompany || '',
    };


    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        shippingCompany: 'shippingCompany',
        orgVendas: 'orgVendas',
    };


    useEffect(() => {
        const params = buildParamsFromQuery(queryParams, mappings);

        const fetchData = async () => {
            if (hasPermission(PermissionShipping.GET)) {
                await shipppingStore.getList(params);
            }
        };

        fetchData();
    }, []);



    // Colunas da Tabela
    const columns: TableColumnsType<DataType> = [
        {
            title: 'Empresa',
            dataIndex: 'shippingCompany',
            key: 'shippingCompany',
            sorter: true,
            filters: getUniqueFilters(shippingList, 'shippingCompany'),
            onFilter: (value, record) => record.shippingCompany.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('shippingCompany', queryParams.sort),
        },
        {
            title: 'Org Vendas',
            dataIndex: 'orgVendas',
            key: 'orgVendas',
            sorter: true,
            filters: getUniqueFilters(shippingList, 'orgVendas'),
            onFilter: (value, record) => record.orgVendas.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('orgVendas', queryParams.sort),
        },
        {
            title: 'Regiao Vendas',
            dataIndex: 'regVendas',
            key: 'regVendas',
            sorter: true,
            filters: getUniqueFilters(shippingList, 'regVendas'),
            onFilter: (value, record) => record.orgVendas.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('regVendas', queryParams.sort),
        },
        {
            title: 'Hora do Corte',
            dataIndex: 'corte',
            key: 'corte',
            sorter: true,
            defaultSortOrder: getSortOrder('corte', queryParams.sort),
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{convertIsoToTime(text)}</p>}</>
                );
            },
        },
        {
            title: 'SLA Região',
            dataIndex: 'sla',
            key: 'sla',
            sorter: true,
            filters: getUniqueFilters(shippingList, 'sla'),
            onFilter: (value, record) => record.sla.indexOf(value as string) === 0,
            defaultSortOrder: getSortOrder('sla', queryParams.sort),
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            defaultSortOrder: getSortOrder('created', queryParams.sort),
            render: (text) => {
                return (
                    //@ts-ignore
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Editar',
            dataIndex: 'edit',
            key: 'edit',
            align: 'center',
            width: 50,
            hidden: !isColumnVisible(PermissionShipping.GET_UUID),
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a href={`/frete/create/${record.uuid}`}>
                        <div><EditOutlined /></div>
                    </a>
                </Space>
            ),
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 50,
            hidden: !isColumnVisible(PermissionShipping.DELETE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    // Faz o filtro da tabela
    const handleSubmit = async (values: { shippingCompany: string; orgVendas: string }) => {
        const newParams: any = {
            shippingCompany: values.shippingCompany || undefined,
            orgVendas: values.orgVendas || undefined,
        };

        const search = [
            values.shippingCompany ? `shippingCompany;"${values.shippingCompany}"` : null,
            values.orgVendas ? `orgVendas;"${values.orgVendas}"` : null,
        ]
            .filter(Boolean)
            .join(' ');


        const params = {
            size: queryParams.size ? Number(queryParams.size) : DEFAULT_PAGE_SIZE,
            page: 0,
            sort: queryParams.sort || sortDefault || 'created,desc',
            search,
        };

        setQueryParams(newParams);

        //@ts-ignore
        await shipppingStore.getList(params);
    };



    const handleDeleteItem = async () => {
        const response = await shipppingStore.deleteShipping(itemSelected.uuid);
        if (!response?.error) {
            await shipppingStore.getList(defaultparams);
            setIsModalOpen(false);
        }
    };


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const params = buildParams(pagination, sorter, queryParams);
        await shipppingStore.getList(params);
    };


    const handleClearAndSearch = async (resetForm: () => void) => {
        resetForm();
        setQueryParams({});
        await shipppingStore.getList(defaultparams);
    };


    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };


    const buildParams = (
        pagination: any,
        sorter: any,
        queryParams: QueryParams
    ) => {
        const getSort = getSortField(sorter);
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const filters = buildParamsFromQuery(queryParams, mappings);

        const params = {
            size: pagination.pageSize || queryParams.size || newPageSize,
            page: pagination.current ? currentPage : queryParams.page || 0,
            sort: getSort || queryParams.sort || 'created,desc',
            search: filters.search || queryParams.search || '',
        };

        // Atualiza a URL com os novos parâmetros
        setQueryParams({
            size: params.size,
            page: params.page,
            sort: params.sort,
            search: params.search,
        });

        return params;
    };

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            setSelectionCheckbox(selectedRows);
        },
    };

    const deleteSelectedItems = async () => {
        try {
            if (selectionCheckbox !== undefined) {
                const results = await Promise.all(
                    selectionCheckbox.map((item: any) => shipppingStore.deleteShippingAll(item.uuid))
                );
                const hasError = results.some(response => response?.error);
                if (hasError) {
                    setSelectionCheckbox([]);
                    message.error('Erro ao deletar um ou mais Fretes.');
                } else {
                    setSelectionCheckbox([]);
                    message.success('Todos os Fretes foram deletados com sucesso!');
                    await shipppingStore.getList(defaultparams);
                }
                setIsModalDeleteOpen(false);
            } else {
                message.error('Selecione pelo menos um item da listagem');
            }
        } catch (error) {
            message.error('Erro ao tentar deletar os Fretes.');
        }
    };

    const handleExport = async () => {
        const params = buildParamsFromQuery(queryParams, mappings);
        const exportParams = {
            ...params,
            size: 999999,
        }

        const dataExport = await shipppingStore.getList(exportParams);

        if (dataExport) {
            const listExport = dataExport.data.content.map((item: Shipping) => ({
                shippingCompany: item.shippingCompany,
                orgVendas: item.orgVendas,
                regVendas: item.regVendas,
                corte: convertIsoToTime(item.corte),
                sla: item.sla,
                created: formatDateString(item.created?.toString() ?? ""),
            }));

            exportToExcel(listExport, ["Empresa", "Org Vendas", "Região de Vendas", "Hora do Corte", "SLA Região", "Data de Criação"], "Tabela de Fretes.xlsx");
        }
    };

    const deleteAllModal = () => {
        if (selectionCheckbox?.length > 0) {
            setIsModalDeleteOpen(true);
        } else {
            message.warning('Por favor, selecione ao menos um item para realizar a exclusão.');
        }
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionShipping.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />


            <Permission permissionKeys={[PermissionShipping.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Fretes" description='Tela de listagem de Fretes'>
                    {/* @ts-ignore */}
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                        {({ resetForm }) => (
                            <Form>
                                <div className="flex items-end w-full gap-4">
                                    <div className="w-full">
                                        <InputComponet label="Empresa" name="shippingCompany" id="shippingCompany" />
                                    </div>
                                    <div className="w-full">
                                        <InputComponet label="ORG Vendas" name="orgVendas" id="orgVendas" />
                                    </div>
                                    <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                                        <Button className="w-full" onClick={() => handleClearAndSearch(resetForm)}>Limpar</Button>
                                        <Button className="w-full" htmlType="submit" type="primary">Filtrar</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </ContentBox>

                <ContentBox className="mb-4 p-6" description='Listagem de Fretes' actions={
                    <>
                        <Permission permissionKeys={[PermissionShipping.DELETE]}><ActionButton icon actionType="delete" onClick={() => deleteAllModal()}>Deletar</ActionButton></Permission>
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
                    </>
                }>
                    <Table
                        className="table-custom"
                        columns={columns}
                        dataSource={shippingList.map((item: any, index: number) => ({
                            ...item,
                            key: item.uuid || index,
                        }))}
                        onChange={onChange}
                        loading={loading}
                        rowSelection={rowSelection}
                        size="middle"
                        bordered={false}
                        pagination={{
                            current: page + 1,
                            pageSize: size,
                            total: totalElements,
                            showSizeChanger: true,
                            pageSizeOptions: ['20', '40', '60'],
                        }}
                        showSorterTooltip={{ target: 'sorter-icon' }}
                    />
                </ContentBox>
            </Permission>

            {isModalOpen && <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Frete <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />}

            {isModalDeleteOpen && <ModalComponent
                title="Aviso"
                content={<p>Você está prestes a excluir vários itens da listagem, deseja prosseguir?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={deleteSelectedItems}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />}
        </>
    );
});

export default ListShipping;