import { Spin } from 'antd';
import React from 'react';
import { LOADING_TEXT } from '../../../config/config';

interface LoadingComponentProps {
    text?: string;
}

const LoadingComponent: React.FC<LoadingComponentProps> = ({ text }) => {
    return (
        <div className="flex flex-col items-center gap-2">
            <Spin />
            <p>{text ? text : LOADING_TEXT}</p>
        </div>
    );
};

export default LoadingComponent;