import { DatePicker, Typography } from "antd";
import { Field } from "formik";
import React from "react";
import "./input.scss";

interface DatePickerComponetProps {
  label?: string;
  format?: string;
  disabled?: boolean;
  name: string; // Tornando o name obrigatório
  size?: "large" | "middle" | "small";
  isRange?: boolean;
}

const { RangePicker } = DatePicker;

const DatePickerComponet: React.FC<DatePickerComponetProps> = ({
  label,
  name,
  size,
  disabled,
  format,
  isRange = true,
}) => (
  <div className="flex flex-col w-full input-container">
    {label && <Typography.Title level={5}>{label}</Typography.Title>}
    <Field name={name}>
      {({ field, form, meta }: any) => {
        const handleChange = (dates: any, dateStrings: any) => {
          form.setFieldValue(field.name, dates); // Ou dateStrings, dependendo do que você precisa
        };

        return isRange ? (
          <>
            <RangePicker
              onChange={handleChange}
              value={field.value || []}
              size={size ? size : "large"}
              disabled={disabled}
              format={format ? format : "DD-MM-YYYY"}
              status={meta.touched && meta.error ? "error" : ""}
            />
            {meta.touched && meta.error && (
              <Typography.Text type="danger">{meta.error}</Typography.Text>
            )}
          </>
        ) : (
          <>
            <DatePicker
              onChange={handleChange}
              value={field.value || []}
              size={size ? size : "large"}
              disabled={disabled}
              format={format ? format : "DD-MM-YYYY"}
              status={meta.touched && meta.error ? "error" : ""}
            />
          </>
        );
      }}
    </Field>
  </div>
);

export default DatePickerComponet;
