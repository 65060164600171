import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class HomeService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.HOMES}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createHome(color: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.HOMES}`, color);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };  // Retorna um objeto de erro
        }
    }

    async editHome(color: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.HOMES}/${uuid}`, color);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error }; // Retorna um objeto de erro
        }
    }

    async registerCustomerHome(customer: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.HOMES}/${uuid}/customers`, customer);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error }; // Retorna um objeto de erro
        }
    }

    async registerComponentHome(components: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.HOMES}/${uuid}/components`, components);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error }; // Retorna um objeto de erro
        }
    }

    async deleteComponentHome(components: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.patch(`${URLS.HOMES}/${uuid}/components`, components);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error }; // Retorna um objeto de erro
        }
    }

    async deleteHome(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.HOMES}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async getHomeByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.HOMES}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }
}

export default new HomeService();