import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class PerfilService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.AUTHORITY_GROUPS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async getPerfilByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.AUTHORITY_GROUPS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async createPerfil(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.AUTHORITY_GROUPS}`, data);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async editPerfil(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.AUTHORITY_GROUPS}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async deletePerfil(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.AUTHORITY_GROUPS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new PerfilService();