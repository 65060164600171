import { observer } from 'mobx-react-lite';
import React from 'react';
import { typePromotion, YesOrNot } from '../../../../../utils/selects';
import SelectComponent from '../../../../atoms/SelectComponent';
import ContentBox from '../../../../molecules/ContentBox';
import CategorySelectedPromotion from './CategorySelectedPromotion';
import ProductAddPromotion from './ProductAddPromotion';
import Permission from '../../../../molecules/Permission';
import { PermissionCategories } from '../../../../../permissions/PermissionCategories';
import PermissionComponent from '../../../../atoms/PermissionComponent';
import { PermissionProducts } from '../../../../../permissions/PermissionProducts';


const ApplicationType: React.FC = observer(({ setAplicationType, aplicationType }: any) => {
    return (
        <div>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Tipo de Aplicação" description='Quando ativo a campanha será destinada apenas parar os protudos ou categorias selecionadas.'>
                <div className="flex gap-4 items-end">
                    <SelectComponent onChange={(value, option) => setAplicationType(option)} label="Selecione o Tipo de Aplicação" name="aplicationType" options={typePromotion} />
                    <SelectComponent label="Remove Produtos ou Categoria da Campanha" name="excludeProductCategory" options={YesOrNot} />
                </div>
            </ContentBox>

            <Permission permissionKeys={[PermissionCategories.GET]} fallback={<PermissionComponent />}>
                {aplicationType && aplicationType?.value === "CATEGORIA" &&
                    <ContentBox className="mb-4 p-6" title="Selecione a Categoria" description='Selecione as categorias que varão parte da campanha'>
                        <CategorySelectedPromotion />
                    </ContentBox>
                }
            </Permission>

            <Permission permissionKeys={[PermissionProducts.GET]} fallback={<PermissionComponent />}>
                {aplicationType && aplicationType?.value === "PRODUTO" &&
                    <ContentBox className="mb-4 p-6" title="Selecione o Produto" description='Selecione os produtos que varão parte da campanha'>
                        <ProductAddPromotion />
                    </ContentBox>
                }
            </Permission>
        </div>
    );
});

export default ApplicationType;
