import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    code: Yup.string().required('Código é obrigatório'),
    name: Yup.string().required('Nome é obrigatório'),
    channel: Yup.object()
        .shape({
            value: Yup.string().required('Selecione um canal válido'),
        })
        .nullable()
        .required('Canal é obrigatório'),
});