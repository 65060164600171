import * as Yup from 'yup';

const validationSchema = Yup.object({
    newPassword: Yup.string()
    .matches(/[A-Z]/, 'A senha deve ter pelo menos uma letra maiúscula')
    .matches(/[a-z]/, 'A senha deve ter pelo menos uma letra minúscula')
    .matches(/\d/, 'A senha deve ter pelo menos um número')
    .matches(/[@$!%*?&#]/, 'A senha deve ter pelo menos um caractere especial')
    .required('Senha é obrigatória'),
    confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'),], 'As senhas não são iguais')
    .required('Confirmação de senha é obrigatória'),
});

export default validationSchema;