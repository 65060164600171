import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    center: Yup.string().required('Centro é obrigatória'),
    minimumPrice: Yup.string().required('Preço Minimo é obrigatória'),
    price: Yup.string().required('Preço é obrigatória'),
    initialAmount: Yup.string().required('Quantidade Inicial é obrigatória'),
    nameMeasurement: Yup.object()
        .shape({
            value: Yup.string().required('Selecione uma Unidade de Medida válida'),
        })
        .nullable()
        .required('Unidade de Medida é obrigatório'),
});