import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class InstitucionalService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.INSTITUTIONAL}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async getInstitucionalByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.INSTITUTIONAL}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async deleteInstitucional(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.INSTITUTIONAL}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async createInstitucional(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.INSTITUTIONAL}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editInstitucional(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.INSTITUTIONAL}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }
}

export default new InstitucionalService();