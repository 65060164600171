import { observer } from 'mobx-react-lite';
import React from 'react';
import InputComponent from '../../../atoms/InputComponet';
import ContentBox from '../../../molecules/ContentBox';


const GeneralConfiguration: React.FC = observer(() => {
    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Configuração Geral" description='Cadastro de Configuração Geral'>
                <div className="w-full flex flex-col gap-4">
                    <div className="flex gap-4">
                        <InputComponent label="Nome da empresa" name="companyName" id="companyName" />
                        <InputComponent label="Documento" name="document" id="document" />
                    </div>
                    <div className="flex gap-4">
                        <InputComponent label="Meta Description" name="homeMetaDescription" id="homeMetaDescription" />
                        <InputComponent label="Meta Title" name="homeMetaTitle" id="homeMetaTitle" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Telefone" name="phone" id="phone" />
                        <InputComponent label="Horario de Atendimento" name="openingHours" id="openingHours" />
                    </div>

                    <div className="flex gap-4">
                        <InputComponent label="Chat (Link)" name="zendeskKey" id="zendeskKey" />
                    </div>
                </div>
            </ContentBox>
        </>
    );
});

export default GeneralConfiguration;