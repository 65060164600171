import { makeAutoObservable } from 'mobx';
import reportGeneralSalesService from '../services/ReportGeneralSalesService';


class ReportGeneralSalesStore {
    loading: boolean = false;
    generalReport: any = [];

    constructor() {
        makeAutoObservable(this);
    }

    async getGeneralSales(params: any) {
        this.loading = true;

        const response = await reportGeneralSalesService.getGeneralReport(params);

        if (!response.error) {
            this.generalReport = response.data;
        }
        this.loading = false;
        return response.data;
    }
}

const reportGeneralSalesStore = new ReportGeneralSalesStore();
export default reportGeneralSalesStore;