import type { TableColumnsType, TableProps } from "antd";
import { Button, Table } from "antd";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { DEFAULT_PAGE_SIZE } from "../../../config/config";
import useQueryParams from "../../../hooks/useQueryParams";
import { PermissionReports } from "../../../permissions/PermissionReports";
import categoryStore from "../../../stores/categoryStore";
import reportSalesByCategoryStore from "../../../stores/reportSalesByCategoryStore";
import { CategoryReport } from "../../../types/Category";
import { exportToExcel } from "../../../utils/excelFunction";
import {
  convertToDayjs,
  formattedDates,
  getFirstFilterDates,
  getSortField,
  getSortOrder,
  getUniqueFilters,
  roundPercentage,
} from "../../../utils/functions";
import BreadCrumbComponent from "../../atoms/BreadCrumbComponent";
import ActionButton from "../../atoms/ButtonComponent";
import DatePickerComponet from "../../atoms/DatePickerComponet";
import NotPermissionComponent from "../../atoms/NotPermissionComponent";
import SelectComponent from "../../atoms/SelectComponent";
import ContentBox from "../../molecules/ContentBox";
import Permission from "../../molecules/Permission";
import { useAuth } from "../../protected/ProviderAuth";
import { PermissionCategories } from "../../../permissions/PermissionCategories";

interface DataType {
  categoryName: string;
}

interface QueryParams {
  to: string;
  from: string;
  size?: number;
  page?: number;
  categoryName: string;
}

// BreadCrumb
const breadCrumb = [
  {
    title: <a href="/dashboard">Dashboard</a>,
  },
  {
    title: <p>Relatório de Vendas por Categoria</p>,
  },
];

const ReportSalesByCategory: React.FC = observer(() => {
  const { reportList, loading, page, totalElements, sort } =
    reportSalesByCategoryStore;
  const { categoryListSelect } = categoryStore;
  const [queryParams, setQueryParams] = useQueryParams();
  const [initialDate, finalDate] = getFirstFilterDates();
  const { hasPermission } = useAuth();

  const initialValues = {
    date:
      queryParams.from && queryParams.to
        ? [convertToDayjs(queryParams.from), convertToDayjs(queryParams.to)]
        : [convertToDayjs(initialDate), convertToDayjs(finalDate)],
    categoryName:
      typeof queryParams.categoryName === "string"
        ? { value: queryParams.categoryName, label: queryParams.categoryName }
        : { value: "", label: "" },
  };

  useEffect(() => {
    const initialParams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      categoryName: queryParams.categoryName || "",
      from: queryParams.from
        ? queryParams.from + "T00:00:00.556"
        : initialDate + "T00:00:00.556",
      to: queryParams.to
        ? queryParams.to + "T00:00:00.556"
        : finalDate + "T00:00:00.556",
      sort: "categoryName",
      page: queryParams.page || "0",
      size: queryParams.size || DEFAULT_PAGE_SIZE,
    };

    const fetchData = async () => {
      if (hasPermission(PermissionReports.BEST_SELLING_CATEGORIES)) {
        reportSalesByCategoryStore.getList(initialParams);
      }
    };

    const fetchDataCategory = async () => {
      await categoryStore.getListCategorySelect();
    };

    if (hasPermission(PermissionCategories.GET)) {
      fetchDataCategory();
    }
    fetchData();
  }, []);

  // Colunas da Tabela
  const columns: TableColumnsType<DataType> = [
    {
      title: "Categoria",
      dataIndex: "categoryName",
      key: "categoryName",
      filters: getUniqueFilters(reportList, "categoryName"),
      onFilter: (value, record) =>
        record.categoryName.indexOf(value as string) === 0,
      defaultSortOrder: getSortOrder("categoryName", queryParams.sort),
    },
    {
      title: "Quantidade",
      dataIndex: "totalAmount",
      key: "totalAmount",
      defaultSortOrder: getSortOrder("totalAmount", queryParams.sort),
    },
    {
      title: "Porcentagem",
      dataIndex: "percentage",
      key: "percentage",
      defaultSortOrder: getSortOrder("percentage", queryParams.sort),
      render: (percentage) => {
        return <>{percentage && <p>{roundPercentage(percentage)}%</p>}</>;
      },
    },
  ];

  // Faz o filtro da tabela
  const handleSubmit = async (values: {
    date: any;
    categoryName: { value: string; label: string };
  }) => {
    const formattedDate = formattedDates(values.date, "YYYY-MM-DD");
    const dateInitial = formattedDate[0];
    const dateFinal = formattedDate[1];

    const params = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: dateInitial + "T00:00:00.556",
      to: dateFinal + "T00:00:00.556",
      categoryName: values.categoryName.label || queryParams.categoryName || "",
      sort: queryParams.sort || sort || "categoryName",
      page: queryParams.page || "0",
      size: queryParams.size || DEFAULT_PAGE_SIZE,
    };

    const newParams: any = {
      categoryName: values.categoryName.label || undefined,
      to: dateFinal || undefined,
      from: dateInitial || undefined,
    };

    setQueryParams(newParams);

    //@ts-ignore
    await reportSalesByCategoryStore.getList(params);
  };

  // Limpa os dados do formulario
  const handleClearAndSearch = async (resetForm: () => void) => {
    resetForm(); // Limpa os valores do formulário
    setQueryParams({}); // Limpa a URL

    const clearparams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      customer: "",
      from: initialDate + "T00:00:00.556",
      to: finalDate + "T00:00:00.556",
      sort: "categoryName",
      page: "0",
      size: DEFAULT_PAGE_SIZE,
    };

    await reportSalesByCategoryStore.getList(clearparams);
  };

  const onChange: TableProps<DataType>["onChange"] = async (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    //@ts-ignore
    const params = buildParams(pagination, sorter, queryParams);
    await reportSalesByCategoryStore.getList(params);
  };

  const buildParams = (
    pagination: any,
    sorter: any,
    queryParams: QueryParams
  ) => {
    const currentPage = pagination.current - 1;
    const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;
    const getSort = getSortField(sorter);

    const params = {
      size: pagination.pageSize || queryParams.size || newPageSize,
      page: pagination.current ? currentPage : queryParams.page || 0,
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      from: queryParams.from ? queryParams.from : initialDate + "T00:00:00.556",
      to: queryParams.to ? queryParams.to : finalDate + "T00:00:00.556",
      sort: getSort || "categoryName",
      categoryName: queryParams.categoryName || "",
    };

    // Atualiza a URL com os novos parâmetros
    setQueryParams({
      size: params.size,
      page: params.page,
      from: params.from,
      to: params.to,
      sort: params.sort,
      categoryName: params.categoryName,
    });

    return params;
  };

  const handleExport = async () => {
    const defaultParams = {
      "@class":
        "br.com.stoom.tresCoracoes.model.dto.TresCoracoesReportRequestDTO",
      categoryName: queryParams.categoryName || "",
      from: queryParams.from
        ? queryParams.from + "T00:00:00.556"
        : initialDate + "T00:00:00.556",
      to: queryParams.to
        ? queryParams.to + "T00:00:00.556"
        : finalDate + "T00:00:00.556",
      size: 9999,
      page: 0,
      sort: "created"
    };
    const dataExport = await reportSalesByCategoryStore.getList(defaultParams);

    if (dataExport) {
      const listExport = dataExport.data.content.map((item: CategoryReport) => ({
        categoryName: item.categoryName,
        totalAmount: item.totalAmount,
        percentage: `${roundPercentage(item.percentage ?? 0)}%`,
      }));

      exportToExcel(listExport, ["Categoria", "Quantidade", "Porcentagem"], "Tabela de Relatório de Vendas por Categoria.xlsx");
    }
  };

  return (
    <>
      <BreadCrumbComponent breadCrumbList={breadCrumb} />

      <Permission permissionKeys={[PermissionReports.BEST_SELLING_CATEGORIES]} fallback={NotPermissionComponent()}>
        <ContentBox
          className="mb-4 p-6"
          title="Relatório de Vendas por Categoria"
          description="Informações sobre as vendas por categoria"
        >
          {/* @ts-ignore */}
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(
              { resetForm } // Passa o resetForm do render prop de Formik
            ) => (
              <Form>
                <div className="flex items-end w-full gap-4 pb-4">
                  <div className="w-full">
                    <Permission permissionKeys={[PermissionCategories.GET]} inputType name="Categoria">
                      <SelectComponent
                        label="Categoria"
                        name="categoryName"
                        options={categoryListSelect}
                      />
                    </Permission>
                  </div>
                  <div className="w-full">
                    <DatePickerComponet label="Data inicial" name="date" />
                  </div>
                  <div className="col-span-12 md:col-span-2 flex flex-row gap-4">
                    <Button
                      className="w-full"
                      onClick={() => handleClearAndSearch(resetForm)}
                    >
                      Limpar
                    </Button>
                    <Button className="w-full" htmlType="submit" type="primary">
                      Filtrar
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </ContentBox>

        <ContentBox className="mb-4 p-6">
          <div className="flex items-end w-full gap-4 pb-4 items-center justify-between">
            <p>Relatório de Vendas por Categoria</p>

            <div className="flex gap-4">
              <ActionButton icon actionType="export" onClick={() => handleExport()}>Exportar para Excel</ActionButton>
            </div>
          </div>

          <Table
            className="table-custom"
            columns={columns}
            dataSource={reportList.map((item: any, index: number) => ({
              ...item,
              key: item.uuid || index,
            }))}
            loading={loading}
            onChange={onChange}
            size="middle"
            bordered={false}
            pagination={{
              current: page + 1,
              pageSize: DEFAULT_PAGE_SIZE,
              total: totalElements,
              showSizeChanger: true,
              pageSizeOptions: ["20", "40", "60"],
            }}
            showSorterTooltip={{ target: "sorter-icon" }}
          />
        </ContentBox>
      </Permission>
    </>
  );
});

export default ReportSalesByCategory;
