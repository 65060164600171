import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class ReportGeneralSalesService {
    async getGeneralReport(params: any): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.GENERAL_SALES}`, params);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }
}

// Atribua a instância da classe a uma variável
const reportGeneralSalesService = new ReportGeneralSalesService();

// Exporte a variável ao invés de uma instância anônima
export default reportGeneralSalesService;