import { observer } from "mobx-react-lite";
import React from "react";
import "./styles.scss";
import { formatCurrency } from "../../../../../utils/functions";

interface ProductCarouselProps {
  product?: any;
}

const ProductCard: React.FC<ProductCarouselProps> = observer(({ product }) => {

  const replaceImageUrl = (url: string) => {

    if (url) {
      return url.replace(
        "https://s3.amazonaws.com/tres-coracoes-dev-sa/",
        "https://tres-coracoes-dev-sa.s3.amazonaws.com/"
      );
    }
  };


  return (
    <div className="product-card mx-2">
      <div className="product-image">
        <img width={175} height={175} src={replaceImageUrl(product.files[0]?.path)} alt="" />
      </div>
      <div className="pt-6 flex flex-col justify-between product-information">
        <div>
          <div className="product-description">{product.name}</div>
        </div>

        {product?.prices.length > 0 && (
          <>
            <div className="mt-4 flex items-center justify-center gap-8">
              {product && product?.prices && (
                <p className="product-font ">
                  {formatCurrency(product?.prices[0]?.price)} /{" "}
                  {product?.unitOfMeasurementList?.length > 0 &&
                    product?.unitOfMeasurementList[0]?.name}
                </p>
              )}
            </div>

            <div className="product-fake-button cursor-pointer">
              <p>Adicionar</p>
            </div>
          </>
        )}

        {product?.prices.length === 0 && (
          <>
            <div className="mt-4 flex items-center justify-center gap-8">
              <p className="product-font product-font-height"></p>
            </div>

            <div className="product-fake-button-indisponivel cursor-pointer">
              <p>Indisponivel</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default ProductCard;
