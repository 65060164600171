export function getChartData(dataArray: any, metricName: any, isDaily: boolean = false) {
    if (dataArray) {
        // Define os nomes dos meses abreviados
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Encontra o objeto correspondente à métrica
        const metric = dataArray.find((item: any) => item?.name === metricName);
        if (!metric) {
            throw new Error(`Métrica "${metricName}" não encontrada.`);
        }

        // Extrai as séries e converte para números os valores de `data`
        const series = metric.series.map((seriesItem: any) => ({
            name: seriesItem?.name,
            data: seriesItem?.data.map((value: any) => parseFloat(value))
        }));

        // Define as categorias como dias ou meses, usando as datas da métrica `xaxis`
        const categories = metric.xaxis.data.map((date: any) => {
            const parsedDate = new Date(date);
            if (isDaily) {
                return parsedDate.getDate(); // Retorna o dia do mês
            } else {
                const monthIndex = parsedDate.getMonth(); // Extrai o índice do mês
                return monthNames[monthIndex]; // Retorna o mês abreviado
            }
        });

        return { categories, series };
    }
}