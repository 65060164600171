import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import orderStore from '../../../../../stores/orderStore';
import { formatCurrency } from '../../../../../utils/functions';
import ContentBox from '../../../../molecules/ContentBox';
import './styles.scss';

type OrderItem = {
    quantity: number;
    total: number;
    product: Product;
};

type Product = {
    name: string;
    skuCode: string;
    files: {
        uuid: string;
        name: string;
        path: string;
        // ... outros atributos conforme necessário 
    }[];
};


type Order = {
    customer: { firstName: string; mainAddress: any };
    orderItems: OrderItem[];
    total: number;
    totalBilled: number;
    status: { statusName: string };
};


const ProductMostPurchase: React.FC = () => {
    const [mostProductBuy, setMostProductBuy] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);


    function getTopSellingProducts(orders: Order[], topN: number): { highlighted: OrderItem, others: OrderItem[] } {
        const productMap: Record<string, { quantity: number; total: number; product: Product }> = {};

        // Agregar as quantidades e totais dos produtos
        orders.forEach(order => {
            order.orderItems.forEach(item => {
                const { skuCode, name, files } = item.product; // Pegando todos os dados do produto
                if (!productMap[skuCode]) {
                    productMap[skuCode] = { quantity: 0, total: 0, product: { name, skuCode, files } }; // Armazenando o produto completo
                }
                productMap[skuCode].quantity += item.quantity;
                productMap[skuCode].total += item.total;
            });
        });


        // Transformar o objeto em um array
        const productsArray = Object.values(productMap);

        const filteredProducts = productsArray.filter((item: any) =>
            item.product.name && item.product.skuCode
        );

        // Ordenar os produtos pela quantidade
        const sortedProducts = filteredProducts.sort((a, b) => b.quantity - a.quantity);

        // Selecionar os top N produtos
        const highlighted = sortedProducts[0]; // Primeiro lugar em destaque
        const others = sortedProducts.slice(1, topN); // Os outros produtos

        return { highlighted, others };
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const defaultparams = {
                size: 999,
                page: 0,
                sort: 'idOrder,desc',
                search: 'status_statusName!CART',
            }

            const response = await orderStore.getList(defaultparams);

            if (!response.error) {
                const editJson = response.data.content.map((item: any) => {
                    return {
                        customer: {
                            firstName: item.customer?.fullName,
                            mainAddress: {
                                state: item.customer?.mainAddress?.state,
                                city: item.customer?.mainAddress?.state,
                                block: item.customer?.mainAddress?.block,
                            }
                        },
                        orderItems: item.orderItems.map((orderItem: any) => ({
                            quantity: orderItem.amount * orderItem.baseQty,
                            total: orderItem?.total,
                            totalBilled: orderItem?.totalBilled,
                            unitFakePrice: orderItem?.unitFakePrice,
                            unitPrice: orderItem?.unitPrice,
                            product: {
                                files: orderItem?.product?.files,
                                name: orderItem?.product?.name,
                                skuCode: orderItem?.product?.skuCode,
                                weight: orderItem?.product?.weight,
                                width: orderItem?.product?.width,
                                shippingHeight: orderItem?.product?.shippingHeight,
                                shippingLength: orderItem?.product?.shippingLength,
                                shippingWeight: orderItem?.product?.shippingWeight,
                                shippingWidth: orderItem?.product?.shippingWidth,
                            }
                        })),
                        total: item?.total,
                        totalBilled: item?.totalBilled,
                        status: {
                            statusName: item?.status?.statusName
                        }
                    };
                });

                const value = getTopSellingProducts(editJson, 6);
                //@ts-ignore
                setMostProductBuy(value)
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <ContentBox className="mb-0 p-6 h-full" title="Produtos mais vendidos Ultimos Pedidos">
            <div>
                {loading &&
                    <div className="flex flex-col items-center justify-items-center h-full">
                        <Spin size="large" className="pt-20" />
                        <p className="pt-2 dark:text-white">...Carregando Informação</p>
                    </div>
                }

                {!loading &&
                    <>
                        {mostProductBuy && mostProductBuy.highlighted &&
                            <div className="flex items-center pb-6 gap-2">
                                <img alt="produto-principal" className="max-w-32" src={mostProductBuy?.highlighted?.product?.files[0]?.path}></img>

                                <div className="flex flex-col">
                                    <p className="text-base">
                                        {mostProductBuy.highlighted.product.name}
                                    </p>

                                    <div className="grid-cols-2 grid pt-4">
                                        <div className="flex flex-col">
                                            <p className="whitespace-nowrap text-base">{formatCurrency(mostProductBuy?.highlighted?.total)}</p>
                                            <p className="text-gray-400">Total Vendido</p>
                                        </div>

                                        <div className="flex flex-col">
                                            <p className="whitespace-nowrap text-base">{mostProductBuy?.highlighted?.quantity}</p>
                                            <p className="text-gray-400">Quantidade</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="flex flex-col dashboard-product pr-2">
                            {mostProductBuy && mostProductBuy?.others.map((item: any, index: number) => (
                                <div className="flex items-center mb-2 gap-4 justify-between px-3 py-3 product-most-purchase-container rounded-lg" key={index}>
                                    <div className="flex items-center gap-2">
                                        <img alt={item?.product?.files[0]?.path} className="max-w-14" src={item?.product?.files[0]?.path}></img>

                                        <div>
                                            <p className="text-sm">
                                                {item.product.name}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex justify-end flex-col items-end">
                                        <p className="text-sm font-medium whitespace-nowrap">{formatCurrency(item.total)}</p>
                                        <p className="text-sm font-medium whitespace-nowrap text-gray-400">{item.quantity} vendidos</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>
        </ContentBox>
    );
};


export default ProductMostPurchase;