import React from 'react';

interface EditorTextComponentProps {
    title?: string;
}

const NotificationUnavailable: React.FC<EditorTextComponentProps> = ({ title }) => (
    <div className="flex flex-col w-full input-container">
        <p>{title}</p>
    </div>
);

export default NotificationUnavailable;
