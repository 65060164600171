import { observer } from 'mobx-react-lite';
import React from 'react';
import SelectComponent from '../../../atoms/SelectComponent';
import ContentBox from '../../../molecules/ContentBox';

const multipleSelect = [
    { value: 'HE', label: 'Ativo' },
    { value: 'IC', label: 'Inativo' },
]

const MultipleControl: React.FC = observer(() => {
    return (
        <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Controle de Unidade" description='Ao Ativar o controle de unidade, a regra de múltiplo passa a entrar em vigor para os clientes com múltiplo ativo na plataforma'>
            <SelectComponent size="middle" label="Troca de Unidade" name="multiple" options={multipleSelect} />
        </ContentBox>
    );
});

export default MultipleControl;