import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Space, Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { utils, writeFile } from 'xlsx';
import campaingStore from '../../../../stores/campaingStore';
import customerStore from '../../../../stores/customerStore';
import { removeSpecialCharacters } from '../../../../utils/functions';
import ContentBox from '../../../molecules/ContentBox';
import AutocompleteComponent from '../../../atoms/AutocompleteComponent';
import FileUpload from '../../../atoms/FileUploadComponent';
import ActionButton from '../../../atoms/ButtonComponent';
import ModalComponent from '../../../organisms/ModalComponent';
import homeStore from '../../../../stores/homeStore';
import Permission from '../../../molecules/Permission';
import { PermissionCustomer } from '../../../../permissions/PermissionCustomer';
import PermissionComponent from '../../../atoms/PermissionComponent';

interface DataType {
    displayUnits: any;
    baseQty: string;
    name: string;
    uuid: any;
    key: React.Key;
}

const CustomerAddHome: React.FC = observer(() => {
    const [autocompleteOptions, setAutocompleteOptions] = useState<any>([]);
    const [selectedProduct, setSelectedProduct] = useState<any>([]);
    const { customerList } = homeStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleSearch = async (searchText: string) => {
        setAutocompleteOptions([]);
        if (searchText.length > 3) {
            try {
                const response = await customerStore.searchCustomer(removeSpecialCharacters(searchText), 'document');
                setAutocompleteOptions(response);
                // Aqui você pode mapear a resposta para opções, se necessário
            } catch (error) {
                console.error('Erro ao buscar cliente:', error);
            }
        }
    };

    const addCustomer = () => {
        if (selectedProduct.option) {
            homeStore.updateCustomer(selectedProduct?.option?.option);
            setAutocompleteOptions([]);
            setSelectedProduct([])
        }
    }

    const handleFileProcessed = async (data: any) => {
        setLoading(true);
        const promises = data.map(async (item: any) => {
            const response = await customerStore.findCustomerByFile(removeSpecialCharacters(item.cnpj), 'document');
            return response?.content[0];
        });

        const results = await Promise.all(promises);
        results.forEach((result) => {
            if (result) {
                homeStore.updateCustomer(result);
            }
        });
        setLoading(false);
    };


    const columns: TableColumnsType<DataType> = [
        {
            title: 'Nome',
            dataIndex: 'firstName',
            key: 'firstName',
            render: (text) => <p>{text}</p>, // Exibe os valores do CNPJ/CPF
        },
        {
            title: 'CNPJ/CPF',
            dataIndex: 'document',
            key: 'document',
            render: (text) => <p>{text}</p>, // Exibe os valores do CNPJ/CPF
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        homeStore.removeCustomer(itemSelected.uuid);
        setIsModalOpen(false);
    };

    const handleExport = () => {
        const headings = [['cnpj']];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, [{ cnpj: '55765868000174' }], {
            origin: 'A2',
            skipHeader: true,
        });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Clientes.xlsx');
    };

    return (
        <div>
            <Permission permissionKeys={[PermissionCustomer.GET]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6" title="Listagem de Clientes" description='Lista com os clientes que utilizaram a home'>
                    <div className="flex pt-3 gap-4 items-end">
                        <AutocompleteComponent
                            label="Buscar Cliente"
                            name="searchTermCustomer"
                            placeholder="Digite o documento do cliente"
                            fetchOptions={handleSearch}
                            options={autocompleteOptions}
                            onChange={(value: any) => setSelectedProduct(value)}
                        />
                        <Button type='primary' onClick={() => addCustomer()}>Adicionar</Button>
                        <FileUpload onFileProcessed={handleFileProcessed} requiredColumns={['cnpj']} />
                        <ActionButton icon actionType="export" onClick={() => handleExport()}>Baixar Planilha</ActionButton>
                    </div>

                    <div className="mt-4">
                        <Table
                            className="table-custom"
                            columns={columns}
                            dataSource={customerList}
                            loading={loading}
                            size="middle"
                            bordered={false}
                            pagination={false}
                        />
                    </div>

                    <ModalComponent
                        title="Aviso"
                        content={<p>Você deseja excluir o cliente <b>{itemSelected?.name}</b>?</p>}
                        isModalOpen={isModalOpen}
                        handleOk={handleDeleteItem}
                        handleCancel={() => setIsModalOpen(false)}
                    />
                </ContentBox>
            </Permission>
        </div>
    );
});

export default CustomerAddHome;
