import URLS from '../config/urls';
import { Material } from '../types/Material';
import BaseAPI from './BaseApi';


class MaterialService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.MATERIAL}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async createMaterial(material: Material): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.MATERIAL}`, material);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async editMaterial(material: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.MATERIAL}/${uuid}`, material);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };
        }
    }

    async getMaterialByUuid(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.MATERIAL}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }


    async deleteMaterial(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.MATERIAL}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new MaterialService();