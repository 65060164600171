import React from 'react';
import { createContext, useContext } from 'react';
import stores from '../stores';

const StoreContext = createContext(stores);

export const StoreProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <StoreContext.Provider value={stores}>
            {children}
        </StoreContext.Provider>
    );
};

export const useStore = () => useContext(StoreContext);
