import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class ComponentsService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COMPONENTS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createComponent(color: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.COMPONENTS}`, color);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error };  // Retorna um objeto de erro
        }
    }

    async editComponent(color: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.COMPONENTS}/${uuid}`, color);
            return response; // Retorna a resposta da API
        } catch (error) {
            return { error: "Erro ao cadastrar", details: error }; // Retorna um objeto de erro
        }
    }

    async getComponentByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.COMPONENTS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async deleteComponente(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.COMPONENTS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new ComponentsService();