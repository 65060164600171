import { Divider } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import ContentBox from '../../../molecules/ContentBox';
import { formatCurrency } from '../../../../utils/functions';
import LoadingComponent from '../../../molecules/LoadingComponent';
import Permission from '../../../molecules/Permission';
import { PermissionCustomer } from '../../../../permissions/PermissionCustomer';
import NotPermissionComponent from '../../../atoms/NotPermissionComponent';
import { formatCep, formatPhone } from '../../../../utils/TextFormatters';
import { formatDateAndTime } from '../../../../utils/dateFunctions';

interface DescriptionItemProps {
    title: string;
    content: string;
    className?: string;
}

const DescriptionItem = ({ title, content, className }: DescriptionItemProps) => (
    <div title={content} className={`${className} flex gap-2 pb-2`}>
        <p className="text-sm inline-block text-gray-500 font-normal">{title}:</p>
        <p className="text-sm text-zinc-700 font-medium">{content}</p>
    </div>
);

interface ClientDetailsInformationProps {
    customer?: any;
    loading: boolean;
}

const ClientDetailsInformation: React.FC<ClientDetailsInformationProps> = observer(({ customer, loading }) => {

    return (
        <Permission permissionKeys={[PermissionCustomer.GET]} fallback={NotPermissionComponent()}>
            <ContentBox title="Informações do Cliente" description='Informações de Cadastro do Cliente' className="mb-4 p-6" borderCustom='rounded-b-lg'>

                {loading &&
                    <LoadingComponent />
                }

                {!loading &&
                    <div className="flex gap-4">
                        <div className="w-full">
                            <p className="pb-4 text-base text-primary-color">Dados de Contato</p>

                            <div className="grid grid-cols-2 gap-8">
                                <DescriptionItem title="Nome" content={customer?.firstName} />
                                <DescriptionItem title="CNPJ" content={customer?.document} />
                            </div>

                            <div className="grid grid-cols-2 gap-8">
                                <DescriptionItem title="Razão Social" content={customer?.corporateName} />
                                <DescriptionItem title="Inscrição Estadual" content={customer?.stateRegistration} />
                            </div>

                            <div className="grid grid-cols-2 gap-8">
                                <DescriptionItem title="Email" content={customer?.user?.email} />
                                <DescriptionItem title="Email Nota" content={customer?.emailNf} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Celular" content={formatPhone(customer?.mobile)} />
                                <DescriptionItem title="Telefone Fixo" content={formatPhone(customer?.phone)} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Número com Whatsapp?" content={customer?.mobileWhatsapp ? "Sim" : "Não"} />
                                <DescriptionItem title="Código da Matrícula" content={customer?.seller} />
                            </div>

                            <Divider />
                            <p className="pb-3 text-base text-primary-color">Outras Informações</p>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Canal" content={customer?.channel?.name} />
                                <DescriptionItem title="SubCanal" content={customer?.subChannel?.name} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Centro" content={customer?.center} />
                                <DescriptionItem title="Tabela de preço" content={customer?.tableId} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Troca de Unidade" content={customer?.clientUnitsActive === "HE" ? "Ativo" : "Inativo"} />
                                <DescriptionItem title="Código BP" content={customer?.clientExternal} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Grupo Clientes" content={customer?.clientGroup} />
                                <DescriptionItem title="Classe de Risco" content={customer?.riskClass} />
                            </div>

                            <div className="grid grid-cols-2 gap-8">
                                <DescriptionItem title="Status do Cadastro" content={customer?.status} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Data aceite de Políticas" content={formatDateAndTime(customer?.acceptTermsDate)} />
                            </div>

                            <Divider />
                            <p className="pb-4 text-base text-primary-color">Dados de Pagamento</p>

                            <div className="grid grid-cols-2 gap-4">
                                <DescriptionItem title="Forma de Pagamento" content={customer?.paymentForm} />
                                <DescriptionItem title="Prazo de Pagamento" content={customer?.paymentDeadline} />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <DescriptionItem title="Valor Mínimo" content={formatCurrency(customer?.minimumValue)} />
                                <DescriptionItem title="Valor Máximo" content={formatCurrency(customer?.limitValue)} />
                            </div>

                            <div className="grid grid-cols-2 gap-4">
                                <DescriptionItem title="CodPrazo Pagamento" content={customer?.paymentDeadlineCode} />
                            </div>

                            <Divider />
                            <p className="pb-3 text-base text-primary-color">Dados Comercial</p>

                            <div className="grid grid-cols-2 gap-8">
                                <DescriptionItem title="Contato Financeiro" content={customer?.businessContact} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Telefone Financeiro" content={formatPhone(customer?.phoneCommercial)} />
                                <DescriptionItem title="Celular Financeiro" content={formatPhone(customer?.cellphoneCommercial)} />
                            </div>

                            <Divider />

                            <p className="pb-3 text-base text-primary-color">Dados Financeiro</p>

                            <div className="grid grid-cols-2 gap-8">
                                <DescriptionItem title="Contato Financeiro" content={customer?.contactFinancial} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Telefone Financeiro" content={formatPhone(customer?.phoneFinancial)} />
                                <DescriptionItem title="Celular Financeiro" content={formatPhone(customer?.cellphoneFinancial)} />
                            </div>

                            <Divider />

                            <p className="pb-3 text-base text-primary-color">Endereço de Cobrança</p>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Endereço" content={customer?.billingAddress?.street} />
                                <DescriptionItem title="Bairro" content={customer?.billingAddress?.block} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Cidade" content={customer?.billingAddress?.city} />
                                <DescriptionItem title="Estado" content={customer?.billingAddress?.state} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Pais" content={customer?.billingAddress?.country} />
                                <DescriptionItem title="CEP" content={formatCep(customer?.billingAddress?.zipCode)} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Complemento" content={customer?.billingAddress?.addressComplement} />
                                <DescriptionItem title="Numero" content={customer?.billingAddress?.number} />
                            </div>

                            <Divider />

                            <p className="pb-3 text-base text-primary-color">Endereço de Entrega</p>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Endereço" content={customer?.mainAddress?.street} />
                                <DescriptionItem title="Bairro" content={customer?.mainAddress?.block} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Cidade" content={customer?.mainAddress?.city} />
                                <DescriptionItem title="Estado" content={customer?.mainAddress?.state} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Pais" content={customer?.mainAddress?.country} />
                                <DescriptionItem title="CEP" content={formatCep(customer?.mainAddress?.zipCode)} />
                            </div>

                            <div className="grid grid-cols-2 gap-8" >
                                <DescriptionItem title="Complemento" content={customer?.mainAddress?.addressComplement} />
                                <DescriptionItem title="Numero" content={customer?.mainAddress?.number} />
                            </div>
                        </div>
                    </div>
                }

            </ContentBox>
        </Permission>
    );
});

export default ClientDetailsInformation;