import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    entity :  Yup.object().required("Tipo de Cadastro obrigatório"),
  firstName: Yup.string().required("Campo obrigatório!"),
  lastName: Yup.string().required("Campo obrigatório!"),
  document : Yup.string().required("Documento obrigatório!"),
  password: Yup.string().required("Senha obrigatória!"),
  confirmPassword: Yup.string()
    .required("Confirmação da senha é obrigatória")
    .oneOf([Yup.ref("password"),], "As senhas devem ser iguais"),
  email: Yup.string().email("E-mail inválido").required("Email obrigatório!"),
  birthdate :Yup.date()
  .required("Data de Nascimento é obrigatório!"),
});