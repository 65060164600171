import axios from 'axios';
import BaseAPI from './BaseApi';
import URLS from '../config/urls';


class AuthoritiesService {
    async getList(): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.AUTHORITY}/search?page=0&size=9999`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }


    async createAuthoritiesGroup(uuid: string, uuidUser: string): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.AUTHORITY_GROUPS}/${uuid}/user/${uuidUser}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }


    async createIndividualAuthoritie(uuid: string, uuidUser: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.AUTHORITY}/${uuid}/user/${uuidUser}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }


    async deleteIndividualAuthoritie(uuid: string, uuidUser: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.AUTHORITY}/${uuid}/user/${uuidUser}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async deleteAuthoritiesGroup(uuid: string, uuidUser: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.AUTHORITY_GROUPS}/${uuid}/user/${uuidUser}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }
}

export default new AuthoritiesService();