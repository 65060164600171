import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class EvaluationService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.EVALUATIONS}`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async updateEvaluations(uuid: string | undefined, status: string): Promise<any> {
        try {
            const response = await BaseAPI.patch(`${URLS.EVALUATIONS}/${uuid}/status/${status}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }
}

export default new EvaluationService();