import { Tour, TourProps } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { PermissionOrder } from '../../../permissions/PermissionOrder';
import { PermissionReports } from '../../../permissions/PermissionReports';
import dashboardStore from '../../../stores/dashboardStore';
import PermissionComponent from '../../atoms/PermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import BarCharts from './components/BarCharts';
import DashboardInformation from './components/DashboardInformation';
import LineCharts from './components/LineCharts';
import PieCharts from './components/PieCharts';
import ProductMostPurchase from './components/ProductMostPurchase';

const Dashboard: React.FC = observer(() => {
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [dashboardData, setDashboardData] = useState();
    const { hasPermission } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            if (hasPermission(PermissionReports.DASHBOARD)) {
                const response = await dashboardStore.getList();
                setDashboardData(response.data);
            }
        };

        setLoading(false);
        fetchData();
    }, []);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const steps: TourProps['steps'] = [
        {
            title: 'Gráfico de Vendas Totais',
            description: 'Visualize o valor total das vendas realizadas em um gráfico claro e intuitivo.',
            target: () => ref1.current,
        },
        {
            title: 'Alternância de Gráficos',
            description: 'Troque facilmente entre o modo diário e outros formatos de visualização.',
            target: () => ref4.current,
        },
        {
            title: 'Resumo Mensal de Vendas',
            description: 'Acompanhe o desempenho das vendas mês a mês em um compilado detalhado.',
            target: () => ref2.current,
        },
        {
            title: 'Comparativo Anual de Receita',
            description: 'Compare a receita de cada mês deste ano com a do ano passado para insights mais profundos.',
            target: () => ref3.current,
        },
    ];

    return (
        <>
            <div className="grid grid-cols-12 gap-6 mb-6">
                <div className="col-span-6" ref={ref1}>
                    <Permission
                        permissionKeys={[PermissionReports.DASHBOARD]}
                        fallback={<PermissionComponent className="flex p-6 text-center h-full items-center justify-center h-full" />}
                    >
                        <BarCharts ref4={ref4} dashboardData={dashboardData} loading={loading} />
                    </Permission>
                </div>
                <div className="col-span-6" ref={ref2}>
                    <Permission
                        permissionKeys={[PermissionReports.SALES_GENERAL]}
                        fallback={<PermissionComponent className="flex p-6 text-center h-full items-center justify-center h-full" />}
                    >
                        <DashboardInformation />
                    </Permission>
                </div>
            </div>

            <div className="grid grid-cols-12 gap-6 mb-2" >

                <div className="col-span-7 mb-4" ref={ref3}>
                    <Permission
                        permissionKeys={[PermissionOrder.GET]}
                        fallback={<PermissionComponent className="flex p-6 text-center h-full items-center justify-center h-full" />}
                    >
                        <ProductMostPurchase />
                    </Permission>
                </div>

                <div className="col-span-5 mb-4">
                    <Permission
                        permissionKeys={[PermissionReports.DASHBOARD]}
                        fallback={<PermissionComponent className="flex p-6 text-center h-full items-center justify-center h-full" />}
                    >
                        <ContentBox className="mb-0 p-6" title="Total de Pedidos">
                            <PieCharts dashboardData={dashboardData} />
                        </ContentBox>
                    </Permission>
                </div>
            </div>

            <div className="grid grid-cols-12 gap-6" >
                <div className="col-span-12 mb-4" ref={ref3}>
                    <Permission
                        permissionKeys={[PermissionReports.DASHBOARD]}
                        fallback={<PermissionComponent className="flex p-6 text-center h-full items-center justify-center h-full" />}
                    >
                        <ContentBox className="mb-0 p-6 h-full" title="Receita Mês a Mês Ano Atual x Ano Passado">
                            <LineCharts dashboardData={dashboardData} />
                        </ContentBox>
                    </Permission>
                </div>
            </div>

            <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        </>
    );
});


export default Dashboard;