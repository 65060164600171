import { utils, writeFile } from "xlsx";

export const exportToExcel = (data: any, headings: any, fileName: any, sheetName = "Report") => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);

    // Adiciona os cabeçalhos
    utils.sheet_add_aoa(ws, [headings]);

    // Adiciona os dados
    utils.sheet_add_json(ws, data, { origin: "A2", skipHeader: true });

    // Adiciona a planilha ao livro
    utils.book_append_sheet(wb, ws, sheetName);

    // Gera o arquivo Excel
    writeFile(wb, fileName);
};