import { EyeOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType, TableProps, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../../config/config';
import { PermissionOrder } from '../../../../permissions/PermissionOrder';
import orderStore from '../../../../stores/orderStore';
import { formatDateString } from '../../../../utils/dateFunctions';
import { formatCurrency, getSortField, valueStatusConvert } from '../../../../utils/functions';
import { getOrderStatusColor } from '../../../../utils/statusColorMapper';
import NotPermissionComponent from '../../../atoms/NotPermissionComponent';
import ContentBox from '../../../molecules/ContentBox';
import Permission from '../../../molecules/Permission';

interface DataType {
    description: string;
    code: string;
    uuid: any;
    key: React.Key;
}

interface DataType {
    status: any;
    totalBilled: any;
    total: any;
    paymentDetails: any;
    uuid: any;
    key: React.Key;
    idOrder: string;
    externalId: string;
    customer: any;
}

interface QueryParams {
    name?: string;
    code?: string;
    size?: number;
    page?: number;
    sort?: string;
    search?: string;
    status_statusName?: string;
    active?: string;
}


interface TableOrderCustomerProps {
    uuid?: string;
}

const TableOrderCustomer: React.FC<TableOrderCustomerProps> = observer(({ uuid }: any) => {
    const { orderCustomerList, loading, size, totalElements, page } = orderStore;

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Número',
            dataIndex: 'idOrder',
            key: 'idOrder',
            sorter: true,
        },
        {
            title: 'Tipo de Pagamento',
            dataIndex: 'paymentDetails',
            key: 'paymentDetails.paymentOptionName',
            sorter: true,
            render: (item) => {
                return (
                    <>{item && <p>{item?.paymentOptionName}</p>}</>
                );
            },
        },
        {
            title: 'Valor Previo',
            dataIndex: 'total',
            key: 'total',
            sorter: true,
            render: (total) => {
                return (
                    <>{total && <p title={total}>{formatCurrency(total)}</p>}</>
                );
            },
        },
        {
            title: 'Data de criação',
            dataIndex: 'created',
            key: 'created',
            sorter: true,
            render: (text) => <p>{formatDateString(text)}</p>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                return (
                    <Tag color={getOrderStatusColor(status.statusName)}>{valueStatusConvert(status?.statusName)}</Tag>
                );
            },
        },
        {
            title: 'Visualizar Pedido',
            dataIndex: 'order',
            key: 'order',
            align: 'center',
            width: 50,
            render: (text, record) => (
                <Space className="cursor-pointer">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/detalhes-pedido/customer/${record?.customer?.uuid}/order/${record?.uuid}`}
                    >
                        <div><EyeOutlined /></div>
                    </a>
                </Space>
            ),
        },
    ];


    const onChange: TableProps<DataType>['onChange'] = async (pagination, filters, sorter, extra) => {
        const getSort = getSortField(sorter);
        //@ts-ignore
        const currentPage = pagination.current - 1;
        const newPageSize = pagination.pageSize || DEFAULT_PAGE_SIZE;

        const params = {
            size: newPageSize,
            page: currentPage,
            sort: getSort,
            search: ',status_statusName!CART',
        };

        orderStore.getListByCustomer(uuid, params);
    };

    return (
        <Permission permissionKeys={[PermissionOrder.GET_UUID]} fallback={NotPermissionComponent()}>
            <ContentBox borderCustom='rounded-b-lg' title="Lista de Pedidos do Cliente" description='Listagem com os ultimos pedidos do cliente' className="mb-4 p-6">
                <Table
                    columns={columns}
                    className="table-custom"
                    onChange={onChange}
                    dataSource={orderCustomerList && orderCustomerList?.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    pagination={{
                        current: page + 1,
                        pageSize: size,
                        total: totalElements,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60'],
                    }}
                    loading={loading}
                    size="middle"
                    showSorterTooltip={{ target: 'sorter-icon' }}
                />
            </ContentBox>
        </Permission>
    );
});

export default TableOrderCustomer;