import { BannerType } from "./selects";

// Função para obter o limite máximo de imagens para um tipo específico
const getMaxImagesForType = (typeValue: string): number => {
    const selectedType = BannerType.find((type) => type.value === typeValue);
    return selectedType ? selectedType.maxFile : 0;
};

// Função para verificar se a quantidade de imagens é compatível com o novo tipo
export const canChangeBannerType = (newBannerType: string, currentImages: number): boolean => {
    const maxImagesForNewType = getMaxImagesForType(newBannerType);

    if (currentImages > maxImagesForNewType) {
        return false; // Excede o limite, não pode mudar
    }
    return true; // Dentro do limite, pode mudar
};


export const canChangeBannerTypeAdd = (newBannerType: string, currentImages: number): boolean => {
    const maxImagesForNewType = getMaxImagesForType(newBannerType);

    if (currentImages >= maxImagesForNewType) {
        return false;
    }
    return true;
};