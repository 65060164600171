import { observer } from 'mobx-react-lite';
import React from 'react';
import campaingStore from '../../../../../stores/campaingStore';
import { campaignItems } from '../../../../../utils/selects';
import DatePickerComponet from '../../../../atoms/DatePickerComponet';
import InputComponent from '../../../../atoms/InputComponet';
import InputNumberComponente from '../../../../atoms/InputNumberComponet';
import SelectComponent from '../../../../atoms/SelectComponent';
import SwitchComponent from '../../../../atoms/SwitchComponent';
import ContentBox from '../../../../molecules/ContentBox';


const InformationComponent: React.FC = observer(() => {
    const { campaignProps } = campaingStore;

    const setCampaingType = (option: any) => {
        campaingStore.updateValue(option.value);
    }

    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Cadastro de Campanha" description='Tela para cadastro de Campanha' actions={
                <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
            }>
                <div className="flex gap-4">
                    <InputComponent label="Nome" name="name" id="name" />
                    <DatePickerComponet label="Data inicial" name="date" />
                </div>
            </ContentBox>

            <ContentBox className="mb-4 p-6" title="Modelo da Campanha" description="Selecione o modelo da Campanha">
                <div className="flex gap-4">
                    <SelectComponent onChange={(value, option) => setCampaingType(option)} label="Tipo de desconto*" name="type" options={campaignItems} />

                    {campaignProps?.type === 'Desconto Percentual X' &&
                        <InputNumberComponente label="Valor do desconto Percentual %" name="productsPercentageDiscount" id="productsPercentageDiscount" />
                    }

                    {campaignProps?.type === 'Desconto Absoluto X' &&
                        <InputNumberComponente label="Valor do desconto Absoluto*" name="productsAbsoluteDiscount" id="productsAbsoluteDiscount" />
                    }
                </div>
            </ContentBox>
        </>
    );
});

export default InformationComponent;