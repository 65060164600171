import { DirectoryTreeProps } from 'antd/es/tree/DirectoryTree';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_CREATE } from '../../../config/config';
import { PermissionCategories } from '../../../permissions/PermissionCategories';
import categoryStore from '../../../stores/categoryStore';
import { generateTree } from '../../../utils/categoryFunctions';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import PermissionComponent from '../../atoms/PermissionComponent';


// BreadCrumb
const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <p>Listagem de Categorias</p>,
    },
]

const ListCategory: React.FC = observer(() => {
    const { loading } = categoryStore;
    const [categoryList, setCategoryList] = useState<any>();
    const navigate = useNavigate();
    const listRouter = () => { navigate(`/categoria/cadastro`); }
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const { hasPermission } = useAuth();

    const mappings = {
        page: 'page',
        size: 'size',
        sort: 'sort',
        name: 'name',
        active: 'active',
    };

    useEffect(() => {
        const fetchData = async () => {
            if (hasPermission(PermissionCategories.GET)) {
                const respose = await categoryStore.getList();

                const result = generateTree(respose);
                setCategoryList(result);
            }

        };
        fetchData();
    }, []);

    const onSelect: DirectoryTreeProps['onSelect'] = (keys, info) => {
        navigate(`/categoria/editar/${keys[0]}`)
    };

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionCategories.POST} textButton={TEXT_BUTTON_HEADER_CREATE} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Listagem de Categorias" description='Tela de listagem de Categorias'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&
                <Permission permissionKeys={[PermissionCategories.GET]} fallback={<PermissionComponent />}>
                    <ContentBox className="mb-4 p-6" title="Listagem de Categorias" description='Tela de listagem de Categorias'>
                        <Tree
                            onSelect={onSelect}
                            treeData={categoryList}
                            checkStrictly
                        />
                    </ContentBox>
                </Permission>
            }
        </>
    );
});

export default ListCategory;