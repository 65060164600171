import { message } from 'antd';
import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import ConfigService from '../services/ConfigService';


class ConfigStore {
    loading: boolean = false;
    configProps: any = {
        topBarContents: [],
        paymentTypes: [],
    };
    sort?: string = '';
    totalPages: number = 0;
    multiple: any;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }


    reset() {
        this.configProps = {
            topBarContents: [],
            paymentTypes: [],
        };
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getConfig() {
        this.loading = true;
        const response = await ConfigService.getConfig();

        if (response.error) {
            message.error(`Erro ao buscar Configuração`);
            return;
        }

        this.configProps = response.data.result;
        this.loading = false;
        return response;
    }


    async getMultipleRule() {
        this.loading = true;
        const response = await ConfigService.getMultipleRule();

        if (response.error) {
            message.error(`Erro ao buscar Configuração`);
            return;
        }

        this.multiple = response.data;
        this.loading = false;
        return response;
    }

    async editConfig(data: any) {
        this.loading = true;
        const configData = { ...data };
        delete configData.favicon;
        delete configData.logo;
        const response = await ConfigService.editConfig(configData);
        await this.saveMultiple(data);

        if (response.error) {
            message.error(`Erro ao editar Configuração`);
        } else {
            await this.saveImage(data);
            message.success('Configuração editada com sucesso!');
            this.configProps = response.data.result;
        }

        this.loading = false;
        return response;
    }


    async saveMultiple(data: any) {
        const dataMultiple = {
            ...this.multiple,
            value: data.multipleRule
        }

        const response = await ConfigService.saveMultiple(dataMultiple);

        if (!response.error) {
            this.multiple = response.data;
        } else {
            message.error(`Erro ao editar Multiplo`);
        }
    }

    async saveImage(config: any) {
        if (config.logo[0]?.path) {
            const file = config.logo[0];

            const formData = new FormData();
            formData.append('file', file);
            ConfigService.saveLogo(formData);
        }

        if (config?.favicon[0]?.path) {
            const file = config.favicon[0];

            const formData = new FormData();
            formData.append('file', file);
            ConfigService.saveFavicon(formData);
        }
    }

    async addTopBarContents(data: any) {
        this.configProps.topBarContents.push(data);
    }

    async removeTopBarContents(data: any) {
        const filterTopBar = this.configProps.topBarContents.filter((item: any) => item.uuid !== data.uuid);
        this.configProps.topBarContents = filterTopBar;
    }

    async addPayment(data: any) {
        this.configProps.paymentTypes = data;
    }
}

const configStore = new ConfigStore();
export default configStore;