import { observer } from 'mobx-react-lite';
import React from 'react';
import categoryStore from '../../../../stores/categoryStore';
import ImageUploadComponent from '../../../atoms/UploadComponent';
import ContentBox from '../../../molecules/ContentBox';
import { PermissionCategories } from '../../../../permissions/PermissionCategories';
import { useAuth } from '../../../protected/ProviderAuth';
import Permission from '../../../molecules/Permission';
import PermissionComponent from '../../../atoms/PermissionComponent';


const ImageCategory: React.FC = observer(() => {
    const { hasPermission } = useAuth();

    const handleDeleteItem = async (item: any) => {
        if (item.uuid && hasPermission(PermissionCategories.DELETE_IMAGE)) {
            await categoryStore.deleteImage(item.uuid);
        }
    };

    return (
        <div>
            <Permission permissionKeys={[PermissionCategories.POST_IMAGE]} fallback={<PermissionComponent />}>
                <ContentBox className="mb-4 p-6 rounded-none" title="Cadastro de Imagem" description='Cadastre as Imagens das Categorias'>
                    <div className="flex flex-col gap-8">
                        <ImageUploadComponent maxCount={1} name="imageCategory" label="Banner Vitrine" onRemoveAction={(file) => {
                            handleDeleteItem(file);
                        }} />
                        <ImageUploadComponent maxCount={1} name="imageCategoryResponsive" label="Banner Vitrine Responsivo" onRemoveAction={(file) => {
                            handleDeleteItem(file);
                        }} />
                        <ImageUploadComponent maxCount={1} name="imageIconCategory" label="Imagem do menu de departamentos" onRemoveAction={(file) => {
                            handleDeleteItem(file);
                        }} />
                    </div>
                </ContentBox>
            </Permission>
        </div>
    );
});

export default ImageCategory;