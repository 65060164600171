import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import selosService from '../services/selosService';
import { PaginationParams } from '../types';
import { Stamp } from '../types/Selos';


class SelosStore {
    loading: boolean = false;
    selosList: Stamp[] = [];
    selo: Stamp | undefined;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.selo = undefined;
        this.selosList = [];
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await selosService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.selosList = response.data.result;
            }
            this.loading = false;
        });

        return response;
    }

    async createSelo(stamp: Stamp) {
        this.loading = true;
        const response = await selosService.createSelo(stamp);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao criar Selos`);
            } else {
                message.success('Selos cadastrado com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }


    async deleteSelos(uuid: string) {
        this.loading = true;
        const response = await selosService.deleteSelos(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar `);
            } else {
                message.success('Selo deletado com sucesso!');
            }

            this.selo = response.data;
            this.loading = false;
        });
        return response;
    }


    async deleteSelosAll(uuid: string) {
        this.loading = true;
        const response = await selosService.deleteSelos(uuid);
        runInAction(() => {
            this.selo = response.data;
            this.loading = false;
        });
        return response;
    }
}

const selosStore = new SelosStore();
export default selosStore;