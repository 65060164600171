import { DeleteOutlined } from '@ant-design/icons';
import type { TableColumnsType } from 'antd';
import { Space, Table, Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { PermissionBanners } from '../../../../permissions/PermissionBanners';
import bannerStore from '../../../../stores/bannerStore';
import ModalComponent from '../../../organisms/ModalComponent';
import { useAuth } from '../../../protected/ProviderAuth';
import { formatDateString } from '../../../../utils/dateFunctions';

interface DataType {
    uuid: any;
    key: React.Key;
    nameTag: string;
    responsive: boolean;
    tag: string;
}


const TableBanners: React.FC = observer(() => {
    const { banner, loading, arrayImageUrl } = bannerStore;
    const { isColumnVisible } = useAuth();
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Ordenação',
            dataIndex: 'ordering',
            key: 'ordering',
            width: 200
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
        },
        {
            title: 'Data Inicial',
            dataIndex: 'initialDate',
            key: 'initialDate',
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Data Final',
            dataIndex: 'finalDate',
            key: 'finalDate',
            render: (text) => {
                return (
                    <>{<p>{formatDateString(text)}</p>}</>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'finalDate',
            key: 'finalDate',
            render: (text) => {
                const today = new Date(); // Data e hora atuais
                const finalDate = new Date(text); // Data final

                // Verifica se a data final já passou em relação à data de hoje
                const isExpired = finalDate < today;

                return (
                    <>
                        <Tag color={isExpired ? "red" : "green"}><p>{isExpired ? 'Vencido' : 'Ativo'}</p></Tag>
                    </>
                );
            },
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            hidden: !isColumnVisible(PermissionBanners.DELETE_IMAGE),
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => {
                    setIsModalDeleteOpen(true);
                    setItemSelected(record);
                }}>
                    <div><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];


    const handleDeleteItem = async () => {
        setIsModalDeleteOpen(false);
        bannerStore.deleteFile(itemSelected.uuid);
    };

    return (
        <>
            <div>
                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={arrayImageUrl?.map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="small"
                    bordered={false}
                    showSorterTooltip={{ target: 'sorter-icon' }}
                />
            </div>

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir o Banner <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalDeleteOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalDeleteOpen(false)}
            />
        </>
    );
});

export default TableBanners;