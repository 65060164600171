export const getStatusColor = (status: string): string => {
    switch (status) {
        case 'Ativo':
            return 'green'; // azul pastel
        case 'Inativo':
            return 'red'; // laranja pastel
        case 'Sim':
            return 'green'; // azul pastel
        case 'Não':
            return 'red'; // laranja pastel
        default:
            return 'gray'; // Cor padrão para status desconhecido
    }
};


export const getOrderStatusColor = (status: string): string => {
    switch (status) {
        case 'ANALYSIS':
            return 'blue';
        case 'BILLED':
            return '#FDBF6F';
        case 'SENT':
            return '#CAB2D6';
        case 'PENDING_PAYMENT':
            return 'gold';
        case 'DELIVERED':
            return '#B2DF8A';
        case 'FINISHED':
            return '#33A02C';
        case 'PAID':
            return '#B15928';
        case 'PENDING':
            return '#FDBF6F';
        case 'CANCELED':
            return 'red';
        case 'RETURNED':
            return 'magenta';
        case 'CAPTURED':
            return '#CAB2D6';
        case 'ACCEPTED':
            return '#B6D3E9';
        case 'AUTHORIZED':
            return '#83d36f';
        default:
            return 'gray'; // Cor padrão para status desconhecido
    }
};