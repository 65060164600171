import React, { ReactNode } from 'react';
import { useAuth } from '../../protected/ProviderAuth';
import InputComponent from '../../atoms/InputComponet';
import ActionButton from '../../atoms/ButtonComponent';
import { Tooltip } from 'antd';
import { COLOR_TOOLTIP } from '../../../config/config';

interface PermissionProps {
    permissionKeys: string[];
    children: ReactNode;
    inputType?: boolean;
    inputValue?: any;
    name?: string;
    buttonType?: boolean;
    size?: string;
    fallback?: ReactNode; // Componente alternativo caso não tenha permissão
}

const Permission: React.FC<PermissionProps> = ({
    permissionKeys,
    children,
    inputType = false,
    name,
    inputValue,
    buttonType,
    size,
    fallback = null
}) => {
    const { hasPermission } = useAuth();

    // Verifica se o usuário possui pelo menos uma das permissões especificadas
    const hasAnyPermission = permissionKeys.some(permissionKey => hasPermission(permissionKey));

    if (hasAnyPermission) {
        return <>{children}</>;
    }

    // Renderiza o fallback fornecido, ou um input desabilitado se `inputType` for true e `fallback` não for definido
    if (fallback) {
        return <>{fallback}</>;
    }

    if (inputType) {
        return (
            <InputComponent
                type="text"
                label={name}
                name={name}
                placeholder="Permissão Necessária"
                disabled
                value={inputValue}
                size={size ? size : 'large'}
            />
        );
    }

    if (buttonType) {
        return (
            <ActionButton actionType="disabled">
                <Tooltip color={COLOR_TOOLTIP} placement="topLeft" title="Você não possui permissão para realizar esta ação.">
                    <span>Ação Restrita</span>
                </Tooltip>
            </ActionButton>
        );
    }

    // Caso nenhum fallback seja passado e inputType seja false, renderiza um parágrafo com uma mensagem padrão
    return null
};

export default Permission;
