import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TEXT_BUTTON_HEADER_LIST } from '../../../config/config';
import { PermissionSubChannel } from '../../../permissions/PermissionSubChannel';
import channelStore from '../../../stores/channelStore';
import subChannel from '../../../stores/subChannel';
import { SubChannelModel } from '../../../types/SubChannel';
import BreadCrumbComponent from '../../atoms/BreadCrumbComponent';
import ActionButton from '../../atoms/ButtonComponent';
import InputComponet from '../../atoms/InputComponet';
import NotPermissionComponent from '../../atoms/NotPermissionComponent';
import SelectComponent from '../../atoms/SelectComponent';
import ContentBox from '../../molecules/ContentBox';
import LoadingComponent from '../../molecules/LoadingComponent';
import Permission from '../../molecules/Permission';
import { useAuth } from '../../protected/ProviderAuth';
import { validationSchema } from './schema';
import PermissionComponent from '../../atoms/PermissionComponent';
import { PermissionChannel } from '../../../permissions/PermissionChannel';
import SwitchComponent from '../../atoms/SwitchComponent';

const breadCrumb = [
    {
        title: <a href="/dashboard">Dashboard</a>,
    },
    {
        title: <a href="/subcanal">Listagem de SubCanais</a>,
    },
    {
        title: <p>Cadastro de SubCanal</p>,
    },
]

const CreateSuChannel: React.FC = observer(() => {
    const { uuid } = useParams<{ uuid: string }>();
    const { subChannelProps, loading } = subChannel;
    const { selectChannelList } = channelStore;
    const navigate = useNavigate();
    const { hasPermission } = useAuth();

    // Search Channel
    const defaultparamsChannel = {
        size: 99999,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    useEffect(() => {
        if (hasPermission(PermissionChannel.GET_UUID)) {
            channelStore.getList(defaultparamsChannel);
        }

        if (uuid && hasPermission(PermissionSubChannel.GET_UUID)) {
            const fetchData = async () => {
                await subChannel.getSubChannelByUuid(uuid);
            };

            fetchData();
        }

        return () => {
            subChannel.reset();
        };
    }, []);


    const initialValues: SubChannelModel = {
        name: subChannelProps?.name || '',
        code: subChannelProps?.code || '',
        //@ts-ignore
        channel: { value: subChannelProps?.channel?.uuid, label: '' },
        active: subChannelProps?.active || false,
    };

    // Faz o filtro da tabela
    const handleSubmit = async (values: SubChannelModel) => {
        let response;

        const formattedValues = {
            ...values,
            channel: {
                uuid: values.channel.value,
            },
            active: values.active || false,
        };

        if (uuid) {
            response = await subChannel.editSubChannel(formattedValues, uuid);
        } else {
            response = await subChannel.createSubChannel(formattedValues);
        }

        if (!response.error) listRouter();
    };

    const listRouter = () => {
        navigate(`/subCanal`);
    }

    return (
        <>
            <BreadCrumbComponent breadCrumbList={breadCrumb} permission={PermissionSubChannel.GET} textButton={TEXT_BUTTON_HEADER_LIST} onClick={() => listRouter()} />

            {loading &&
                <ContentBox className="mb-4 p-6" title="Cadastro de SubCanal" description='Tela para cadastro de SubCanal'>
                    <LoadingComponent />
                </ContentBox>
            }

            {!loading &&

                <Permission
                    permissionKeys={[PermissionSubChannel.POST, PermissionSubChannel.PUT, PermissionSubChannel.GET_UUID]}
                    fallback={<PermissionComponent />}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <ContentBox className="mb-4 p-6" title="Cadastro de SubCanal" description='Tela para cadastro de SubCanal' actions={
                                <>
                                    <SwitchComponent name="active" checkedChildren="Ativo" unCheckedChildren="Inativo" />
                                </>
                            }>
                                <div className="flex w-full gap-4 pb-4">
                                    <InputComponet label="Código" name="code" id="code" />
                                    <InputComponet label="Nome" name="name" id="name" />
                                </div>

                                <div className="flex w-full gap-4 pb-4">
                                    <Permission permissionKeys={[PermissionChannel.GET]} inputType name="Canal">
                                        <SelectComponent label="Canal" name="channel" options={selectChannelList} />
                                    </Permission>
                                </div>
                            </ContentBox>

                            <div className="flex justify-end">
                                <Permission permissionKeys={[PermissionSubChannel.PUT, PermissionSubChannel.POST]} buttonType>
                                    <ActionButton htmlType="submit" actionType="primary">{uuid ? 'Atualizar' : 'Cadastrar'}</ActionButton>
                                </Permission>
                            </div>
                        </Form>
                    </Formik>
                </Permission>
            }
        </>
    );
});

export default CreateSuChannel;