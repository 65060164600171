import { Input, Typography } from 'antd';
import { Field } from 'formik';
import React from 'react';
import './styles.scss';

//@ts-ignore
interface OtpComponentProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    name: string; // Tornando o name obrigatório
    size?: string;
    length?: number;
    formatter?: boolean;
}

const OtpComponent: React.FC<OtpComponentProps> = ({ label, length, name, size, formatter, disabled, placeholder, ...props }) => (
    <div className="flex flex-col w-full input-container">
        {label && <Typography.Title level={5}>{label}</Typography.Title>}
        <Field name={name}>
            {({ field, form, meta }: any) => {
                return (
                    <>
                        <Input.OTP
                            {...field}
                            size={size ? size : "large"}
                            disabled={disabled}
                            value={field.value || ""}
                            length={length ? length : 6}
                            placeholder={placeholder}
                            onChange={(value) => {
                                form.setFieldValue(name, value ? value : "");
                                form.setFieldTouched(name, true, false);
                            }}
                            status={meta.touched && meta.error ? 'error' : ''}
                        />
                        {meta.touched && meta.error && (
                            <Typography.Text type="danger">{meta.error}</Typography.Text>
                        )}
                    </>
                );
            }}
        </Field>
    </div>
);


export default OtpComponent;
