export const filterComponentsByDate = (data: any, targetDate: any) => {
    const target = new Date(targetDate);

    // Cria uma cópia profunda do data para evitar modificações diretas
    const copiedData = JSON.parse(JSON.stringify(data));

    return copiedData.filter((item: any) => {
        const component = item.components || item.component;
        if (!component) return false;

        // Verifica o minDate do componente
        const minDate = new Date(component.minDate);

        if (component.type !== "BANNER_CAROUSEL") {
            if (target >= minDate) return false;
        }

        // Se o componente tiver banners, filtra os banners pelo initialDate
        if (component.banners && component.banners.length > 0) {
            component.banners = component.banners.filter((banner: any) => {
                // Converte o target para um objeto Date, caso não seja
                const targetDate = new Date(target);


                // Filtra os arquivos dentro do banner
                banner.files = banner.files.filter((file: any) => {
                    const initialDate = new Date(file.initialDate);

                    // Retorna true se a data for válida (inicia após ou na data alvo)
                    return targetDate <= initialDate;
                });

                // Se após filtrar, o banner tiver pelo menos um arquivo válido, mantém o banner
                return banner.files.length > 0;
            });

            // Exclui o componente se todos os banners forem filtrados
            if (component.banners.length === 0) return false;
        }

        return true;
    });
};