import React from 'react';
import { Checkbox } from 'antd';

interface CheckboxComponentboxProps {
    checked?: boolean;
    onChange: () => void;
    label: string;
    disabled?: boolean;
    name?: string;
    id?: string;
}

const CheckboxComponent: React.FC<CheckboxComponentboxProps> = ({ name, id, checked, onChange, label, disabled }) => {
    return (
        <Checkbox name={name} id={id} checked={checked} onChange={onChange} disabled={disabled}>
            {label}
        </Checkbox>
    );
};

export default CheckboxComponent;