import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import PropertiesService from '../services/PropertiesService';
import { PaginationParams } from '../types';
import { transformToValueLabel } from '../utils/functions';


class PropertiesStore {
    loading: boolean = false;
    propertieList: any = [];
    propertieListSelect: any = [];
    propertie: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    reset() {
        this.propertie = undefined;
        this.propertieListSelect = [];
        this.propertieList = [];
    }

    async getList(params: PaginationParams) {
        this.loading = true;
        const response = await PropertiesService.getList(params);

        runInAction(() => {
            if (!response.error) {
                this.propertieList = response.data.content;
                this.totalPages = response.data.totalPages;
                this.page = response.data.number;
                this.totalElements = response.data.totalElements;
                this.size = response.data.size;

                this.propertieListSelect = transformToValueLabel(response.data.content, 'uuid', 'className');
            }
            this.loading = false;
        });
        return response;
    }


    async getPropertieByUuid(uuid: string) {
        this.loading = true;
        const response = await PropertiesService.getPropertieByUuid(uuid);

        runInAction(() => {
            if (response.error) {
                message.error('Erro ao buscar Propriedade');
                return;
            }

            this.propertie = response.data;
            this.loading = false;
        });
        return response;
    }


    async deletePropertie(uuid: string) {
        this.loading = true;
        const response = await PropertiesService.deletePropertie(uuid);

        runInAction(() => {
            if (response.error) {
                message.error(`Erro ao deletar Especificação`);
                return;
            } else {
                message.success('Especificação deletada com sucesso!');
            }

            this.loading = false;
        });
        return response;
    }

    async deletePropertieAll(uuid: string) {
        this.loading = true;
        const response = await PropertiesService.deletePropertie(uuid);

        runInAction(() => {
            this.loading = false;
        });
        return response;
    }


    async createPropertie(data: any) {
        this.loading = true;
        const response = await PropertiesService.createPropertie(data);

        if (response.error) {
            message.error('Erro ao criar Especificação');
        } else {
            if (data?.values?.length > 0) {
                const cleanedValues = data.values.map((item: any) => {
                    const cleanedItem = { ...item };
                    if (cleanedItem.isNew) {
                        delete cleanedItem.uuid;
                        delete cleanedItem.isNew;
                    }
                    return cleanedItem;
                });

                cleanedValues.forEach(async (item: any) => {
                    await PropertiesService.createPropertieValues([item], response.data.uuid);
                });
            }
            message.success('Especificação cadastrado com sucesso!');
        }

        this.loading = false;
        return response;
    }


    async editPropertie(data: any, uuid: string | undefined) {
        this.loading = true;
        const response = await PropertiesService.editPropertie(data, uuid);

        if (response.error) {
            message.error('Erro ao editar Especificação');
        } else {
            const cleanedValues = data?.values
                .filter((item: any) => item.isNew)
                .map((item: any) => {
                    const cleanedItem = { ...item };
                    delete cleanedItem.uuid;
                    delete cleanedItem.isNew;
                    return cleanedItem;
                });

            if (cleanedValues.length > 0) {
                const promises = cleanedValues.map(async (item: any) => {
                    return PropertiesService.createPropertieValues([item], response.data.uuid);
                });
                await Promise.all(promises);
            }
            message.success('Especificação editado com sucesso!');
        }

        this.loading = false;
        return response;
    }


    async deletePropertiesValue(data: any) {
        if (data.isNew === true) {
            message.success('Especificação deletada com sucesso!');
            this.propertie.values = this.propertie.values.filter((value: any) => value.uuid !== data.uuid);
            return;
        }

        const response = await PropertiesService.deletePropertieValues(data.uuid);

        if (response.error) {
            message.error('Erro ao deletar Especificação');
        } else {
            this.propertie.values = this.propertie.values.filter((value: any) => value.uuid !== data.uuid);
            message.success('Especificação deletada com sucesso!');
        }

        return response;
    }

    async addNewValueToPropertie(ordering: string, displayString: string) {


        runInAction(() => {
            if (!this.propertie) {
                this.propertie = {};
            }

            // Verifica se a propriedade `propertie` está carregada
            if (!this.propertie.values) {
                this.propertie.values = []; // Inicializa como um array vazio
            }

            const newValue = {
                uuid: uuidv4(),
                propertyValue: displayString,
                ordering: ordering,
                displayString: displayString,
                orderingTable: ordering,
                isNew: true
            };

            // Adiciona o novo valor ao array `values`
            this.propertie.values.push(newValue);
        });
    }


    async deleteProperties(uuid: string) {
        runInAction(() => {
            this.propertie.values = this.propertie.values.filter((value: any) => value.uuid !== uuid);
        });
    }
}

const propertiesStore = new PropertiesStore();
export default propertiesStore;