import { Tag } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import configStore from '../../../../stores/configStore';
import ContentBox from '../../../molecules/ContentBox';
import { AmericanExpress, Billet, Elo, Hipercard, MasterCard, Pix, Visa } from '../../../molecules/IconComponent';


const tagsData = ['PIX', 'HIPERCARD', 'MASTERCARD', 'VISA', 'ELO', 'BOLETO'];

const PaymentTypes: React.FC = observer(() => {
    const { configProps } = configStore;

    const [selectedTags, setSelectedTags] = React.useState<string[]>([]);
    const handleChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);

        configStore.addPayment(nextSelectedTags);
    };

    return (
        <>
            <ContentBox className="mb-4 p-6" borderCustom='rounded-b-lg' title="Tipos de Pagamento" description='Quando habilitado os icones serão exibidos no Footer'>
                <div className="flex gap-1">
                    {tagsData.map<React.ReactNode>((tag) => (
                        <Tag.CheckableTag
                            className="border border-slate-100 p-2"
                            key={tag}
                            checked={configProps.paymentTypes.includes(tag)}
                            onChange={(checked) => handleChange(tag, checked)}
                        >
                            <div className="flex gap-2">
                                {tag === 'PIX' &&
                                    <div className="min-w-6 max-h-6">
                                        <Pix />
                                    </div>
                                }

                                {tag === 'VISA' &&
                                    <div className="min-w-6 max-h-6">
                                        <Visa />
                                    </div>
                                }


                                {tag === 'AMERICAN_EXPRESS' &&
                                    <div className="min-w-6 max-h-6">
                                        <AmericanExpress />
                                    </div>
                                }

                                {tag === 'ELO' &&
                                    <div className="min-w-6 max-h-6">
                                        <Elo />
                                    </div>
                                }

                                {tag === 'MASTERCARD' &&
                                    <div className="min-w-6 max-h-6">
                                        <MasterCard />
                                    </div>
                                }

                                {tag === 'HIPERCARD' &&
                                    <div className="min-w-6 max-h-6">
                                        <Hipercard />
                                    </div>
                                }

                                {tag === 'BOLETO' &&
                                    <div className="min-w-6 max-h-6">
                                        <Billet />
                                    </div>
                                }
                                {tag}
                            </div>

                        </Tag.CheckableTag>
                    ))}
                </div>


            </ContentBox>
        </>
    );
});

export default PaymentTypes;