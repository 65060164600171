import { observer } from 'mobx-react-lite';
import React from 'react';
import { Navigate } from 'react-router-dom';
import authStore from '../../stores/authStore';


const ProtectedRoute = observer(({ children }: any) => {
    if (!authStore.isAuthenticated) {
        return <Navigate to="/login" />;
    }
    return <>{children}</>;
});

export default ProtectedRoute;

