import { Button, Result } from 'antd';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';


// BreadCrumb
const PageNotFound: React.FC = observer(() => {
    const navigate = useNavigate();

    return (
        <Result
            status="404"
            title="404"
            subTitle="Desculpe, a página que você visitou não existe."
            extra={<Button onClick={() => navigate(`/dashboard`)} type="primary">Voltar para Dashboard</Button>}
        />
    );
});

export default PageNotFound;