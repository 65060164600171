import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    shortName: Yup.string().required('Nome curto é obrigatória'),
    skuCode: Yup.string().required('SKU é obrigatória'),

    netWeight: Yup.string().required('Peso Liquido é obrigatória'),
    weight: Yup.string().required('Peso Bruto é obrigatória'),
    height: Yup.string().required('Altura (cm) é obrigatória'),
    length: Yup.string().required('Profundidade (cm) é obrigatória'),
    shippingWeight: Yup.string().required('Peso envio é obrigatória'),
    shippingHeight: Yup.string().required('Altura envio é obrigatória'),
    shippingWidth: Yup.string().required('Largura envio é obrigatória'),
    width: Yup.string().required('Largura (cm) é obrigatória'),
    cubage: Yup.string().required('Cubagem é obrigatória'),
});