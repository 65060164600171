import { makeAutoObservable } from 'mobx';
import { DEFAULT_PAGE_SIZE } from '../config/config';
import AuthoritiesService from '../services/AuthoritiesService';


class AuthoritiesStore {
    loadingAuthorities: boolean = false;
    authoritiesList: any = [];
    perfil: any;
    sort?: string = '';
    totalPages: number = 0;
    page: number = 0;
    totalElements: number = 0;
    size: number = DEFAULT_PAGE_SIZE;
    defaultparams = {
        size: DEFAULT_PAGE_SIZE,
        page: 0,
        sort: 'created,desc',
        search: '',
    }

    constructor() {
        makeAutoObservable(this);
    }

    async getList() {
        this.loadingAuthorities = true;
        const response = await AuthoritiesService.getList();

        if (!response.error) {
            this.authoritiesList = response.data.content;
        }
        this.loadingAuthorities = false;
        return response.data.content;
    }
}

const authoritiesStore = new AuthoritiesStore();
export default authoritiesStore;