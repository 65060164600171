import URLS from '../config/urls';
import BaseAPI from './BaseApi';


class AdministratorService {
    async getList(params: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ACCOUNTS}/search`, { params });
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao trazer listagem" }; // Retorna um objeto de erro
        }
    }

    async getAdministratorByUuid(uuid: any): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ACCOUNTS}/${uuid}?by=uuid`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }

    async createAdministrator(data: any): Promise<any> {
        try {
            const response = await BaseAPI.post(`${URLS.ACCOUNTS}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao cadastrar" }; // Retorna um objeto de erro
        }
    }

    async editAdministrator(data: any, uuid: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.put(`${URLS.ACCOUNTS}/${uuid}`, data);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao editar" }; // Retorna um objeto de erro
        }
    }

    async deleteAdministrator(uuid: string): Promise<any> {
        try {
            const response = await BaseAPI.delete(`${URLS.ACCOUNTS}/${uuid}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao deletar" }; // Retorna um objeto de erro
        }
    }

    async getAccount(email: string | undefined): Promise<any> {
        try {
            const response = await BaseAPI.get(`${URLS.ACCOUNTS}/${email}`);
            return response; // Retorna a resposta da API
        } catch (e) {
            return { error: "Erro ao buscar dados" }; // Retorna um objeto de erro
        }
    }
}

export default new AdministratorService();