import * as Yup from 'yup';

// Definindo o esquema de validação com Yup
export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    ordering: Yup.string().required('Ordenação é obrigatório'),
    date: Yup.array()
        .of(Yup.string().required('Data inválida'))
        .length(2, 'É necessário selecionar um intervalo de datas')
        .required('Data é obrigatório'),
});