import { DeleteOutlined } from '@ant-design/icons';
import { Space, Table, TableColumnsType } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import configStore from '../../../../../stores/configStore';
import productStore from '../../../../../stores/productStore';
import { getUniqueFilters } from '../../../../../utils/functions';
import ActionButton from '../../../../atoms/ButtonComponent';
import ContentBox from '../../../../molecules/ContentBox';
import ModalComponent from '../../../../organisms/ModalComponent';
import DrawerRegisterTopBarLink from '../DrawerRegisterTopBarLink';


interface DataType {
    position: string;
    link: string;
    ordination: string;
    name: string;
    uuid: string;
    key: React.Key;
}

interface TableTopBarProps {
    title: string;
    description: string;
    mobile: boolean;
}


const TableTopBar: React.FC<TableTopBarProps> = observer(({ title, description, mobile }) => {
    const { configProps, loading } = configStore;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openModalRegister, setOpenModalRegister] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();

    const columns: TableColumnsType<DataType> = [
        {
            title: 'Ordenação',
            dataIndex: 'ordination',
            key: 'ordination',
            filters: getUniqueFilters(configProps?.topBarContents.filter((item: any) => item.mobile === mobile), 'ordination'),
            onFilter: (value, record) => record.ordination.indexOf(value as string) === 0,
        },
        {
            title: 'Nome',
            dataIndex: 'name',
            key: 'name',
            filters: getUniqueFilters(configProps?.topBarContents.filter((item: any) => item.mobile === mobile), 'name'),
            onFilter: (value, record) => record.name.indexOf(value as string) === 0,
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            filters: getUniqueFilters(configProps?.topBarContents.filter((item: any) => item.mobile === mobile), 'link'),
            onFilter: (value, record) => record.link.indexOf(value as string) === 0,
        },
        {
            title: 'Posição',
            dataIndex: 'position',
            key: 'position',
            filters: getUniqueFilters(configProps?.topBarContents.filter((item: any) => item.mobile === mobile), 'position'),
            onFilter: (value, record) => record.position.indexOf(value as string) === 0,
        },
        {
            title: 'Deletar',
            dataIndex: 'delete',
            key: 'delete',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Space className="cursor-pointer" onClick={() => showModal(record)}>
                    <div ><DeleteOutlined /></div>
                </Space>
            ),
        },
    ];

    const showModal = (record: any) => {
        setItemSelected(record);
        setIsModalOpen(true);
    };

    const handleDeleteItem = () => {
        productStore.removeUnit(itemSelected.uuid);
        setIsModalOpen(false);
    };

    return (
        <div>
            <ContentBox className="mb-4 p-6" >
                <div className="flex justify-between items-center mb-4">
                    <div className="flex flex-col">
                        <h1 className="text-lg font-semibold">{title}</h1>
                        <p className="text-gray-500 text-sm">{description}</p>
                    </div>

                    <div className="flex gap-2">
                        <ActionButton icon actionType="create" onClick={() => setOpenModalRegister(true)}>Cadastrar</ActionButton>
                    </div>
                </div>


                <Table
                    className="table-custom"
                    columns={columns}
                    dataSource={configProps?.topBarContents.filter((item: any) => item.mobile === mobile).map((item: any, index: number) => ({
                        ...item,
                        key: item.uuid || index,
                    }))}
                    loading={loading}
                    size="middle"
                    bordered={false}
                    pagination={false}
                />
            </ContentBox>

            <ModalComponent
                title="Aviso"
                content={<p>Você deseja excluir a Unidade <b>{itemSelected?.name}</b>?</p>}
                isModalOpen={isModalOpen}
                handleOk={handleDeleteItem}
                handleCancel={() => setIsModalOpen(false)}
            />

            <DrawerRegisterTopBarLink isModalOpen={openModalRegister} setIsModalOpen={setOpenModalRegister} />
        </div>
    );
});

export default TableTopBar;